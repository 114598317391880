import React, { useEffect, createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../hooks/useLocalStorage";

export const userAuthContext = createContext(null);

export const UserAuthContextProvider = ({ children }) => {
    const [user, setUser] = React.useState(null);
    const [snackbar, setSnackbar] = useState(null);
    const [organizations, setOrganizations] = useState([]);
    const [statusMessage, setStatusMessage] = useState("");
    const [notifications, setNotifications] = useState([]);
    const [unreadNotifications, setUnreadNotifications] = useState([]);

    const [userMapSettings, setUserMapSettings] = useLocalStorage("userMapSettings", null);
    const [userAirspaceSettings, setUserAirspaceSettings] = useLocalStorage("userAirspaceSettings", null);
    const [userOperationalStates, setUserOperationalStates] = useLocalStorage("userOperationalStates", null);

    const navigateTo = useNavigate();

    useEffect(() => {
        getOrganizations();
    }, []);

    useEffect(() => {
        if (user && user.reset_password) {
            return navigateTo("/reset");
        }
    }, [user]);

    const getNotifications = async (newUser) => {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(newUser)
        };
        await fetch("api/user/notifications", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => {
                setNotifications(data);
                setUnreadNotifications(data.filter((message) => !message.read));
            })
            .catch((err) => handleFailedFetch(err));
    };

    const getOrganizations = async () => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        await fetch("api/user/organizations", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => setOrganizations(data))
            .catch((err) => handleFailedFetch(err));
    };

    // api call to signup
    const signup = async (updatedUser) => {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(updatedUser)
        };
        await fetch("api/user/create", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => setStatusMessage(data.message))
            .catch((err) => handleFailedFetch(err));
    };

    // api call to login
    const login = async (updatedUser) => {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(updatedUser)
        };

        await fetch("api/user/login", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => {
                if (data.auth) {
                    setUser(data.user);

                    if (data.user.user_role === "Operator") {
                        navigateTo("/dashboard");
                    } else {
                        navigateTo("/map");
                    }
                } else setStatusMessage(data.message);
            })
            .catch((err) => handleFailedFetch(err));
    };

    // api call to end session on logout
    const logout = async () => {
        setUser(null);
        setUserMapSettings(null);
        setUserOperationalStates(null);
        setUserAirspaceSettings(null);
        localStorage.clear();

        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };

        await fetch("api/user/logout", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => navigateTo("/login"));
    };

    // api call to update a certain user
    const updateUser = async (updatedUser) => {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(updatedUser)
        };

        await fetch("api/user/update", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => setStatusMessage(data))
            .catch((err) => handleFailedFetch(err));
    };

    // api call to update a users password
    const forgotPassword = async (updatedUser) => {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(updatedUser)
        };

        await fetch("api/user/forgot", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(async (data) => setStatusMessage(data.message))
            .catch((err) => handleFailedFetch(err));
    };

    // api call to update a users password
    const updatePassword = async (updatedUser) => {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(updatedUser)
        };

        await fetch("api/user/password", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => setStatusMessage(data))
            .catch((err) => handleFailedFetch(err));
    };

    // api call to update a users information
    const updateInfo = async (updatedUser) => {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(updatedUser)
        };

        await fetch("api/account/update", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => {
                setStatusMessage(data.message);
                setUser(updatedUser);
            })
            .catch((err) => handleFailedFetch(err));
    };

    // api call to populate User Map Settings
    const getMapSettings = async (newUser, fetchMapData) => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        await fetch("/api/mapSettings/get/" + newUser.id, requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => {
                setUserMapSettings(data);
                setUserOperationalStates(data.op_state_settings);
                setUserAirspaceSettings(data.airspace_settings);
                fetchMapData(data);
            })
            .catch((err) => handleFailedFetch(err));
    };

    const updateMapSettings = async (updatedMapSettings) => {
        let api;
        if (updatedMapSettings.id == 0) {
            api = "/api/mapSettings/create";
        } else {
            api = "/api/mapSettings/update";
        }
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(updatedMapSettings)
        };

        await fetch(api, requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => {
                setUserMapSettings(data);
                setUserOperationalStates(data.op_state_settings);
                setUserAirspaceSettings(data.airspace_settings);
            })
            .catch((err) => handleFailedFetch(err));
    };

    const handleFailedFetch = (err) => {
        if (err.status === 401) {
            logout();
            setStatusMessage("Error: Session expired. Please log back in and try again.");
        }

        if (err.status === 403) {
            logout();
            setStatusMessage("Error: Something went wrong. Please log back in and try again.");
        }
    };

    const getOrganizationByID = (id) => {
        return organizations.find((org) => org.id === id);
    };

    const values = {
        user,
        userMapSettings,
        userAirspaceSettings,
        userOperationalStates,
        organizations,
        statusMessage,
        notifications,
        unreadNotifications,
        login,
        signup,
        snackbar,
        setSnackbar,
        logout,
        setUser,
        updateInfo,
        updateUser,
        getMapSettings,
        updatePassword,
        forgotPassword,
        setStatusMessage,
        updateMapSettings,
        getOrganizations,
        getNotifications,
        handleFailedFetch,
        getOrganizationByID
    };

    return <userAuthContext.Provider value={values}>{children}</userAuthContext.Provider>;
};

export function useUserAuth() {
    return useContext(userAuthContext);
}
