import ClearIcon from "@mui/icons-material/Clear";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FitScreenOutlinedIcon from "@mui/icons-material/FitScreenOutlined";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import Button from "../../components/button";
import React, { Suspense, lazy, useEffect, useRef, useState } from "react";

import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { kml } from "@tmcw/togeojson";
import { buffer, circle, featureCollection, lineString, lineToPolygon } from "@turf/turf";

import { useUserAuth } from "../../contexts/authContext";
import { useEnv } from "../../contexts/envContext";
import { useMap } from "../../contexts/mapContext";
import { useSocket } from "../../contexts/socketContext";
import { MapDraw } from "../../map/mapDraw";
import { getAlertColorFromId } from "../../map/mapUtil";
import { ConvertFeaturesToVolumes, ConvertFeetToMeters, ConvertMetersToFeet, FormatPhoneNumber, GetNewEndDate, GetTimezone } from "../../util";
import { IsInvalidAltitude, IsInvalidPhoneNumber, IsValidDate } from "../../util";
import { Altitudes } from "../forms/altitudes";
import * as CONSTANT from "./opConstants";
import { canEditFlight, canPublishByState, canSubmitToLAANC, isFlightBlocked, validateFlightTimes } from "./opUtil";
import { retrieveSafetyJustification, showDetailsByState, updateSafetyJustification } from "./opUtil";

const FlightWorkflowDetails = lazy(() => import("./flightWorkflowDetails"));
const BypassDetails = lazy(() => import("./bypassDetails"));

export const EditFlightDialog = (props) => {
    const alertFileUploadRef = useRef(null);
    const existingAlertVolume = useRef(false);

    const [flightGeometryUpdated, setFlightGeometryUpdated] = useState(false);
    const [flightFieldsDisabled, setFlightFieldsDisabled] = useState(false);
    const [alertChangeMethod, setAlertChangeMethod] = useState("");
    const [volumeChangeMethod, setVolumeChangeMethod] = useState("");

    const [flightStart, setFlightStart] = useState("");
    const [flightEnd, setFlightEnd] = useState("");
    const [flightUuid, setFlightUuid] = useState("");
    const [flightName, setFlightName] = useState("");
    const [flightMinAltitude, setFlightMinAltitude] = useState(0);
    const [flightMaxAltitude, setFlightMaxAltitude] = useState(0);
    const [flightPriority, setFlightPriority] = useState(0);
    const [flightPilotEmail, setFlightPilotEmail] = useState("");
    const [flightPilotPhoneNumber, setFlightPilotPhoneNumber] = useState("");
    const [flightVehicleUUID, setFlightVehicleUUID] = useState(0);
    const [flightCanBePublished, setFlightCanBePublished] = useState(false);

    const [validFlightStart, setValidFlightStart] = useState();
    const [validFlightEnd, setValidFlightEnd] = useState();

    const [flightPart107, setFlightPart107] = useState(true);
    const [flightBVLOS, setFlightBLVOS] = useState(false);
    const [editFlightEntity, setEditFlightEntity] = useState({});

    const [flightRadius, setFlightRadius] = useState("");
    const [flightBuffer, setFlightBuffer] = useState(100);

    const [waypointHierarchy, setWaypointHierarchy] = useState([]);
    const [flightVolumesFinal, setFlightVolumesFinal] = useState([]);
    const [alertVolumesFinal, setAlertVolumesFinal] = useState([]);

    const [alertId, setAlertId] = useState(0);
    const [alertUuid, setAlertUuid] = useState("");
    const [alertName, setAlertName] = useState("");
    const [alertManned, setAlertManned] = useState("all");
    const [alertColorId, setAlertColorId] = useState(0);
    const [alertBuffer, setAlertBuffer] = useState("");
    const [alertMinAltitude, setAlertMinAltitude] = useState(0);
    const [alertMaxAltitude, setAlertMaxAltitude] = useState(0);

    const [showWorkflowDetail, setShowWorkflowDetail] = useState(false);
    const [bypassBlock, setBypassBlock] = useState(false);
    const [bypassReason, setBypassReason] = useState(null);
    const [flightSubmitToLAANC, setFlightSubmitToLAANC] = useState(false);
    const [flightState, setFlightState] = useState("");
    const [flightAdvisories, setFlightAdvisories] = useState([]);

    const [laancVolumes, setLAANCVolumes] = useState([]);
    const [selectedLaancVolumeNames, setSelectedLaancVolumeNames] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);
    const [page, setPage] = useState(0);

    const { user, snackbar, setSnackbar, handleFailedFetch } = useUserAuth();
    const { visibleDrones, getDrones, colors } = useMap();
    const { socket } = useSocket();
    const { laancOn } = useEnv();

    const [safetyJustification, setSafetyJustification] = useState("");

    useEffect(() => {
        getDrones();
    }, []);

    // listen for edits to the operation
    useEffect(() => {
        socket.on("op_edit", (message) => {
            const operation = message;
            if (operation.flight_uuid !== props.editFlightVolume.flight_uuid) {
                return;
            }
            if (operation.state === CONSTANT.DECONFLICTION_BLOCKED_STATUS) {
                if (laancOn === "true") {
                    handlePublishOperation(operation);
                    return;
                } else {
                    alert("Your updated flight is conflicted, please update before proceeding.");
                    return;
                }
            } else if (operation.state === CONSTANT.LAANC_REQUIRED_STATUS) {
                return alert("This operation requires FAA Approval. If you wish to proceed, delete it and create another or modify your operational volume.");
            } else if (operation.state === CONSTANT.PRECHECK_BLOCKED_STATUS) {
                return alert("Your updated flight is blocked, please update before proceeding.");
            } else if (operation.state === CONSTANT.LAANC_NOT_REQUIRED_STATUS) {
                handlePublishOperation(operation);
            } else if (laancOn === "false" && operation.state === CONSTANT.DECONFLICTION_CLEAR_STATUS) {
                handlePublishOperation(operation);
            } else if (operation.state !== CONSTANT.DECONFLICTION_CHECK_STATUS && operation.state !== CONSTANT.DECONFLICTION_CLEAR_STATUS) {
                return alert("Unable to update this operation.");
            }
        });
        return () => {
            socket.off("op_edit");
        };
    }, []);

    // initialize variables
    useEffect(() => {
        if (showDetailsByState(props.editFlightVolume.state, laancOn)) {
            setShowWorkflowDetail(true);
        }
        const editFlight = props.editFlightVolume;
        const editStart = new Date(editFlight.time_start);
        const editEnd = new Date(editFlight.time_end);
        const editVolume = editFlight.volumes[0];
        const editMinAltitude = ConvertMetersToFeet(editVolume.altitude_min_agl_m);
        const editMaxAltitude = ConvertMetersToFeet(editVolume.altitude_max_agl_m);

        let editFlightVolumes = [];
        editFlight.volumes.forEach((volume, i) => {
            if (volume.off_nominal) {
                return;
            } else if (volume.circle) {
                let editFlightVertices = [];
                const editFlightLng = volume.circle.center.lng;
                const editFlightLat = volume.circle.center.lat;
                const radius = ConvertMetersToFeet(volume.circle.radius_m);

                editFlightVertices.push({ lng: editFlightLng, lat: editFlightLat });
                editFlightVolumes.push({ id: i, type: "circle", vertices: editFlightVertices });

                setFlightRadius(radius);
                setVolumeChangeMethod("Circle");
            } else if (volume.polygon) {
                let editFlightVertices = [];
                volume.polygon.forEach(({ lng, lat }) => {
                    editFlightVertices.push({ lng: lng, lat: lat });
                });
                editFlightVolumes.push({ id: i, type: "polygon", vertices: editFlightVertices });
                setVolumeChangeMethod("Polygon");
            }
        });
        setFlightStart(editStart);
        setValidFlightStart(editStart);

        setFlightEnd(editEnd);
        setValidFlightEnd(editEnd);

        setFlightUuid(editFlight.flight_uuid);
        setFlightName(editFlight.name);
        setFlightMinAltitude(editMinAltitude);
        setFlightMaxAltitude(editMaxAltitude);
        setFlightPriority(editFlight.priority);
        setFlightPilotEmail(editFlight.pilot_email);
        setFlightPilotPhoneNumber(editFlight.pilot_phone_number);
        setFlightVehicleUUID(visibleDrones.some((drone) => drone.vehicle_uuid === editFlight.vehicle_uuid) ? editFlight.vehicle_uuid : "");
        setFlightPart107(editFlight.part_107);
        setFlightBLVOS(editFlight.bvlos);
        setFlightCanBePublished(canPublishByState(editFlight.state, laancOn));
        setFlightSubmitToLAANC(canSubmitToLAANC(editFlight.state, laancOn));

        if (editFlight.waypoints && editFlight.waypoints.length > 0) {
            setWaypointHierarchy(editFlight.waypoints);
        }

        setFlightVolumesFinal(editFlightVolumes);
        setEditFlightEntity(editFlight);

        const alertVolume = props.alertVolumes.find((alert) => {
            return alert.flight_uuid === editFlight.flight_uuid;
        });
        if (alertVolume) {
            const editAlertMinAltitude = ConvertMetersToFeet(alertVolume.altitude_min_agl_m);
            const editAlertMaxAltitude = ConvertMetersToFeet(alertVolume.altitude_max_agl_m);
            existingAlertVolume.current = true;

            setAlertId(alertVolume.id);
            setAlertUuid(alertVolume.uuid);
            setAlertVolumesFinal(alertVolume.polygons);
            setAlertName(alertVolume.name);
            setAlertManned(alertVolume.manned);
            setAlertColorId(alertVolume.color_id);
            setAlertMinAltitude(editAlertMinAltitude);
            setAlertMaxAltitude(editAlertMaxAltitude);
        } else {
            setAlertChangeMethod("Clear");
        }

        if (editFlight.laanc != null) {
            const newLaancVolumes = editFlight.laanc.volumes.map((volume, i) => {
                return { ...volume, name: `Volume ${i + 1}`, rowIndex: i + 1 };
            });
            setLAANCVolumes(newLaancVolumes);
            const safety_justification = retrieveSafetyJustification(editFlight);
            setSafetyJustification(safety_justification);
        }
    }, []);

    // update operation volume when updated through redis
    useEffect(() => {
        const operation = props.publishedOperations.find((operation) => {
            return operation.flight_uuid === props.editFlightVolume.flight_uuid;
        });
        if (operation !== undefined && showWorkflowDetail === true) {
            updateEditEntityValues(operation);
        }
    }, [props.publishedOperations, showWorkflowDetail]);

    // update operation volume when updated through redis
    useEffect(() => {
        const operation = props.planningOperations.find((operation) => {
            return operation.flight_uuid === props.editFlightVolume.flight_uuid;
        });
        if (operation !== undefined && showWorkflowDetail === true) {
            updateEditEntityValues(operation);
        }
    }, [props.planningOperations, showWorkflowDetail]);

    const updateEditEntityValues = (operation) => {
        setFlightState(operation.state);
        setFlightAdvisories(operation.advisories);
        setFlightCanBePublished(canPublishByState(operation.state, laancOn));
        setFlightSubmitToLAANC(canSubmitToLAANC(operation.state, laancOn));

        setEditFlightEntity(operation);
        props.setEditFlightVolume(operation);

        if (operation.laanc != null) {
            const newLaancVolumes = operation.laanc.volumes.map((volume, i) => {
                return { ...volume, name: `Volume ${i + 1}`, rowIndex: i + 1 };
            });
            setLAANCVolumes(newLaancVolumes);
            const safety_justification = retrieveSafetyJustification(operation);
            setSafetyJustification(safety_justification);
        }
    };

    const handleFlightAltitudeChange = (minAltitude, maxAltitude) => {
        setFlightMinAltitude(minAltitude);
        setFlightMaxAltitude(maxAltitude);
        setFlightCanBePublished(false);
        setFlightGeometryUpdated(true);
    };

    const handleAlertAltitudeChange = (minAltitude, maxAltitude) => {
        setAlertMinAltitude(minAltitude);
        setAlertMaxAltitude(maxAltitude);
    };

    const handleAlertVolumeChange = (e, value) => {
        if (!value) {
            return;
        }
        if (alertVolumesFinal.length > 0) {
            const response = confirm("This will remove all previously created alert volumes. Do you wish to continue?");
            if (response === false) {
                return;
            }
        }
        if (value === "KML") {
            alertFileUploadRef.current.click();
        } else if (value === "Clear") {
            setAlertVolumesFinal([]);
        } else if (value === "Automatic") {
            if (flightVolumesFinal.length === 0) {
                alert("Please create a flight volume before selecting this method");
                return;
            }
            const alertColorRgb = getAlertColorFromId(alertColorId, colors);
            const alertVolumes = flightVolumesFinal.map((volume) => {
                return {
                    ...volume,
                    name: alertName,
                    color: alertColorRgb,
                    altitude_lower_hae: ConvertFeetToMeters(alertMinAltitude || 0),
                    altitude_upper_hae: ConvertFeetToMeters(alertMaxAltitude || 0),
                    positions: volume.vertices.reduce((acc, { lng, lat }) => {
                        return [...acc, lng, lat];
                    }, [])
                };
            });
            setAlertVolumesFinal(alertVolumes);
        }
        setAlertChangeMethod(value);
        existingAlertVolume.current = false;
    };

    const handleAlertFileChange = (e) => {
        if (e.target.files[0].size > 500000) return setSnackbar({ children: "Please enter a file less than 500KB", severity: "error" });

        if (!/(\.kml|\.kmz)$/i.exec(e.target.files[0].name.toLowerCase()))
            return setSnackbar({ children: "Please enter a valid file type", severity: "error" });

        let fileData = new FileReader();
        fileData.onloadend = (e) => {
            const content = e.target.result;
            const kmlParsed = new DOMParser().parseFromString(content, "text/xml");

            const converted = kml(kmlParsed);
            const features = converted.features;
            const alert = ConvertFeaturesToVolumes(features, flightStart, flightEnd, user);

            if (!alert.volumes.length) {
                return setSnackbar({ children: "Please enter a file with one or more valid polygons", severity: "error" });
            }
            const minHeight = ConvertMetersToFeet(alert.minAlt);
            const maxHeight = ConvertMetersToFeet(alert.maxAlt);
            alert.volumes.forEach((volume) => {
                volume.altitude_lower_hae = alert.minAlt;
                volume.altitude_upper_hae = alert.maxAlt;
            });
            setAlertName(alert.opName);
            setAlertMinAltitude(minHeight);
            setAlertMaxAltitude(maxHeight);
            setAlertVolumesFinal(alert.volumes);
        };
        fileData.readAsText(e.target.files[0]);
    };

    const checkValidation = () => {
        return checkFlightValidation() && checkAlertValidation();
    };

    const checkFlightValidation = () => {
        const flightRad = parseFloat(flightRadius);
        const flightBuff = parseFloat(flightBuffer);
        const badName = !flightName || (flightName && !flightName.trim());
        const badPhone = IsInvalidPhoneNumber(flightPilotPhoneNumber);

        if (!flightVolumesFinal.length || badName || badPhone) {
            return false;
        }
        if (IsInvalidAltitude(flightMinAltitude, flightMaxAltitude)) {
            return false;
        }
        if (volumeChangeMethod === "Circle" && (isNaN(flightRad) || flightRad <= 0)) {
            return false;
        }
        if (volumeChangeMethod === "Waypoints" && (isNaN(flightBuff) || flightBuff <= 0)) {
            return false;
        }
        if (!flightVehicleUUID) {
            return false;
        }
        return true;
    };

    const checkAlertValidation = () => {
        const alertBuff = parseFloat(alertBuffer);
        const alert = alertChangeMethod !== "Clear" ? true : false;
        const badAlertName = !alertName || (alertName && !alertName.trim());

        if (alert && (!alertVolumesFinal || !alertVolumesFinal.length || badAlertName)) {
            return false;
        }
        if (alert && IsInvalidAltitude(alertMinAltitude, alertMaxAltitude)) {
            return false;
        }
        if (alertChangeMethod === "Automatic" && (isNaN(alertBuff) || alertBuff < 0)) {
            return false;
        }
        return true;
    };

    const handlePublish = (state) => {
        if (!validateFlightTimes(flightStart, flightEnd, props.publishedFlight, setSnackbar)) {
            return;
        }

        let bypassJSON;
        if (bypassBlock) {
            if (!bypassReason) return setSnackbar({ children: "Please enter bypass reason", severity: "error" });

            bypassJSON = {
                timestamp: new Date().toISOString(),
                explanation: bypassReason
            };
        }

        if (!checkValidation()) {
            return setSnackbar({ children: "Please fill out the required fields", severity: "error" });
        }
        const radius = parseFloat(flightRadius);
        const minAlt = parseFloat(flightMinAltitude);
        const maxAlt = parseFloat(flightMaxAltitude);

        const newFlightVolumes = [];
        const newFlightRadius = ConvertFeetToMeters(radius);
        const minFlightAltitude = ConvertFeetToMeters(minAlt);
        const maxFlightAltitude = ConvertFeetToMeters(maxAlt);

        const awaitOperation = { ...props.editFlightVolume };
        awaitOperation.time_start = new Date(flightStart).toISOString();
        awaitOperation.time_end = new Date(flightEnd).toISOString();
        awaitOperation.name = flightName;
        awaitOperation.priority = flightPriority;
        awaitOperation.vehicle_uuid = flightVehicleUUID;
        awaitOperation.state = state;
        awaitOperation.waypoints = waypointHierarchy;
        awaitOperation.part_107 = flightPart107;
        awaitOperation.bvlos = flightBVLOS;
        awaitOperation.block_override = bypassJSON;
        awaitOperation.pilot_phone_number = flightPilotPhoneNumber;

        flightVolumesFinal.forEach((volume) => {
            const newAwaitOperationVertices = volume.vertices;
            const newAwaitOperationVolume = {
                time_start: new Date(flightStart).toISOString(),
                time_end: new Date(flightEnd).toISOString(),
                altitude_min_agl_m: minFlightAltitude,
                altitude_max_agl_m: maxFlightAltitude
            };
            if (volume.type === "circle") {
                newAwaitOperationVolume.circle = {
                    center: {
                        lng: newAwaitOperationVertices[0].lng,
                        lat: newAwaitOperationVertices[0].lat
                    },
                    radius_m: newFlightRadius
                };
            } else if (volume.type === "polygon") {
                newAwaitOperationVolume.polygon = newAwaitOperationVertices;
            }
            newFlightVolumes.push(newAwaitOperationVolume);
        });
        awaitOperation.volumes = newFlightVolumes;

        //update any Further Coordination volumes with the optional Safety Justification;
        updateSafetyJustification(awaitOperation, safetyJustification);

        let body = "";
        let route = "";
        if (props.publishedFlight) {
            awaitOperation.state = CONSTANT.DECONFLICTION_CHECK_STATUS;
            awaitOperation.version = parseInt(awaitOperation.version) + 1;
            awaitOperation.updated_user_id = user.id;

            route = "/api/op/edit";
            body = awaitOperation;
        } else {
            if (state == CONSTANT.DECONFLICTION_CHECK_STATUS) {
                awaitOperation.laanc = null;
                awaitOperation.faa_approval_required = null;
            }

            route = state === CONSTANT.ACCEPTED_STATUS ? "/api/op/publish" : "/api/op/deconflict";
            body = state === CONSTANT.ACCEPTED_STATUS ? [awaitOperation] : awaitOperation;
        }
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body)
        };
        fetch(route, requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => {
                if (!props.publishedFlight && route != "/api/op/deconflict") {
                    handleClose();
                    handleCheckAlertVolumeChanged();
                } else if (!props.publishedFlight) {
                    if (laancOn === "true") {
                        setShowWorkflowDetail(true);
                    } else {
                        handleClose();
                    }
                }
            })
            .catch((err) => handleFailedFetch(err));

        if (alertUuid || alertName) {
            if (alertChangeMethod === "Clear") {
                const requestOptions = {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        created_user_id: props.editFlightVolume ? props.editFlightVolume.created_user_id : undefined,
                        organization_id: props.editFlightVolume ? props.editFlightVolume.organization_id : undefined
                    })
                };
                fetch(`api/alertVolume/delete/${flightUuid}`, requestOptions)
                    .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                    .catch((err) => handleFailedFetch(err));
                return;
            }

            const minAlertAltitude = parseFloat(alertMinAltitude);
            const maxAlertAltitude = parseFloat(alertMaxAltitude);
            const alertColor = props.colors.find(({ id }) => id === alertColorId).color;
            const alertRGB = props.colors.find(({ id }) => id === alertColorId).color_rgb;

            const polygons = [];
            alertVolumesFinal.forEach((vol) => {
                const polygon = {
                    type: "polygon",
                    vertices: vol.vertices
                };
                polygons.push(polygon);
            });

            const finalAlertVolume = {
                name: alertName,
                manned: alertManned,
                created_user_id: user.id,
                date_updated: new Date().toISOString(),
                date_created: new Date().toISOString(),
                updated_user_id: user.id,
                organization_id: user.organization_id,
                altitude_min_agl_m: ConvertFeetToMeters(minAlertAltitude),
                altitude_max_agl_m: ConvertFeetToMeters(maxAlertAltitude),
                time_start: new Date(flightStart).toISOString(),
                time_end: new Date(flightEnd).toISOString(),
                polygons: polygons,
                uuid: alertUuid,
                flight_name: flightName,
                flight_uuid: flightUuid,
                flight_status: state,
                color_id: alertColorId,
                color_name: alertColor,
                color_rgb: alertRGB,
                deleted: false,
                id: alertId
            };
            const requestOptions = {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(finalAlertVolume)
            };
            fetch("api/alertVolume/insertUpdate", requestOptions)
                .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                .then((alert) => {
                    if (alert.uuid) {
                        setAlertUuid(alert.uuid);
                        setAlertId(alert.id);
                    }
                })
                .catch((err) => handleFailedFetch(err));
        }
    };

    const handlePublishOperation = (operation) => {
        const newOperation = { ...operation };
        newOperation.state = props.editFlightVolume.state;
        newOperation.version = parseInt(operation.version) + 1;
        newOperation.updated_user_id = user.id;

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(newOperation)
        };

        fetch("/api/op/updatePublish", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => {
                handleClose();
                handleCheckAlertVolumeChanged();
            })
            .catch((err) => handleFailedFetch(err));
    };

    const handleDeleteOperation = () => {
        if (!confirm("Are you sure you want to Cancel this operation?")) {
            return;
        }

        let operation = { ...props.editFlightVolume };
        operation.state = "ENDED";
        operation.updated_user_id = user.id;
        operation.version = operation.version + 1;

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(operation)
        };
        fetch("/api/op/delete", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => handleClose())
            .catch((err) => handleFailedFetch(err));
    };

    const handleUpdateVolumeState = (id, state) => {
        const updatedOperation = { ...editFlightEntity };

        updatedOperation.laanc.volumes.forEach((volume) => {
            if (volume.id === id) {
                volume.state = state;
            }
        });

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(updatedOperation)
        };

        fetch("/api/op/deconflict", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .catch((err) => handleFailedFetch(err));
    };

    const handleClose = () => {
        props.setEditFlightDialogOpen(false);
    };

    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        const phoneNumber = FormatPhoneNumber(value);
        setFlightPilotPhoneNumber(phoneNumber);
    };

    const handleAlertBufferChange = (e) => {
        if (e.target.value) {
            const newBuffer = parseFloat(e.target.value);
            const features = [];
            flightVolumesFinal.forEach(({ type, vertices }) => {
                if (type === "circle") {
                    const center = [vertices[0].lng, vertices[0].lat];
                    const feature = circle(center, flightRadius / 5280, { steps: 64, units: "miles" });
                    features.push(feature);
                } else if (type === "polygon") {
                    const coordinates = vertices.map(({ lng, lat }) => [lng, lat]);
                    const linestring = lineString(coordinates);
                    const feature = lineToPolygon(linestring);
                    features.push(feature);
                }
            });
            const collection = featureCollection(features);
            const geojson = buffer(collection, newBuffer / 5280.0, { units: "miles" });
            const volumes = [];
            geojson.features.forEach(({ geometry }) => {
                const type = "polygon";
                const color = props.colors.find(({ id }) => id === alertColorId).color_rgb;
                const vertices = geometry.coordinates[0].map((arr) => {
                    return { lng: arr[0], lat: arr[1] };
                });
                const positions = geometry.coordinates[0].reduce((acc, current) => {
                    return acc.concat(current);
                });
                volumes.push({
                    type: type,
                    name: alertName,
                    altitude_lower_hae: alertMinAltitude || 0,
                    altitude_upper_hae: alertMaxAltitude || 0,
                    color: color,
                    positions: positions,
                    vertices: vertices
                });
            });
            setAlertVolumesFinal(volumes);
        }
        setAlertBuffer(e.target.value);
    };

    const handleCheckAlertVolumeChanged = () => {
        if (existingAlertVolume.current && flightGeometryUpdated) {
            alert(
                "The alert volume geometry could not be updated with this request. If you wish to update it please visit the Alert Volume tab from the Airspace Manager and create a new one."
            );
        }
    };

    const handleStartDateChange = (value) => {
        const prev_start = validFlightStart;
        const prev_end = validFlightEnd;

        if (IsValidDate(value)) {
            const new_end = GetNewEndDate(prev_start, prev_end, value);
            setFlightEnd(new_end);
            setValidFlightStart(value);
            setValidFlightEnd(new_end);
        }
        setFlightStart(value);
    };

    const handleEndDateChange = (value) => {
        if (IsValidDate(value)) {
            setValidFlightEnd(value);
        }
        setFlightEnd(value);
    };

    const handleEditButtonClicked = () => {
        setShowWorkflowDetail(false);
        setFlightSubmitToLAANC(false);
        setBypassBlock(false);
        setFlightCanBePublished(false);
    };

    const handleLaancVolumeSelected = (names) => {
        if (names.length > 0) {
            const selectedName = names[names.length - 1];
            const index = laancVolumes.findIndex(({ name }) => name === selectedName);
            if (index !== -1) {
                const newPage = Math.floor(index / 3);
                setPage(newPage);
            }
            setSelectedLaancVolumeNames([selectedName]);
            setSelectionModel([selectedName]);
        } else if (names.length === 0) {
            setSelectedLaancVolumeNames([]);
            setSelectionModel([]);
        }
    };

    const DeleteButton = () => (
        <Button onClick={handleDeleteOperation} disabled={flightFieldsDisabled} data-testid="deleteButton">
            Delete
        </Button>
    );

    const UpdateButton = () => (
        <Button id="update" onClick={() => handlePublish(props.editFlightVolume.state)} disabled={flightFieldsDisabled} data-testid="updateButton">
            Update
        </Button>
    );

    const EditButton = () => (
        <Button id="edit" disabled={flightFieldsDisabled} onClick={handleEditButtonClicked} data-testid="editButton">
            Edit
        </Button>
    );

    const ProceedWithoutLAANCButton = () => (
        <Button disabled={flightFieldsDisabled} onClick={() => setBypassBlock(true)} data-testid="proceedWithoutLaancButton">
            Proceed without LAANC
        </Button>
    );

    const PublishButton = () => (
        <Button id="publish" onClick={() => handlePublish(CONSTANT.ACCEPTED_STATUS)} disabled={flightFieldsDisabled} data-testid="publishButton">
            Publish
        </Button>
    );

    const SubmitToLAANCButton = () => (
        <Button disabled={flightFieldsDisabled} onClick={() => handlePublish(CONSTANT.FAA_APPROVAL_CHECK_STATUS)} data-testid="submitToLaancButton">
            Submit to LAANC
        </Button>
    );

    const PublishWithoutLAANCButton = () => (
        <Button onClick={() => handlePublish(CONSTANT.ACCEPTED_STATUS)} disabled={flightFieldsDisabled} data-testid="publishWithoutLaancButton">
            Publish Without LAANC
        </Button>
    );

    const SubmitDeconflictionButton = () => (
        <Button id="submitDeconfliction" onClick={() => handlePublish(CONSTANT.DECONFLICTION_CHECK_STATUS)} disabled={flightFieldsDisabled}>
            Submit Deconfliction
        </Button>
    );

    const WorkflowDetailButtons = () => (
        <>
            {canEditFlight(flightState) ? <EditButton /> : null}
            {isFlightBlocked(flightState) ? bypassBlock ? <PublishWithoutLAANCButton /> : <ProceedWithoutLAANCButton /> : null}
        </>
    );

    const NoPublishDeconflictionButtons = () => <>{!showWorkflowDetail ? <SubmitDeconflictionButton /> : null}</>;

    return (
        <Dialog
            onClose={() => props.setEditFlightDialogOpen(false)}
            open={props.editFlightDialogOpen}
            maxWidth="lg"
            fullWidth
            PaperProps={{ sx: { maxHeight: "98vh" } }}
        >
            <DialogTitle sx={{ pt: "8px", pb: "8px" }}>Edit Flight</DialogTitle>
            <DialogContent sx={{ pb: 0 }}>
                <MapDraw
                    type={props.type}
                    canEdit={!showWorkflowDetail}
                    editVolume={props.editFlightVolume}
                    activeDrawMethod={volumeChangeMethod}
                    setActiveDrawMethod={setVolumeChangeMethod}
                    constraints={props.constraints}
                    operations={props.publishedOperations}
                    start={flightStart}
                    end={flightEnd}
                    minAltitude={flightMinAltitude}
                    setMinAltitude={setFlightMinAltitude}
                    maxAltitude={flightMaxAltitude}
                    setMaxAltitude={setFlightMaxAltitude}
                    radius={flightRadius}
                    waypointBuffer={flightBuffer}
                    setWaypointHierarchy={setWaypointHierarchy}
                    disabled={flightFieldsDisabled}
                    setDisabled={setFlightFieldsDisabled}
                    finalVolumes={flightVolumesFinal}
                    setFinalVolumes={setFlightVolumesFinal}
                    alertColorId={alertColorId}
                    alertVolumes={alertVolumesFinal}
                    setAlertVolumes={setAlertVolumesFinal}
                    alertMinAltitude={alertMinAltitude}
                    alertMaxAltitude={alertMaxAltitude}
                    alertChangeMethod={alertChangeMethod}
                    setAlertChangeMethod={setAlertChangeMethod}
                    flightGeometryUpdated={flightGeometryUpdated}
                    setFlightGeometryUpdated={setFlightGeometryUpdated}
                    flightCanBePublished={flightCanBePublished}
                    setFlightCanBePublished={setFlightCanBePublished}
                    laancVolumes={laancVolumes}
                    selectedLaancVolumeNames={selectedLaancVolumeNames}
                    handleLaancVolumeSelected={handleLaancVolumeSelected}
                    setName={setFlightName}
                />

                <Box>
                    {showWorkflowDetail ? (
                        <>
                            {bypassBlock ? (
                                <Suspense fallback={<></>}>
                                    <BypassDetails setBypassReason={setBypassReason} />
                                </Suspense>
                            ) : (
                                <Suspense fallback={<></>}>
                                    <FlightWorkflowDetails
                                        state={flightState}
                                        advisories={flightAdvisories}
                                        volumes={laancVolumes}
                                        setSelectedLaancVolumeNames={setSelectedLaancVolumeNames}
                                        handleUpdateVolume={handleUpdateVolumeState}
                                        selectionModel={selectionModel}
                                        handleLaancVolumeSelected={handleLaancVolumeSelected}
                                        page={page}
                                        setPage={setPage}
                                        setSafetyJustification={setSafetyJustification}
                                    />
                                </Suspense>
                            )}
                        </>
                    ) : (
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 3, pt: 3, pb: 1 }}>
                            <Divider textAlign="left" flexItem>
                                Flight Information
                            </Divider>
                            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2 }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        label={`Start Time (${GetTimezone()}) *`}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                error: snackbar && !validateFlightTimes(flightStart, flightEnd, props.publishedFlight),
                                                inputProps: { "data-testid": "startTime" }
                                            }
                                        }}
                                        onChange={handleStartDateChange}
                                        disabled={flightFieldsDisabled}
                                        value={dayjs(flightStart)}
                                    />
                                    <DateTimePicker
                                        label={`End Time (${GetTimezone()}) *`}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                error: snackbar && !validateFlightTimes(flightStart, flightEnd, props.publishedFlight),
                                                inputProps: { "data-testid": "endTime" }
                                            }
                                        }}
                                        onChange={handleEndDateChange}
                                        disabled={flightFieldsDisabled}
                                        value={dayjs(flightEnd)}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <Box
                                sx={{
                                    display: "grid",
                                    gridTemplateColumns:
                                        volumeChangeMethod === "Waypoints" || volumeChangeMethod === "Circle" ? "1fr 1fr 1fr 1fr 1fr" : "1fr 1fr 1fr 1fr",
                                    gap: 2
                                }}
                            >
                                <TextField
                                    onChange={(e) => setFlightName(e.target.value)}
                                    error={snackbar && !flightName}
                                    disabled={flightFieldsDisabled}
                                    sx={{ mt: 0, mb: 0 }}
                                    value={flightName}
                                    variant="outlined"
                                    margin="dense"
                                    label="Flight Name"
                                    fullWidth
                                    id="flightName"
                                    inputProps={{ "data-testid": "flightName" }}
                                />
                                <FormControl disabled={flightFieldsDisabled}>
                                    <InputLabel id="select-priority-air">Flight Priority</InputLabel>
                                    <Select
                                        value={flightPriority}
                                        id="selectType"
                                        labelId="select-label"
                                        label="Flight Priority"
                                        onChange={(e) => {
                                            setFlightCanBePublished(false);
                                            setFlightPriority(e.target.value);
                                        }}
                                        inputProps={{ "data-testid": "selectType" }}
                                    >
                                        <MenuItem value={CONSTANT.PERSONAL_TYPE} id="optionNoPriority">
                                            0 - No Priority/Personal/Hobbyist
                                        </MenuItem>
                                        <MenuItem value={CONSTANT.TRAINING_TYPE} id="optionTraining">
                                            10 - Training
                                        </MenuItem>
                                        <MenuItem value={CONSTANT.COMMERCIAL_TYPE} id="optionCommercial">
                                            20 - Commercial
                                        </MenuItem>
                                        <MenuItem value={CONSTANT.PUBLIC_SAFETY_TYPE} id="optionPublicSafety">
                                            30 - Public Safety
                                        </MenuItem>
                                        <MenuItem value={CONSTANT.EMERGENCY_TYPE} id="optionEmergency">
                                            40 - Emergency
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    margin="dense"
                                    type="number"
                                    label="Buffer"
                                    fullWidth
                                    value={flightBuffer}
                                    disabled={flightFieldsDisabled}
                                    onWheel={(e) => e.target.blur()}
                                    onChange={(e) => {
                                        setFlightGeometryUpdated(true);
                                        setFlightBuffer(e.target.value);
                                    }}
                                    error={snackbar && (isNaN(flightBuffer) || parseFloat(flightBuffer) <= 0)}
                                    sx={{ my: 0, display: volumeChangeMethod === "Waypoints" ? "block" : "none" }}
                                    InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                                    id="flightBuffer"
                                    inputProps={{ "data-testid": "flightBuffer" }}
                                />
                                <TextField
                                    margin="dense"
                                    label="Radius"
                                    fullWidth
                                    type="number"
                                    value={flightRadius}
                                    disabled={flightFieldsDisabled}
                                    onWheel={(e) => e.target.blur()}
                                    onChange={(e) => {
                                        setFlightGeometryUpdated(true);
                                        setFlightRadius(e.target.value);
                                    }}
                                    error={snackbar && (isNaN(flightRadius) || parseFloat(flightRadius) <= 0)}
                                    sx={{ my: 0, display: volumeChangeMethod === "Circle" ? "block" : "none" }}
                                    InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                                    id="flightRadius"
                                    inputProps={{ "data-testid": "flightRadius" }}
                                />
                                <Altitudes
                                    minAltitude={flightMinAltitude}
                                    maxAltitude={flightMaxAltitude}
                                    handleAltitudeChange={handleFlightAltitudeChange}
                                    disabled={flightFieldsDisabled}
                                    error={snackbar && IsInvalidAltitude(flightMinAltitude, flightMaxAltitude)}
                                />
                            </Box>
                            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: 2 }}>
                                <TextField
                                    value={flightPilotEmail}
                                    sx={{ mt: 0, mb: 0 }}
                                    label="Flight Pilot"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    disabled
                                />
                                <TextField
                                    value={flightPilotPhoneNumber}
                                    disabled={flightFieldsDisabled}
                                    onChange={handlePhoneNumberChange}
                                    error={snackbar && IsInvalidPhoneNumber(flightPilotPhoneNumber)}
                                    sx={{ mt: 0, mb: 0 }}
                                    label="Flight Pilot Phone Number"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    id="phoneNumber"
                                    inputProps={{ "data-testid": "phoneNumber" }}
                                />
                                <FormControl disabled={flightFieldsDisabled}>
                                    <InputLabel id="select-vehicle-label">Flight Vehicle</InputLabel>
                                    <Select
                                        id="selectVehicle"
                                        labelId="select-vehicle-label"
                                        label="Flight Vehicle"
                                        value={flightVehicleUUID}
                                        onChange={(e) => setFlightVehicleUUID(e.target.value)}
                                        error={snackbar && !flightVehicleUUID}
                                        inputProps={{ "data-testid": "selectVehicle" }}
                                    >
                                        {visibleDrones.map((drone, i) => (
                                            <MenuItem key={i} value={drone.vehicle_uuid} id={drone.vehicle_uuid}>
                                                {drone.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                                    <FormControlLabel
                                        label="BVLOS"
                                        labelPlacement="top"
                                        sx={{ mr: 0, ml: 0 }}
                                        control={<Switch checked={flightBVLOS} onChange={(event) => setFlightBLVOS(event.target.checked)} />}
                                    />
                                    <FormControlLabel
                                        label="Part 107"
                                        labelPlacement="top"
                                        sx={{ mr: 0, ml: 0 }}
                                        control={<Switch checked={flightPart107} onChange={(event) => setFlightPart107(event.target.checked)} />}
                                    />
                                </Box>
                            </Box>

                            <Divider textAlign="left" flexItem>
                                Alert Volume
                            </Divider>

                            <input
                                type="file"
                                ref={alertFileUploadRef}
                                style={{ display: "none" }}
                                onChange={handleAlertFileChange}
                                onClick={(e) => {
                                    e.target.value = "";
                                }}
                                data-testid="fileUpload"
                            />

                            <ToggleButtonGroup fullWidth exclusive value={alertChangeMethod} onChange={handleAlertVolumeChange}>
                                <ToggleButton fullWidth value="KML" disabled={flightFieldsDisabled} data-testid="tabKmlUpload">
                                    <Tooltip title="Upload">
                                        <FileUploadOutlinedIcon sx={{ width: "100%" }} />
                                    </Tooltip>
                                </ToggleButton>
                                <ToggleButton fullWidth value="Automatic" disabled={flightFieldsDisabled} data-testid="tabAutomatic">
                                    <Tooltip title="Automatic">
                                        <FitScreenOutlinedIcon sx={{ width: "100%" }} />
                                    </Tooltip>
                                </ToggleButton>
                                <ToggleButton fullWidth value="Clear" disabled={flightFieldsDisabled} data-testid="tabNone">
                                    <Tooltip title="Clear">
                                        <ClearIcon sx={{ width: "100%" }} />
                                    </Tooltip>
                                </ToggleButton>
                            </ToggleButtonGroup>

                            {alertChangeMethod !== "Clear" ? (
                                <>
                                    <Divider textAlign="left" flexItem>
                                        Alert Information
                                    </Divider>

                                    <Box
                                        sx={{
                                            display: "grid",
                                            gridTemplateColumns: alertChangeMethod === "Automatic" ? "1fr 1fr 1fr" : "1fr 1fr",
                                            gap: 2
                                        }}
                                    >
                                        <TextField
                                            sx={{ mt: 0, mb: 0 }}
                                            disabled={flightFieldsDisabled}
                                            onChange={(e) => setAlertName(e.target.value)}
                                            error={snackbar && !alertName}
                                            value={alertName}
                                            variant="outlined"
                                            margin="dense"
                                            label="Alert Name"
                                            fullWidth
                                            id="alertName"
                                            inputProps={{ "data-testid": "alertName" }}
                                        />
                                        <TextField
                                            sx={{ my: 0, display: alertChangeMethod === "Automatic" ? "block" : "none" }}
                                            error={snackbar && (isNaN(alertBuffer) || parseFloat(alertBuffer) < 0)}
                                            onChange={handleAlertBufferChange}
                                            disabled={flightFieldsDisabled}
                                            value={alertBuffer}
                                            variant="outlined"
                                            margin="dense"
                                            type="number"
                                            label="Alert Buffer"
                                            fullWidth
                                            onWheel={(e) => e.target.blur()}
                                            inputProps={{ "data-testid": "alertBuffer" }}
                                        />
                                        <FormControl disabled={flightFieldsDisabled}>
                                            <InputLabel id="select-vehicle-air">Alerts For</InputLabel>
                                            <Select
                                                value={alertManned}
                                                id="selectManned"
                                                label="Alerts For"
                                                onChange={(e) => setAlertManned(e.target.value)}
                                                inputProps={{ "data-testid": "selectManned" }}
                                            >
                                                <MenuItem value={"all"} id="optionAll">
                                                    All Aircraft
                                                </MenuItem>
                                                <MenuItem value={"manned"} id="optionManned">
                                                    Manned
                                                </MenuItem>
                                                <MenuItem value={"unmanned"} id="optionUnmanned">
                                                    Unmanned
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 2 }}>
                                        <Altitudes
                                            minAltitude={alertMinAltitude}
                                            maxAltitude={alertMaxAltitude}
                                            handleAltitudeChange={handleAlertAltitudeChange}
                                            disabled={flightFieldsDisabled}
                                            error={snackbar && IsInvalidAltitude(alertMinAltitude, alertMaxAltitude)}
                                        />
                                        <FormControl disabled={flightFieldsDisabled}>
                                            <InputLabel>Alert Color</InputLabel>
                                            <Select
                                                value={alertColorId}
                                                label="Alert Color"
                                                onChange={(e) => setAlertColorId(e.target.value)}
                                                id="selectColor"
                                                inputProps={{ "data-testid": "selectColor" }}
                                            >
                                                {props.colors.map(({ id, color }) => (
                                                    <MenuItem key={id} value={id} id={color}>
                                                        {color}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </>
                            ) : (
                                <></>
                            )}
                        </Box>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button sx={{ mr: "auto" }} onClick={handleClose}>
                    Close
                </Button>
                {props.publishedFlight ? (
                    <>
                        <DeleteButton />
                        <UpdateButton />
                    </>
                ) : (
                    <>
                        {showWorkflowDetail ? <WorkflowDetailButtons /> : null}
                        {flightCanBePublished ? <PublishButton /> : <NoPublishDeconflictionButtons />}
                        {flightSubmitToLAANC && !bypassBlock ? <SubmitToLAANCButton /> : null}
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};
