import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";

import { DataGrid } from "@mui/x-data-grid";
import { CustomPagination } from "../../customPagination";
import { MapPreview } from "../../map/mapPreview";
import { ConvertISOToDate, ConvertMetersToFeet } from "../../util";
import { useUserAuth } from "../../contexts/authContext";
import { useMap } from "../../contexts/mapContext";
import { useSocket } from "../../contexts/socketContext";
import { CreateAlertDialog } from "./createAlertDialog";

export function Alerts() {
    const [showMap, setShowMap] = useState(false);
    const [createAlertDialogOpen, setCreateAlertDialogOpen] = useState(false);
    const [selectedAlertVolumes, setSelectedAlertVolumes] = useState([]);
    const [alertsAreSelected, setAlertsAreSelected] = useState(false);

    const { colors, airspaces } = useMap();
    const { planningOperations, publishedOperations, constraints, alertVolumes } = useSocket();
    const { getOrganizationByID, handleFailedFetch, setSnackbar } = useUserAuth();

    const columns = [
        {
            field: "name",
            headerName: "Name",
            editable: false,
            minWidth: 150,
            flex: 1
        },
        {
            field: "time_start",
            headerName: "Start",
            editable: false,
            minWidth: 200,
            type: "dateTime",
            flex: 1,
            valueFormatter: (value) => `${ConvertISOToDate(value)}`
        },
        {
            field: "time_end",
            headerName: "End",
            editable: false,
            minWidth: 200,
            type: "dateTime",
            flex: 1,
            valueFormatter: (value) => `${ConvertISOToDate(value)}`
        },
        {
            field: "altitude_min",
            headerName: "Alt Min ft (AGL)",
            editable: false,
            minWidth: 125,
            flex: 1,
            valueGetter: (value, row) => `${ConvertMetersToFeet(row.altitude_min_agl_m)}`
        },
        {
            field: "altitude_max",
            headerName: "Alt Max ft (AGL)",
            editable: false,
            minWidth: 125,
            flex: 1,
            valueGetter: (value, row) => `${ConvertMetersToFeet(row.altitude_max_agl_m)}`
        },
        {
            field: "color_name",
            headerName: "Color",
            editable: false,
            minWidth: 75,
            flex: 1
        },
        {
            field: "organization_id",
            headerName: "Org",
            editable: false,
            minWidth: 75,
            flex: 1,
            renderCell: (cellValues) => {
                const org = getOrganizationByID(cellValues.formattedValue);
                if (org != undefined) {
                    return <div>{org.name}</div>;
                }
            }
        },
        {
            field: "flight_name",
            headerName: "Operation",
            editable: false,
            minWidth: 125,
            flex: 1
        },
        {
            field: "flight_status",
            headerName: "Op Status",
            editable: false,
            minWidth: 125,
            flex: 1,
            valueGetter: (value, row) => {
                try {
                    const operations = [...planningOperations, ...publishedOperations];
                    const operation = operations.find(({ flight_uuid }) => {
                        return row.flight_uuid === flight_uuid;
                    });
                    if (operation !== undefined) {
                        return operation.state;
                    } else {
                        return "";
                    }
                } catch (err) {
                    return "";
                }
            }
        },
        {
            field: "manned",
            headerName: "Alerts for",
            editable: false,
            minWidth: 100,
            flex: 1
        },
        {
            field: "deleted",
            headerName: "Delete",
            headerAlign: "center",
            editable: false,
            minWidth: 100,
            flex: 1,
            renderCell: ({ row }) => (
                <IconButton
                    data-testid="delete"
                    sx={{ m: "auto" }}
                    onClick={(event) => {
                        event.stopPropagation();
                        handleDeleteVolume(row);
                    }}
                    id={`delete-${row.volumeId}`}
                >
                    <DeleteIcon />
                </IconButton>
            )
        }
    ];

    useEffect(() => {
        if (alertsAreSelected === false) {
            setSelectedAlertVolumes(alertVolumes);
        }
    }, [alertVolumes, selectedAlertVolumes, alertsAreSelected]);

    const handleDeleteVolume = async (row) => {
        if (confirm("Are you sure you want to delete this alert volume?") === false) {
            return;
        }
        const volume = { ...row };
        volume.date_deleted = new Date();
        volume.deleted = true;

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(volume)
        };
        await fetch("api/alertVolume/insertUpdate", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => setSnackbar({ children: "Alert Volume Successfully Deleted", severity: "success" }))
            .catch((err) => handleFailedFetch(err));
    };

    const handleCheckboxSelected = (ids) => {
        if (ids.length > 0 && showMap === false) {
            setShowMap(true);
        }
        const selected = alertVolumes.filter(({ id }) => {
            return ids.includes(id);
        });
        if (selected.length > 0) {
            setAlertsAreSelected(true);
            setSelectedAlertVolumes(selected);
        } else {
            setAlertsAreSelected(false);
            setSelectedAlertVolumes(alertVolumes);
        }
    };

    return (
        <>
            <Box sx={{ width: 1, height: 578 }}>
                <DataGrid
                    rows={alertVolumes}
                    columns={columns}
                    checkboxSelection
                    disableVirtualization
                    disableRowSeletionOnClick
                    pagination
                    slots={{ pagination: CustomPagination }}
                    initialState={{ pagination: { paginationModel: { pageSize: 9 } } }}
                    onRowSelectionModelChange={handleCheckboxSelected}
                />
            </Box>

            <Box sx={{ my: 2, display: "flex", justifyContent: "flex-end", gap: "5px" }}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    data-testid="largePreview"
                    sx={{ mr: "auto" }}
                    onClick={() => setShowMap((prev) => !prev)}
                >
                    {showMap ? "Hide Map" : "Preview on Map"}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    data-testid="create"
                    id="createAlertVolume"
                    onClick={() => setCreateAlertDialogOpen(true)}
                >
                    Create Alert Volume
                </Button>
            </Box>

            {showMap ? (
                <Paper sx={{ pb: 2, height: "600px" }}>
                    <MapPreview
                        id={4}
                        planningOperations={planningOperations}
                        publishedOperations={publishedOperations}
                        airspaces={airspaces}
                        constraints={constraints}
                        alertVolumes={selectedAlertVolumes}
                    />
                </Paper>
            ) : (
                <></>
            )}

            {createAlertDialogOpen ? (
                <CreateAlertDialog
                    type={3}
                    colors={colors}
                    publishedOperations={publishedOperations}
                    publishedAlertVolumes={alertVolumes}
                    createAlertDialogOpen={createAlertDialogOpen}
                    setCreateAlertDialogOpen={setCreateAlertDialogOpen}
                />
            ) : (
                <></>
            )}
        </>
    );
}
