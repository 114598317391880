import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../contexts/authContext";
import { ConvertFeetToMeters, ConvertMetersToFeet, GetTimezone, StartTimeHasAlreadyPassed, getConstraintDurationFromInput } from "../../util";

const ADVISORY_TYPE = "ADVISORY";
const TRAINING_TYPE = "TRAINING";
const COMMERCIAL_TYPE = "COMMERCIAL";
const PUBLIC_SAFETY_TYPE = "PUBLIC_SAFETY";
const EMERGENCY_TYPE = "EMERGENCY";
const RESTRICTED_TYPE = "RESTRICTED";

export const ConstraintEdit = (props) => {
    const [editName, setEditName] = useState();
    const [editEnd, setEditEnd] = useState(new Date());
    const [editStart, setEditStart] = useState(new Date());
    const [editAltitude, setEditAltitude] = useState();
    const [editDuration, setEditDuration] = useState();
    const [editPriority, setEditPriority] = useState();
    const [editType, setEditType] = useState();
    const [publishedConstraint, setPublishedConstraint] = useState();

    const { handleFailedFetch } = useUserAuth();

    useEffect(() => {
        if (props.constraintEditDialogOpen) {
            const startTime = new Date(props.constraintEditEntity.startTime).getTime();
            const endTime = new Date(props.constraintEditEntity.endTime).getTime();
            const timeRemaining = Math.floor((endTime - startTime) / 1000 / 60);
            const altitude = ConvertMetersToFeet(props.constraintEditEntity.altitude_max_agl_m);

            setEditAltitude(altitude);
            setEditDuration(timeRemaining);
            setEditName(props.constraintEditEntity.name);
            setEditEnd(props.constraintEditEntity.endTime);
            setEditStart(props.constraintEditEntity.startTime);
            setEditType(props.constraintEditEntity.constraint.type);
            setPublishedConstraint(props.constraintEditEntity.constraint.state === "ACCEPTED");
        }
    }, [props.constraintEditDialogOpen]);

    useEffect(() => {
        const startTime = new Date(editStart).getTime();
        const endTime = startTime + parseInt(editDuration || 0) * 60 * 1000;
        if (!startTime) return;

        setEditStart(new Date(startTime).toISOString());
        setEditEnd(new Date(endTime).toISOString());
    }, [editDuration, editStart]);

    useEffect(() => {
        switch (editType) {
            case RESTRICTED_TYPE:
                setEditPriority(1000);
                break;
            case EMERGENCY_TYPE:
                setEditPriority(40);
                break;
            case PUBLIC_SAFETY_TYPE:
                setEditPriority(30);
                break;
            case COMMERCIAL_TYPE:
                setEditPriority(20);
                break;
            case TRAINING_TYPE:
                setEditPriority(10);
                break;
            default:
                setEditPriority(0);
        }
    }, [editType]);

    // Fires if user hits save
    const handleUpdateConstraint = async () => {
        if (!editName || (!publishedConstraint && StartTimeHasAlreadyPassed(editStart))) {
            props.setSnackbar({ children: "Please fill out the required fields.", severity: "error" });
            return false;
        }

        if (isNaN(parseFloat(editAltitude)) || parseFloat(editAltitude) <= 0) {
            props.setSnackbar({ children: "Please enter a valid altitude", severity: "error" });
            return false;
        }

        if (isNaN(parseFloat(editDuration)) || parseFloat(editDuration) <= 0) {
            props.setSnackbar({ children: "Please enter a valid duration", severity: "error" });
            return false;
        }

        const constraint = props.constraintEditEntity.constraint;
        constraint.name = editName;
        constraint.time_end = editEnd;
        constraint.time_start = editStart;
        constraint.altitude = ConvertFeetToMeters(parseFloat(editAltitude));
        constraint.version = parseInt(constraint.version) + 1;
        constraint.type = editType;
        constraint.priority = editPriority;
        constraint.volumes.forEach((vol) => {
            vol.altitude_max_agl_m = ConvertFeetToMeters(parseFloat(editAltitude));
        });

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(constraint)
        };
        await fetch("api/constraint/update", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => props.setSnackbar({ children: "Constraint successfully updated", severity: "success" }))
            .catch((err) => handleFailedFetch(err));

        props.setConstraintEditDialogOpen(false);
    };

    // Fires if delete button clicked
    const handleDeleteConstraint = () => {
        if (!confirm("Are you sure you want to delete this constraint?")) return;

        const constraint = props.constraintEditEntity.constraint;

        //Put Operations data over FETCH request
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(constraint)
        };
        fetch("/api/constraint/delete", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => props.setSnackbar({ children: "Constraint successfully deleted", severity: "success" }))
            .catch((err) => handleFailedFetch(err));

        props.setConstraintEditDialogOpen(false);
    };
    const handleDurationChange = (e) => {
        const input = getConstraintDurationFromInput(e.target.value);
        if (input !== undefined) {
            setEditDuration(input);
        }
    };
    return (
        <Dialog open={props.constraintEditDialogOpen} onContextMenu={(e) => e.preventDefault()} fullWidth={true} maxWidth={"sm"}>
            <DialogTitle>Edit Constraint</DialogTitle>
            <DialogContent>
                <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2, mb: 2, pt: 1 }}>
                    <TextField
                        inputProps={{ "data-testid": "editName" }}
                        onChange={(e) => setEditName(e.target.value)}
                        error={props.snackbar && !editName}
                        variant="outlined"
                        value={editName}
                        id="nameInput"
                        label="Name"
                        sx={{ flex: 1 }}
                    />

                    <TextField
                        InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                        inputProps={{ "data-testid": "editAltitude" }}
                        onChange={(e) => setEditAltitude(e.target.value)}
                        error={props.snackbar && (isNaN(parseFloat(editAltitude)) || parseFloat(editAltitude) <= 0)}
                        variant="outlined"
                        id="editAltitude"
                        label="Altitude (AGL)"
                        value={editAltitude}
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        sx={{ flex: 1 }}
                    />

                    <FormControl sx={{ flex: 1 }}>
                        <InputLabel id="select-priority-air">Type</InputLabel>
                        <Select value={editType} id="selectType" labelId="select-label" label="Type" onChange={(e) => setEditType(e.target.value)}>
                            <MenuItem value={ADVISORY_TYPE} id="advisory">
                                Advisory
                            </MenuItem>
                            <MenuItem value={TRAINING_TYPE} id="training">
                                Training
                            </MenuItem>
                            <MenuItem value={COMMERCIAL_TYPE} id="commercial">
                                Commercial
                            </MenuItem>
                            <MenuItem value={PUBLIC_SAFETY_TYPE} id="safety">
                                Public Safety
                            </MenuItem>
                            <MenuItem value={EMERGENCY_TYPE} id="emergency">
                                Emergency
                            </MenuItem>
                            <MenuItem value={RESTRICTED_TYPE} id="restricted">
                                Restricted
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
                    <Box sx={{ flex: 1 }}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateTimePicker
                                label={`Start Time (${GetTimezone()}) *`}
                                value={editStart}
                                onChange={(value) => setEditStart(value)}
                                InputProps={{ "data-testid": "start-date-picker" }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        error={props.snackbar && !publishedConstraint && StartTimeHasAlreadyPassed(editStart)}
                                        inputProps={{ ...params.inputProps, "data-testid": "startTime" }}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Box>

                    <TextField
                        error={props.snackbar && (isNaN(parseFloat(editDuration)) || parseFloat(editDuration) <= 0)}
                        inputProps={{ "data-testid": "editDuration" }}
                        InputProps={{ endAdornment: <InputAdornment position="end">mins</InputAdornment> }}
                        variant="outlined"
                        label="Duration"
                        onChange={handleDurationChange}
                        value={editDuration}
                        sx={{ flex: 1 }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDeleteConstraint} data-testid="delete" size="small" sx={{ mr: "auto" }}>
                    Delete
                </Button>
                <Button onClick={() => props.setConstraintEditDialogOpen(false)} data-testid="cancel" size="small">
                    Cancel
                </Button>
                <Button onClick={handleUpdateConstraint} data-testid="accept" size="small">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
