import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Slider from "@mui/material/Slider";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import { useUserAuth } from "../contexts/authContext";
import { useMap } from "../contexts/mapContext";
import { Paper } from "@mui/material";

export function MapSettings() {
    const [editingMapSettings, setEditingMapSettings] = useState(false);

    const [latitudeSetting, setLatitudeSetting] = useState(0);
    const [latitudeError, setLatitudeError] = useState("");
    const [longitudeSetting, setLongitudeSetting] = useState(0);
    const [longitudeError, setLongitudeError] = useState("");

    const [mapBrightnessSetting, setMapBrightnessSetting] = useState(0);
    const [weatherOpacitySetting, setWeatherOpacitySetting] = useState(0);

    const [entityLabelSetting, setEntityLabelSetting] = useState("");
    const [wellClearVolumeSetting, setWellClearVolumeSetting] = useState(false);
    const [entitySizeSetting, setEntitySizeSetting] = useState(0);

    const [predLineLengthSetting, setPredLineLengthSetting] = useState(0);
    const [predLineSetting, setPredLineSetting] = useState(false);
    const [histLineSetting, setHistLineSetting] = useState(false);
    const [volumeLabelSetting, setVolumeLabelSetting] = useState(false);
    const [volumeOpacitySetting, setVolumeOpacitySetting] = useState(0);

    const { userMapSettings, updateMapSettings, setSnackbar } = useUserAuth();
    const { fetchMapData } = useMap();

    useEffect(() => {
        if (userMapSettings) {
            initalizeUserMapSettings(userMapSettings);
        }
    }, []);

    const initalizeUserMapSettings = (settings) => {
        setLatitudeSetting(settings.latitude);
        setLongitudeSetting(settings.longitude);

        setMapBrightnessSetting(settings.brightness);
        setWeatherOpacitySetting(settings.weather_opacity);

        setEntityLabelSetting(settings.label_visible);
        setWellClearVolumeSetting(settings.well_clear_volume);
        setEntitySizeSetting(settings.entity_size);

        setPredLineLengthSetting(settings.pred_line_lead_time);
        setPredLineSetting(settings.pred_line);
        setHistLineSetting(settings.hist_line);
        setVolumeLabelSetting(settings.volume_labels);
        setVolumeOpacitySetting(settings.op_opacity);
    };

    const handleResetClick = () => {
        if (!confirm("This will reset your saved settings to the default values. Click OK if you would like to proceed")) {
            return;
        }

        const mapSettings = {
            ...userMapSettings,
            label_visible: "off",
            pred_line_lead_time: 20,
            pred_line: false,
            hist_line: false,
            well_clear_volume: false,
            volume_labels: false,
            op_opacity: 0.5,
            latitude: 39.9612,
            longitude: -82.9988,
            entity_size: 0.6,
            brightness: 1,
            weather_opacity: 1
        };
        initalizeUserMapSettings(mapSettings);
        updateMapSettings(mapSettings);

        fetchMapData(mapSettings);
        setSnackbar({
            children: "Settings successfully updated",
            severity: "success"
        });
    };

    const handleCancelMapSettings = () => {
        if (latitudeError !== "") {
            setLatitudeError("");
        }
        if (longitudeError !== "") {
            setLongitudeError("");
        }
        initalizeUserMapSettings(userMapSettings);
        setEditingMapSettings(false);
    };

    const handleSaveMapSettings = () => {
        const latitude = parseFloat(latitudeSetting);
        const longitude = parseFloat(longitudeSetting);

        let error = false;
        if (isNaN(latitude) || latitude > 90 || latitude < -90) {
            setLatitudeError(`Please enter a latitude greater than -90 and less than 90`);
            error = true;
        } else if (latitudeError !== "") {
            setLatitudeError("");
        }
        if (isNaN(longitude) || longitude > 180 || longitude < -180) {
            setLongitudeError("Please enter a longitude greater than -180 and less than 180");
            error = true;
        } else if (longitudeError !== "") {
            setLongitudeError("");
        }
        if (error === true) {
            return;
        }
        const mapSettings = {
            ...userMapSettings,
            label_visible: entityLabelSetting,
            pred_line_lead_time: predLineLengthSetting,
            pred_line: predLineSetting,
            hist_line: histLineSetting,
            well_clear_volume: wellClearVolumeSetting,
            volume_labels: volumeLabelSetting,
            op_opacity: volumeOpacitySetting,
            latitude: latitude,
            longitude: longitude,
            entity_size: parseFloat(entitySizeSetting),
            brightness: mapBrightnessSetting,
            weather_opacity: weatherOpacitySetting
        };
        updateMapSettings(mapSettings);
        fetchMapData(mapSettings);

        setEditingMapSettings(false);
        setSnackbar({
            children: "Settings successfully updated",
            severity: "success"
        });
    };

    return (
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", width: "800px" }}>
            <Box sx={{ display: "flex", flex: "row", justifyContent: "space-between" }}>
                <Typography variant="h6" noWrap>
                    Map Settings
                </Typography>
                <Box sx={{ display: "flex" }}>
                    {editingMapSettings === true ? (
                        <>
                            <Tooltip title="Save">
                                <IconButton id="save" onClick={handleSaveMapSettings}>
                                    <SaveIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Cancel">
                                <IconButton onClick={handleCancelMapSettings}>
                                    <ClearIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    ) : (
                        <>
                            <Tooltip title="Edit">
                                <IconButton id="edit" onClick={() => setEditingMapSettings(true)}>
                                    <ModeEditIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Reset">
                                <IconButton onClick={handleResetClick} data-testid="reset">
                                    <RestartAltIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                {/* Default Map Settings */}
                <Box>
                    <Typography gutterBottom>Home Location:</Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                        <TextField
                            variant="standard"
                            label="Latitude"
                            value={latitudeSetting}
                            onChange={(e) => setLatitudeSetting(e.target.value)}
                            type="number"
                            sx={{ margin: "8px 0", flex: 1 }}
                            InputProps={{ endAdornment: <InputAdornment position="end">deg</InputAdornment> }}
                            disabled={editingMapSettings === false}
                            size="small"
                            onWheel={(e) => e.target.blur()}
                            error={latitudeError !== ""}
                            helperText={latitudeError}
                            id="latitudeInput"
                        />
                        <TextField
                            variant="standard"
                            label="Longitude"
                            value={longitudeSetting}
                            onChange={(e) => setLongitudeSetting(e.target.value)}
                            type="number"
                            sx={{ margin: "8px 0", flex: 1 }}
                            InputProps={{ endAdornment: <InputAdornment position="end">deg</InputAdornment> }}
                            disabled={editingMapSettings === false}
                            size="small"
                            onWheel={(e) => e.target.blur()}
                            error={longitudeError !== ""}
                            helperText={longitudeError}
                            id="longitudeInput"
                        />
                    </Box>
                </Box>

                {/* Entity Settings */}
                <Typography>Entity Settings:</Typography>

                <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 2 }}>
                    <FormControl sx={{ flex: 1 }} disabled={editingMapSettings === false} size="small" variant="standard">
                        <InputLabel id="entity-label">Entity Labels</InputLabel>
                        <Select
                            labelId="entity-label"
                            id="entity-labels"
                            value={entityLabelSetting}
                            onChange={(e) => setEntityLabelSetting(e.target.value)}
                            label="Entity Labels"
                        >
                            <MenuItem value={"on"}>Visible</MenuItem>
                            <MenuItem value={"off"}>On Hover</MenuItem>
                            <MenuItem value={"alerts"}>In Alert Volumes</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl sx={{ flex: 1 }} disabled={editingMapSettings === false} size="small" variant="standard">
                        <InputLabel id="pred-line-label">Prediction Lines</InputLabel>
                        <Select
                            labelId="pred-line-label"
                            id="pred-line"
                            value={predLineSetting}
                            label="Prediction Lines"
                            onChange={(e) => setPredLineSetting(e.target.value)}
                        >
                            <MenuItem value={true}>Visible</MenuItem>
                            <MenuItem value={false}>Hidden</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl sx={{ flex: 1 }} disabled={editingMapSettings === false} size="small" variant="standard">
                        <InputLabel id="hist-line-label">History Lines</InputLabel>
                        <Select
                            labelId="hist-line-label"
                            id="hist-line"
                            value={histLineSetting}
                            label="History Lines"
                            onChange={(e) => setHistLineSetting(e.target.value)}
                        >
                            <MenuItem value={true}>Visible</MenuItem>
                            <MenuItem value={false}>Hidden</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl sx={{ flex: 1 }} disabled={editingMapSettings === false} size="small" variant="standard">
                        <InputLabel id="well-clear-volume-label">Well Clear Volumes</InputLabel>
                        <Select
                            labelId="well-clear-volume-label"
                            id="well-clear-volume"
                            value={wellClearVolumeSetting}
                            onChange={(e) => setWellClearVolumeSetting(e.target.value)}
                            label="Well Clear Volumes"
                        >
                            <MenuItem value={true}>Visible</MenuItem>
                            <MenuItem value={false}>Hidden</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl sx={{ flex: 1 }} disabled={editingMapSettings === false} size="small" variant="standard">
                        <InputLabel id="volume-label">Volume Labels</InputLabel>
                        <Select
                            labelId="volume-label"
                            id="volume-labels"
                            value={volumeLabelSetting}
                            onChange={(e) => setVolumeLabelSetting(e.target.value)}
                            label="Volume Labels"
                        >
                            <MenuItem value={true}>Visible</MenuItem>
                            <MenuItem value={false}>Hidden</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Box>
                    <Typography gutterBottom>Entity Size:</Typography>
                    <Slider
                        valueLabelDisplay="auto"
                        value={entitySizeSetting}
                        onChange={(e) => setEntitySizeSetting(e.target.value)}
                        step={0.05}
                        min={0.25}
                        max={1}
                        marks
                        disabled={editingMapSettings === false}
                    />
                </Box>

                <Box>
                    <Typography gutterBottom>{"Prediction Line Length (seconds):"}</Typography>
                    <Slider
                        valueLabelDisplay="auto"
                        value={predLineLengthSetting}
                        onChange={(e) => setPredLineLengthSetting(e.target.value)}
                        max={120}
                        step={5}
                        min={10}
                        marks
                        disabled={editingMapSettings === false}
                    />
                </Box>

                <Box>
                    <Typography gutterBottom>Volume Opacity:</Typography>
                    <Slider
                        valueLabelDisplay="auto"
                        value={volumeOpacitySetting}
                        onChange={(e) => setVolumeOpacitySetting(e.target.value)}
                        step={0.1}
                        min={0}
                        max={1}
                        marks
                        disabled={editingMapSettings === false}
                    />
                </Box>

                <Box>
                    <Typography gutterBottom>Weather Opacity:</Typography>
                    <Slider
                        valueLabelDisplay="auto"
                        value={weatherOpacitySetting}
                        onChange={(e) => setWeatherOpacitySetting(e.target.value)}
                        step={0.1}
                        min={0}
                        max={1}
                        marks
                        disabled={editingMapSettings === false}
                    />
                </Box>

                <Box>
                    <Typography gutterBottom>Map Brightness:</Typography>
                    <Slider
                        valueLabelDisplay="auto"
                        value={mapBrightnessSetting}
                        onChange={(e) => setMapBrightnessSetting(e.target.value)}
                        step={0.1}
                        min={0}
                        max={1}
                        marks
                        disabled={editingMapSettings === false}
                    />
                </Box>
            </Box>
        </Paper>
    );
}

export default MapSettings;
