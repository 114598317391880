import React from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";

import CloseIcon from "@mui/icons-material/Close";
import VolumeMute from "@mui/icons-material/VolumeMute";
import VolumeOff from "@mui/icons-material/VolumeOff";

export const EntityWatchToolbar = (props) => {
    const [soundOn, setSoundOn] = React.useState(true);

    const handleSoundToggle = () => {
        setSoundOn((prev) => !prev);
        props.toggleSound();
    };

    return (
        <Collapse in={props.asdWatchToolbarOpen}>
            <Box sx={{ display: "flex", flexDirection: "row", px: 1 }}>
                <List dense={true} sx={{ flex: 1, display: "grid", gridTemplateColumns: { xs: "repeat(3, 1fr)", md: "repeat(6, 1fr)" } }}>
                    <ListItem sx={{ flex: 1, alignItems: "flex-start", py: 0, pl: 0, minWidth: 0 }}>
                        <ListItemText
                            disableTypography
                            sx={{ my: 0 }}
                            primary={
                                <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
                                    Callsign
                                </Typography>
                            }
                            secondary={
                                <Typography
                                    variant="body2"
                                    sx={{ color: "#6b778c", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                                    ref={props.asdWatchEntityCallsignRef}
                                ></Typography>
                            }
                        />
                        <Divider orientation="vertical" flexItem sx={{ display: { xs: "none", md: "unset" } }} />
                    </ListItem>
                    <ListItem sx={{ flex: 1, alignItems: "flex-start", py: 0, pl: 0, minWidth: 0 }}>
                        <ListItemText
                            disableTypography
                            sx={{ my: 0 }}
                            primary={
                                <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
                                    Track Angle
                                </Typography>
                            }
                            secondary={
                                <Typography
                                    variant="body2"
                                    sx={{ color: "#6b778c", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                                    ref={props.asdWatchEntityTrackAngleRef}
                                ></Typography>
                            }
                        />
                        <Divider orientation="vertical" flexItem sx={{ display: { xs: "none", md: "unset" } }} />
                    </ListItem>
                    <ListItem sx={{ flex: 1, alignItems: "flex-start", py: 0, pl: 0, minWidth: 0 }}>
                        <ListItemText
                            sx={{ my: 0 }}
                            disableTypography
                            primary={
                                <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
                                    Speed
                                </Typography>
                            }
                            secondary={
                                <Typography
                                    variant="body2"
                                    sx={{ color: "#6b778c", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                                    ref={props.asdWatchEntitySpeedRef}
                                ></Typography>
                            }
                        />
                        <Divider orientation="vertical" flexItem sx={{ display: { xs: "none", md: "unset" } }} />
                    </ListItem>
                    <ListItem sx={{ flex: 1, alignItems: "flex-start", py: 0, pl: 0, minWidth: 0 }}>
                        <ListItemText
                            sx={{ my: 0 }}
                            disableTypography
                            primary={
                                <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
                                    AGL
                                </Typography>
                            }
                            secondary={
                                <Typography
                                    variant="body2"
                                    sx={{ color: "#6b778c", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                                    ref={props.asdWatchEntityAglRef}
                                ></Typography>
                            }
                        />
                        <Divider orientation="vertical" flexItem sx={{ display: { xs: "none", md: "unset" } }} />
                    </ListItem>
                    <ListItem sx={{ flex: 1, alignItems: "flex-start", py: 0, pl: 0, minWidth: 0 }}>
                        <ListItemText
                            sx={{ my: 0 }}
                            disableTypography
                            primary={
                                <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
                                    MSL
                                </Typography>
                            }
                            secondary={
                                <Typography
                                    variant="body2"
                                    sx={{ color: "#6b778c", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                                    ref={props.asdWatchEntityAmslRef}
                                ></Typography>
                            }
                        />
                        <Divider orientation="vertical" flexItem sx={{ display: { xs: "none", md: "unset" } }} />
                    </ListItem>
                    <ListItem sx={{ flex: 1, alignItems: "flex-start", py: 0, pl: 0, minWidth: 0 }}>
                        <ListItemText
                            sx={{ my: 0 }}
                            disableTypography
                            primary={
                                <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
                                    Alerts
                                </Typography>
                            }
                            secondary={
                                <Typography
                                    variant="body2"
                                    style={{
                                        color: "#6b778c",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap"
                                    }}
                                    ref={props.asdWatchEntityAlertRef}
                                >
                                    <span>--</span>
                                    <span></span>
                                </Typography>
                            }
                        />
                    </ListItem>
                </List>
                <Box sx={{ display: "flex", flexDirection: { xs: "column-reverse", md: "row" }, justifyContent: "center", alignItems: "center" }}>
                    <IconButton onClick={() => handleSoundToggle()}>{soundOn === true ? <VolumeMute /> : <VolumeOff />}</IconButton>
                    <IconButton onClick={() => props.setASDWatchToolbarOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
        </Collapse>
    );
};
