import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import Button from "../../components/button";

import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useUserAuth } from "../../contexts/authContext";
import { MapDraw } from "../../map/mapDraw";
import { ConvertFeetToMeters, GetTimezone, StartTimeHasAlreadyPassed, getConstraintDurationFromInput } from "../../util";

const ADVISORY_TYPE = "ADVISORY";
const TRAINING_TYPE = "TRAINING";
const COMMERCIAL_TYPE = "COMMERCIAL";
const PUBLIC_SAFETY_TYPE = "PUBLIC_SAFETY";
const EMERGENCY_TYPE = "EMERGENCY";
const RESTRICTED_TYPE = "RESTRICTED";

export function CreateConstraint(props) {
    const [inputVolumes, setInputVolumes] = useState(props.volumes);
    const [inputName, setInputName] = useState("");
    const [inputEnd, setInputEnd] = useState(null);
    const [inputStart, setInputStart] = useState(null);
    const [inputRadius, setInputRadius] = useState("");
    const [inputAltitude, setInputAltitude] = useState(0);
    const [inputPriority, setInputPriority] = useState(0);
    const [inputDuration, setInputDuration] = useState(60);
    const [inputType, setInputType] = useState(ADVISORY_TYPE);

    const [constraintFieldsDisabled, setConstraintFieldsDisabled] = useState(false);
    const [activeDrawMethod, setActiveDrawMethod] = useState("Clear");

    const { user, handleFailedFetch, snackbar, setSnackbar } = useUserAuth();

    useEffect(() => {
        const currentTime = new Date();
        const startTime = currentTime.getTime() + 10 * 60 * 1000;
        const endTime = currentTime.getTime() + (parseInt(10) + parseInt(inputDuration || 0)) * 60 * 1000;
        const startIsoString = new Date(startTime).toISOString();
        const endIsoString = new Date(endTime).toISOString();

        setInputStart(startIsoString);
        setInputEnd(endIsoString);
    }, [props.createDialogOpen]);

    useEffect(() => {
        const startTime = new Date(inputStart).getTime();
        const endTime = startTime + parseInt(inputDuration || 0) * 60 * 1000;
        if (!startTime) return;

        setInputStart(new Date(startTime).toISOString());
        setInputEnd(new Date(endTime).toISOString());
    }, [inputDuration, inputStart]);

    useEffect(() => {
        switch (inputType) {
            case RESTRICTED_TYPE:
                setInputPriority(1000);
                break;
            case EMERGENCY_TYPE:
                setInputPriority(40);
                break;
            case PUBLIC_SAFETY_TYPE:
                setInputPriority(30);
                break;
            case COMMERCIAL_TYPE:
                setInputPriority(20);
                break;
            case TRAINING_TYPE:
                setInputPriority(10);
                break;
            default:
                setInputPriority(0);
        }
    }, [inputType]);

    const isValidData = () => {
        const validName = inputName && inputName.length > 0 ? true : false;
        const validRadius = inputRadius && parseFloat(inputRadius) > 0;
        const validAltitude = inputAltitude && parseFloat(inputAltitude) > 0 ? true : false;
        const validDuration = inputDuration && parseFloat(inputDuration) > 0 ? true : false;
        const validVolumes = inputVolumes.length > 0 ? true : false;
        const validDate = !StartTimeHasAlreadyPassed(inputStart);

        if (!validName || !validDate || !validAltitude || !validDuration || !validVolumes || !inputVolumes.length) {
            return false;
        }
        if (activeDrawMethod === "Circle" && !validRadius) {
            return false;
        }
        return true;
    };

    const handlePublish = () => {
        if (isValidData() === false) {
            setSnackbar({
                children: "Please fill out the required fields and provide at least one polygon.",
                severity: "error"
            });
            return false;
        }
        const volumes = [];
        inputVolumes.forEach((volume) => {
            if (volume.type === "circle") {
                volumes.push({
                    lat: volume.vertices[0].lat,
                    lng: volume.vertices[0].lng,
                    radius: ConvertFeetToMeters(inputRadius)
                });
            } else {
                volumes.push(volume);
            }
        });
        const constraint = {
            name: inputName,
            altitude: ConvertFeetToMeters(parseFloat(inputAltitude)),
            organization: user.organization,
            organization_id: user.organization_id,
            created_user_id: user.id,
            time_start: inputStart,
            time_end: inputEnd,
            volumes: volumes,
            type: inputType,
            priority: inputPriority,
            state: "PLANNING",
            version: 1
        };

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(constraint)
        };
        fetch("api/constraint/planning", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => {
                setSnackbar({ children: "Constraint successfully created", severity: "success" });
                props.setCreateDialogOpen(false);
            })
            .catch((err) => handleFailedFetch(err));
    };
    const handleDurationChange = (e) => {
        const input = getConstraintDurationFromInput(e.target.value);
        if (input !== undefined) {
            setInputDuration(input);
        }
    };
    return (
        <Dialog open={props.createDialogOpen} fullWidth={true} maxWidth="lg">
            <DialogTitle>Create Constraint</DialogTitle>

            <DialogContent>
                <MapDraw
                    type={2}
                    canEdit={true}
                    activeDrawMethod={activeDrawMethod}
                    setActiveDrawMethod={setActiveDrawMethod}
                    constraints={props.constraints}
                    start={inputStart}
                    end={inputEnd}
                    radius={inputRadius}
                    minAltitude={0}
                    setMinAltitude={() => null}
                    maxAltitude={inputAltitude}
                    setMaxAltitude={setInputAltitude}
                    disabled={constraintFieldsDisabled}
                    setDisabled={setConstraintFieldsDisabled}
                    finalVolumes={inputVolumes}
                    setFinalVolumes={setInputVolumes}
                    setName={setInputName}
                />

                <InputLabel id="entity-label" sx={{ color: "#FFF", mt: 2 }}>
                    Constraint Details:
                </InputLabel>

                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: { xs: "1fr", md: activeDrawMethod === "Circle" ? "1fr 1fr 1fr" : "1fr 1fr" },
                        gap: 2,
                        mb: 2
                    }}
                >
                    <TextField
                        inputProps={{ "data-testid": "inputName" }}
                        disabled={constraintFieldsDisabled}
                        onChange={(e) => setInputName(e.target.value)}
                        error={snackbar && !inputName}
                        variant="outlined"
                        id="nameInput"
                        label="Name"
                        value={inputName}
                    />
                    <TextField
                        margin="dense"
                        label="Radius"
                        id="constraintRadius"
                        fullWidth
                        type="number"
                        value={inputRadius}
                        onWheel={(e) => e.target.blur()}
                        disabled={constraintFieldsDisabled}
                        onChange={(e) => setInputRadius(e.target.value)}
                        error={snackbar && (isNaN(parseFloat(inputRadius)) || parseFloat(inputRadius) <= 0)}
                        sx={{ my: 0, display: activeDrawMethod === "Circle" ? "block" : "none" }}
                        InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            label={`Start Time (${GetTimezone()}) *`}
                            value={dayjs(inputStart)}
                            disabled={constraintFieldsDisabled}
                            onChange={(value) => setInputStart(value)}
                            slotProps={{
                                textField: {
                                    error: snackbar && StartTimeHasAlreadyPassed(inputStart),
                                    inputProps: { "data-testid": "start-date-picker" }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Box>

                <Box sx={{ display: "grid", gridTemplateColumns: { xs: "1fr", md: "1fr 1fr 1fr" }, gap: 2 }}>
                    <FormControl>
                        <InputLabel id="select-priority-air">Type</InputLabel>
                        <Select
                            value={inputType}
                            disabled={constraintFieldsDisabled}
                            id="selectType"
                            labelId="select-label"
                            label="Type"
                            onChange={(e) => setInputType(e.target.value)}
                            inputProps={{ "data-testid": "selectType" }}
                        >
                            <MenuItem value={ADVISORY_TYPE}>Advisory</MenuItem>
                            <MenuItem value={TRAINING_TYPE}>Training</MenuItem>
                            <MenuItem value={COMMERCIAL_TYPE}>Commercial</MenuItem>
                            <MenuItem value={PUBLIC_SAFETY_TYPE}>Public Safety</MenuItem>
                            <MenuItem value={EMERGENCY_TYPE}>Emergency</MenuItem>
                            <MenuItem value={RESTRICTED_TYPE}>Restricted</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        inputProps={{ "data-testid": "inputAltitude" }}
                        InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                        disabled={constraintFieldsDisabled}
                        onChange={(e) => setInputAltitude(e.target.value)}
                        error={snackbar && (isNaN(parseFloat(inputAltitude)) || parseFloat(inputAltitude) <= 0)}
                        variant="outlined"
                        id="inputAltitude"
                        label="Altitude (AGL)"
                        value={inputAltitude}
                        type="number"
                        onWheel={(e) => e.target.blur()}
                    />
                    <TextField
                        error={snackbar && (isNaN(parseFloat(inputDuration)) || parseFloat(inputDuration) <= 0)}
                        inputProps={{ "data-testid": "inputDuration" }}
                        InputProps={{ endAdornment: <InputAdornment position="end">mins</InputAdornment> }}
                        variant="outlined"
                        id="durationInput"
                        label="Duration"
                        disabled={constraintFieldsDisabled}
                        onChange={handleDurationChange}
                        value={inputDuration}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.setCreateDialogOpen(false)} sx={{ mr: "auto" }}>
                    Cancel
                </Button>
                <Button onClick={handlePublish} data-testid="publish" id="publish" disabled={constraintFieldsDisabled}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}
