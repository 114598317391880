import React, { useEffect } from "react";
import Box from '@mui/material/Box';
import { useUserAuth } from '../contexts/authContext';
import { ConvertISOToDate } from '../util';
import { DataGrid } from '@mui/x-data-grid';
import { CustomPagination } from "../customPagination";
import { Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

export function DataLoggingStatus() {
    const [databaseSize, setDatabaseSize] = React.useState([]);
    const [sensorStatus, setSensorStatus] = React.useState([]);
    const { handleFailedFetch } = useUserAuth();
    const isMobile = useMediaQuery("(max-width:400px)");

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    const fetchDatabaseSize = async () => {
        await fetch('api/dl/status', requestOptions)
            .then(response => response.ok ? response.json() : Promise.reject(response))
            .then(data => setDatabaseSize(data))
            .catch(err => handleFailedFetch(err))
    };

    const fetchSensorStatus = async () => {
        await fetch('api/dl/sensors', requestOptions)
            .then(response => response.ok ? response.json() : Promise.reject(response))
            .then(data => setSensorStatus(data))
            .catch(err => handleFailedFetch(err))
    };

    useEffect(() => {
        fetchDatabaseSize();
        fetchSensorStatus();

        return () => {
            setDatabaseSize([]);
            setSensorStatus([]);
        }
    }, []);

    const handleRefresh = () => {
        fetchDatabaseSize();
        fetchSensorStatus();
    };

    const columns = [
        {
            field: 'sensor_name',
            headerName: 'Sensor Name',
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'sensor_id',
            headerName: 'Sensor ID',
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'message_time',
            headerName: 'Message Time',
            minWidth: 175,
            type: 'dateTime',
            flex: 1,
            valueFormatter: (params) => `${ConvertISOToDate(params.value)}`,
        },
        {
            field: 'message_id',
            headerName: 'Message ID',
            flex: 1,
            minWidth: 50,
        },
        {
            field: 'message_label',
            headerName: 'Message Label',
            minWidth: 50,
            flex: 1,
        }
    ];

    return (
        <>
            <Box sx={{ mb: 2, display: "flex", justifyContent: "flex-end", gap: "5px", alignItems: "center" }}>
                <Box sx={{ mr: "auto" }}>Current Database Size: {databaseSize.length ? databaseSize[0].pg_size_pretty : 0}</Box>
                <Button
                    sx={{ whiteSpace: "nowrap", flexShrink: 0 }}
                    variant="contained"
                    color="primary"
                    onClick={handleRefresh}
                    data-testid="refresh"
                    size={isMobile ? "small" : "medium"}
                >
                    Refresh
                </Button>
            </Box>
            <Box sx={{ height: 600 }}>
                <DataGrid
                    disableVirtualization
                    rows={sensorStatus}
                    columns={columns}
                    disableSelectionOnClick
                    getRowId={(row) => row.sensor_id}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    components={{ Pagination: CustomPagination }}
                />
            </Box>
        </>
    );
}