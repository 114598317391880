import ArticleIcon from "@mui/icons-material/Article";
import React, { Suspense, lazy, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import InfoIcon from "@mui/icons-material/Info";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";

import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useUserAuth } from "../../contexts/authContext";
import { useEnv } from "../../contexts/envContext";
import { useMap } from "../../contexts/mapContext";
import { useSocket } from "../../contexts/socketContext";
import { CustomPagination } from "../../customPagination";
import { MapPreview } from "../../map/mapPreview";
import { ConvertISOToDate, GetColorFromState } from "../../util";
import { getOperationEditable, getOperationPriority, getWaypointsDownloadable, handleDownloadOperations } from "./opUtil";

import ExportFlightWaypointsMenu from "./exportFlightWaypointsDialog";

import * as CONSTANT from "../operations/opConstants";
import { FlightInfoDialog } from "./flightInfoDialog";

const FlightDetailsDialog = lazy(() => import("./flightDetailsDialog"));

export function Operations() {
    const [showMap, setShowMap] = useState(false);
    const [operationColumns, setOperationColumns] = useState([]);
    const [infoFlight, setInfoFlight] = useState(null);
    const [flightInfoOpen, setFlightInfoOpen] = useState(false);

    const [operationsAreSelected, setOperationsAreSelected] = useState(false);
    const [selectedPublishedOperations, setSelectedPublishedOperations] = useState([]);

    const [flightDetailsVolume, setFlightDetailsVolume] = useState(null);
    const [flightDetailsDialogOpen, setFlightDetailsDialogOpen] = useState(false);

    const [downloadWaypointDialogOpen, setDownloadWaypointDialogOpen] = useState(false);
    const [downloadWaypointOperation, setDownloadWaypointOperation] = useState(null);

    const { airspaces } = useMap();
    const { planningOperations, publishedOperations, constraints, alertVolumes } = useSocket();
    const { laancOn } = useEnv();
    const { user, userMapSettings, getOrganizationByID, userOperationalStates, handleFailedFetch } = useUserAuth();

    const status = {
        accepted: {
            name: "ACCEPTED",
            confirmation: "Are you sure you want to Accept this operation?"
        },
        activated: {
            name: "ACTIVATED",
            confirmation: "Are you sure you want to Activate this operation?"
        },
        contingent: {
            name: "CONTINGENT",
            confirmation: "Are you sure you want to change the status of this operation to Contingent?"
        },
        nonconforming: {
            name: "NONCONFORMING",
            confirmation: "Are you sure you want to change the status of this operation to Nonconforming?"
        },
        ended: {
            name: "ENDED",
            confirmation: "Are you sure you want to Cancel this operation?"
        }
    };

    const initialColumns = [
        {
            field: "info",
            headerName: "",
            minWidth: 50,
            maxWidth: 50,
            flex: 1,
            renderCell: (cellValues) => {
                return (
                    <IconButton size="small" onClick={(event) => handleInfoClick(event, cellValues)} id={`info-${cellValues.row.volumeId}`}>
                        <InfoIcon />
                    </IconButton>
                );
            }
        },
        {
            field: "name",
            headerName: "Name",
            minWidth: 175,
            flex: 1,
            editable: false
        },
        {
            field: "state",
            headerName: "Status",
            minWidth: 190,
            flex: 1,
            type: "singleSelect",
            editable: true,
            valueOptions: Object.values(status).map((status) => status.name),
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            fontSize: 14,
                            fontWeight: "bold",
                            color: GetColorFromState(cellValues.row.state, userOperationalStates)
                        }}
                    >
                        {cellValues.row.state}
                    </div>
                );
            }
        },
        {
            field: "time_start",
            headerName: "Start",
            type: "date",
            minWidth: 200,
            flex: 1,
            editable: false,
            valueGetter: (params) => `${ConvertISOToDate(params.row.time_start)}`
        },
        {
            field: "time_end",
            headerName: "End",
            minWidth: 200,
            flex: 1,
            valueGetter: (params) => `${ConvertISOToDate(params.row.time_end)}`
        },
        {
            field: "organization_id",
            headerName: "Org",
            editable: false,
            minWidth: 75,
            maxWidth: 75,
            flex: 1,
            renderCell: (cellValues) => {
                const org = getOrganizationByID(cellValues.formattedValue);
                if (org != undefined) {
                    return <div>{org.name}</div>;
                }
            }
        },
        {
            field: "priority",
            headerName: "Priority",
            flex: 1,
            editable: false,
            minWidth: 100,
            valueGetter: ({ row }) => `${getOperationPriority(row)}`
        },
        {
            field: "version",
            headerName: "Ver",
            minWidth: 40,
            maxWidth: 40,
            flex: 1,
            editable: false,
            valueGetter: ({ row }) => `${row.version}`
        },
        {
            field: "conflicted",
            headerName: "Conflicted",
            align: "center",
            minWidth: 80,
            maxWidth: 80,
            flex: 1,
            editable: false,
            renderCell: (cellValues) => {
                if (cellValues.formattedValue) {
                    return <div style={{ fontWeight: "bold", color: "#CB4C4E" }}>Yes</div>;
                } else {
                    return <div>No</div>;
                }
            }
        }
    ];

    const laancColumns = [
        {
            field: "laanc_state",
            headerName: "LAANC",
            minWidth: 210,
            flex: 1,
            editable: false,
            renderCell: (cellValues) => {
                const isOwnOp = user.id === cellValues.row.created_user_id ? true : false;
                if (Object.prototype.hasOwnProperty.call(cellValues.row, "laanc")) {
                    return (
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                        >
                            <Box>{cellValues.row.laanc.state.replace(/_/g, " ")}</Box>
                            {isOwnOp && cellValues.row.faa_approval_required === true ? (
                                cellValues.row.laanc.state === CONSTANT.LAANC_RESCINDED_AWAITING ||
                                cellValues.row.laanc.state === CONSTANT.LAANC_INVALID_AWAITING ? (
                                    <IconButton size="small" onClick={() => handleDetailsButtonClicked(cellValues.row)} id={`laanc-${cellValues.row.volumeId}`}>
                                        <Badge badgeContent={"!"} color={"error"}>
                                            <ArticleIcon />
                                        </Badge>
                                    </IconButton>
                                ) : (
                                    <IconButton size="small" onClick={() => handleDetailsButtonClicked(cellValues.row)} id={`laanc-${cellValues.row.volumeId}`}>
                                        <ArticleIcon />
                                    </IconButton>
                                )
                            ) : (
                                <></>
                            )}
                        </Box>
                    );
                } else return <div>NOT REQUIRED</div>;
            }
        }
    ];

    const actionsColumn = [
        {
            type: "actions",
            field: "actions",
            minWidth: 125,
            headerName: "Actions",
            cellClassName: "actions",
            getActions: ({ row }) => {
                const canDelete = getOperationEditable(row, user);
                const canDownloadWaypoints = getWaypointsDownloadable(row, user);
                return [
                    canDownloadWaypoints === true ? (
                        <GridActionsCellItem
                            key={row.id}
                            icon={<DownloadIcon />}
                            label="Download"
                            onClick={() => {
                                setDownloadWaypointDialogOpen(true);
                                setDownloadWaypointOperation(row);
                            }}
                        />
                    ) : (
                        <></>
                    ),
                    canDelete === true ? (
                        <GridActionsCellItem
                            key={row.id}
                            icon={<DeleteIcon />}
                            label="Delete"
                            id={`cancel-${row.volumeId}`}
                            onClick={() => {
                                handleDeleteClick(row);
                            }}
                        />
                    ) : (
                        <></>
                    )
                ];
            }
        }
    ];

    useEffect(() => {
        if (operationsAreSelected === false) {
            setSelectedPublishedOperations(publishedOperations);
        }
    }, [publishedOperations, selectedPublishedOperations, operationsAreSelected]);

    //Sets up the columns for the Operation Grid based on Login
    useEffect(() => {
        const columns = initialColumns;
        const timeEndColumn = columns.findIndex((column) => column.field === "time_end");
        if (laancOn === "true" && timeEndColumn !== -1) {
            columns.splice(timeEndColumn + 1, 0, ...laancColumns);
        }
        columns.push(...actionsColumn);
        setOperationColumns(columns);
    }, []);

    const handleStatusChange = (data, event) => {
        const row = publishedOperations.find(({ flight_uuid }) => {
            return flight_uuid == data.id;
        });
        const statusName = data.value;
        if (statusName === status.ended.name) {
            handleCancelClick(row, event);
            return;
        }
        if (!confirm(status[statusName.toLowerCase()].confirmation)) {
            event.defaultMuiPrevented = true;
            return;
        }
        row.state = statusName;
        const operation = row;

        operation.state = statusName;
        operation.version = parseInt(operation.version) + 1;
        operation.updated_user_id = user.id;

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(operation)
        };

        fetch("/api/op/updatePublish", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .catch((err) => handleFailedFetch(err));
    };

    const handleCancelClick = (row, event) => {
        if (!confirm(status.ended.confirmation)) {
            if (event) {
                event.defaultMuiPrevented = true;
            }
            return;
        }
        row.state = status.ended.name;
        let operation = row;
        operation.updated_user_id = user.id;

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(operation)
        };

        fetch("/api/op/delete", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .catch((err) => handleFailedFetch(err));
    };

    const handleDeleteClick = (operation) => {
        if (confirm("Are you sure you want to delete this operation?") === true) {
            const updated_operation = JSON.stringify({
                ...operation,
                state: "DELETED",
                updated_user_id: user.id
            });
            const requestOptions = {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: updated_operation
            };
            fetch("/api/op/delete", requestOptions)
                .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                .catch((err) => handleFailedFetch(err));
        }
    };

    const handleInfoClick = (event, cellValues) => {
        event.stopPropagation();
        setInfoFlight(cellValues.row);
        setFlightInfoOpen(true);
    };

    const handleDetailsButtonClicked = (operation) => {
        setFlightDetailsDialogOpen(true);
        setFlightDetailsVolume(operation);
    };

    const handleCheckboxSelected = (ids) => {
        if (ids.length > 0 && showMap === false) {
            setShowMap(true);
        }
        const selected = publishedOperations.filter(({ flight_uuid }) => {
            return ids.includes(flight_uuid);
        });
        if (selected.length > 0) {
            setOperationsAreSelected(true);
            setSelectedPublishedOperations(selected);
        } else {
            setOperationsAreSelected(false);
            setSelectedPublishedOperations(publishedOperations);
        }
    };

    const handleCheckCellEditable = ({ row }) => {
        return getOperationEditable(row, user);
    };

    return (
        <>
            <Box sx={{ width: 1, height: 578 }}>
                <DataGrid
                    pageSize={9}
                    checkboxSelection
                    rows={publishedOperations}
                    onCellEditCommit={handleStatusChange}
                    isCellEditable={handleCheckCellEditable}
                    disableVirtualization
                    disableSelectionOnClick
                    rowsPerPageOptions={[6]}
                    columns={operationColumns}
                    getRowId={(row) => row.flight_uuid}
                    components={{ Pagination: CustomPagination }}
                    onSelectionModelChange={handleCheckboxSelected}
                />
            </Box>
            <Box sx={{ display: "flex", my: 2, gap: 1 }}>
                <Button onClick={() => setShowMap((prev) => !prev)} variant="contained" color="primary" size="small">
                    {showMap ? "Hide Map" : "Preview on Map"}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    id="download"
                    onClick={() => handleDownloadOperations(selectedPublishedOperations, userMapSettings)}
                    size="small"
                >
                    Download KML
                </Button>
            </Box>

            {showMap ? (
                <Paper sx={{ pb: 2, height: "600px" }}>
                    <MapPreview
                        id={1}
                        planningOperations={planningOperations}
                        publishedOperations={selectedPublishedOperations}
                        airspaces={airspaces}
                        constraints={constraints}
                        alertVolumes={alertVolumes}
                    />
                </Paper>
            ) : (
                <></>
            )}

            {flightInfoOpen ? <FlightInfoDialog flight={infoFlight} flightInfoOpen={flightInfoOpen} setFlightInfoOpen={setFlightInfoOpen} /> : <></>}
            {flightDetailsDialogOpen ? (
                <Suspense fallback={<></>}>
                    <FlightDetailsDialog
                        flightDetailsVolume={flightDetailsVolume}
                        flightDetailsDialogOpen={flightDetailsDialogOpen}
                        publishedOperations={publishedOperations}
                        setFlightDetailsVolume={setFlightDetailsVolume}
                        setFlightDetailsDialogOpen={setFlightDetailsDialogOpen}
                    />
                </Suspense>
            ) : (
                <></>
            )}
            {downloadWaypointDialogOpen === true ? (
                <ExportFlightWaypointsMenu
                    downloadWaypointDialogOpen={downloadWaypointDialogOpen}
                    setDownloadWaypointDialogOpen={setDownloadWaypointDialogOpen}
                    downloadWaypointOperation={downloadWaypointOperation}
                    setDownloadWaypointOperation={setDownloadWaypointOperation}
                />
            ) : (
                <></>
            )}
        </>
    );
}
