import { useState, useEffect } from "react";
import { ImageryLayer, WebMapServiceImageryProvider } from "cesium";
import { useUserAuth } from "../contexts/authContext";

// The following is a hook which can be used to add and remove a particular imagery layer to a cesium viewer.
// You can declare it similarly to a normal state variable and toggle its visibility with the second variable returned.

// For example you can initilize an imagery layer with `const [layer, setLayer] = useImageryLayer(viewer, type, visibility);`.
// The viewer is equal to the cesium viewer you wish to add.
// The type is the id which maps to the particular imagery layer type (see the `types` array below).
// And the visibility is the initial visibility of the layer.

const types = [
    {
        id: 0,
        name: "Radar",
        source: "https://mapservices.weather.noaa.gov:443/eventdriven/services/radar/radar_base_reflectivity_time/ImageServer/WMSServer"
    }
];
const useImageryLayer = (viewer, type, visibility) => {
    const [visible, setVisible] = useState(visibility);
    const [layer, setLayer] = useState(null);

    const { userMapSettings } = useUserAuth();

    useEffect(() => {
        if (viewer !== null) {
            if (visible === true) {
                if (layer !== null) {
                    layer.alpha = userMapSettings.weather_opacity;
                } else {
                    const { source } = types.find(({ id }) => {
                        return id === type;
                    });
                    const imageryProvider = new WebMapServiceImageryProvider({
                        url: source,
                        layers: "0",
                        parameters: {
                            transparent: "true",
                            format: "image/png"
                        }
                    });
                    const imageryLayer = new ImageryLayer(imageryProvider, {
                        alpha: userMapSettings.weather_opacity
                    });
                    viewer.imageryLayers.add(imageryLayer);
                    setLayer(imageryLayer);
                }
            } else if (layer !== null) {
                viewer.imageryLayers.remove(layer);
                setLayer(null);
            }
        }
    }, [viewer, visible, userMapSettings]);

    return [visible, setVisible];
};

export default useImageryLayer;
