import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Accordion from "@mui/material/Accordion";
import FormControlLabel from "@mui/material/FormControlLabel";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Slider from "@mui/material/Slider";

import { useUserAuth } from "../contexts/authContext";
import { getCheckedFromSubLayers, getIndeterminateFromSubLayers, getSourceName } from "./mapUtil";
import { Typography } from "@mui/material";
import { useMap } from "../contexts/mapContext";

const marks = [
    {
        value: 0,
        label: "0ft"
    },
    {
        value: 4500,
        label: "4500ft"
    },
    {
        value: 9000,
        label: "9000ft"
    },
    {
        value: 13500,
        label: "13500ft"
    },
    {
        value: 18000,
        label: "18000ft"
    }
];

export const MapLayersDropdown = (props) => {
    const [sources, setSources] = useState([]);
    const [airspaceSubLayers, setAirspaceSubLayers] = useState([]);

    useEffect(() => {
        const allAirspaces = [
            props.airspacesClassA,
            props.airspacesClassB,
            props.airspacesClassC,
            props.airspacesClassD,
            props.airspacesClassE2,
            props.airspacesClassE3,
            props.airspacesClassE4,
            props.airspacesClassE5,
            props.airspacesModeC
        ];
        const populated = allAirspaces.every((airspace) => {
            return airspace !== null;
        });
        if (populated) {
            const subLayers = [
                { type: "CLASS_A", checked: props.airspacesClassA.show, element: props.airspacesClassA },
                { type: "CLASS_B", checked: props.airspacesClassB.show, element: props.airspacesClassB },
                { type: "CLASS_C", checked: props.airspacesClassC.show, element: props.airspacesClassC },
                { type: "CLASS_D", checked: props.airspacesClassD.show, element: props.airspacesClassD },
                { type: "CLASS_E2", checked: props.airspacesClassE2.show, element: props.airspacesClassE2 },
                { type: "CLASS_E3", checked: props.airspacesClassE3.show, element: props.airspacesClassE3 },
                { type: "CLASS_E4", checked: props.airspacesClassE4.show, element: props.airspacesClassE4 },
                { type: "CLASS_E5", checked: props.airspacesClassE5.show, element: props.airspacesClassE5 },
                { type: "MODE C", checked: props.airspacesModeC.show, element: props.airspacesModeC }
            ];
            setAirspaceSubLayers(subLayers);
        }
    }, [
        props.airspacesClassA,
        props.airspacesClassB,
        props.airspacesClassC,
        props.airspacesClassD,
        props.airspacesClassE2,
        props.airspacesClassE3,
        props.airspacesClassE4,
        props.airspacesClassE5,
        props.airspacesModeC
    ]);

    useEffect(() => {
        const sources = [];
        for (const sourceID of props.sourceIDs) {
            let identifier = getSourceName(sourceID, sourceData);
            let visibility = true;

            const source = userMapSettings.source_mapping[sourceID];
            if (source && source.identifier) {
                identifier = source.identifier;
            }
            if (source && source.visibility !== undefined) {
                visibility = source.visibility;
            }
            if (!sources.find((s) => s.identifier.toLowerCase() === identifier.toLowerCase())) {
                sources.push({ identifier: identifier, visibility: visibility });
            }
        }
        setSources(sources);
    }, [props.sourceIDs]);

    const { user, userMapSettings } = useUserAuth();
    const { sourceData } = useMap();

    const toggleAllAirspaces = (e) => {
        const checked = e.target.checked;
        const updatedLayers = airspaceSubLayers.map((subLayer) => {
            subLayer.element.show = checked;
            return {
                ...subLayer,
                checked: checked
            };
        });
        setAirspaceSubLayers(updatedLayers);
    };
    const toggleSubAirspaces = (e, type) => {
        const checked = e.target.checked;
        const updatedLayers = airspaceSubLayers.map((subLayer) => {
            if (subLayer.type === type) {
                subLayer.element.show = checked;
                return {
                    ...subLayer,
                    checked: checked
                };
            } else {
                return subLayer;
            }
        });
        setAirspaceSubLayers(updatedLayers);
    };
    return (
        <>
            <div className="cesium-baseLayerPicker-sectionTitle">Layers</div>

            <div className="cesium-baseLayerPicker-section">
                <div className="cesium-baseLayerPicker-categoryTitle" data-bind="text: name" style={{ padding: "5px 0" }}>
                    Altitude
                </div>
                <Slider
                    value={props.altitudeRange}
                    onChange={props.handleToggleAltitude}
                    valueLabelDisplay="auto"
                    sx={{ m: "10 auto 30px auto", width: "86%", display: "flex" }}
                    marks={marks}
                    max={18000}
                    step={250}
                    min={0}
                />
            </div>
            <div className="cesium-baseLayerPicker-section">
                <div className="cesium-baseLayerPicker-categoryTitle" data-bind="text: name" style={{ padding: "5px 0" }}>
                    General
                </div>
                <Accordion sx={{ width: "100%", display: "grid", background: "transparent", boxShadow: "none" }} disableGutters>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <FormControlLabel
                            label="Surveillance Sources"
                            control={
                                <Checkbox
                                    inputProps={{ "data-testid": "sources" }}
                                    onClick={(e) => props.handleToggleSources(e)}
                                    checked={props.sourceToggle}
                                    sx={{ p: "6px" }}
                                />
                            }
                        />
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                        <Box sx={{ display: "flex", flexDirection: "column", ml: 4 }}>
                            {sources
                                .filter((s) => s.visibility)
                                .map(({ identifier }, i) => (
                                    <Typography key={i} sx={{ p: "6px" }} noWrap>
                                        {identifier}
                                    </Typography>
                                ))}
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{ width: "100%", display: "grid", background: "transparent", boxShadow: "none" }} disableGutters expanded={false}>
                    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                        <FormControlLabel
                            label="Weather Radar"
                            control={
                                <Checkbox
                                    inputProps={{ "data-testid": "weather" }}
                                    onClick={(e) => props.setWeatherRadarLayer(e.target.checked)}
                                    checked={props.weatherRadarLayer}
                                    sx={{ p: "6px" }}
                                />
                            }
                        />
                    </AccordionSummary>
                </Accordion>
            </div>

            <div className="cesium-baseLayerPicker-section">
                <div className="cesium-baseLayerPicker-categoryTitle" data-bind="text: name" style={{ padding: "5px 0" }}>
                    Volumes
                </div>
                <Accordion
                    sx={{ width: "100%", display: user.user_role_id === 5 ? "none" : "grid", background: "transparent", boxShadow: "none" }}
                    disableGutters
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <FormControlLabel
                            label="Airspaces"
                            control={
                                <Checkbox
                                    inputProps={{ "data-testid": "airspaces" }}
                                    checked={getCheckedFromSubLayers(airspaceSubLayers)}
                                    indeterminate={getIndeterminateFromSubLayers(airspaceSubLayers)}
                                    sx={{ p: "6px" }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        toggleAllAirspaces(e);
                                    }}
                                />
                            }
                        />
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                        <Box sx={{ display: "flex", flexDirection: "column", ml: 4 }}>
                            {airspaceSubLayers.map(({ type, checked }, i) => (
                                <FormControlLabel
                                    key={i}
                                    label={type}
                                    control={<Checkbox checked={checked} sx={{ p: "6px" }} onClick={(e) => toggleSubAirspaces(e, type)} />}
                                />
                            ))}
                        </Box>
                    </AccordionDetails>
                </Accordion>

                <Accordion sx={{ width: "100%", display: "grid", background: "transparent", boxShadow: "none" }} disableGutters expanded={false}>
                    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                        <FormControlLabel
                            label="Operations"
                            control={
                                <Checkbox
                                    inputProps={{ "data-testid": "operations" }}
                                    onClick={(e) => props.handleToggleOperations(e)}
                                    checked={props.showOperations}
                                    sx={{ p: "6px" }}
                                />
                            }
                        />
                    </AccordionSummary>
                </Accordion>
                <Accordion sx={{ width: "100%", display: "grid", background: "transparent", boxShadow: "none" }} disableGutters expanded={false}>
                    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                        <FormControlLabel
                            label="Constraints"
                            control={
                                <Checkbox
                                    onClick={(e) => props.handleToggleConstraints(e)}
                                    inputProps={{ "data-testid": "constraints" }}
                                    checked={props.showConstraints}
                                    sx={{ p: "6px" }}
                                />
                            }
                        />
                    </AccordionSummary>
                </Accordion>
                <Accordion sx={{ width: "100%", display: "grid", background: "transparent", boxShadow: "none" }} disableGutters expanded={false}>
                    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                        <FormControlLabel
                            label="Alert Volumes"
                            control={<Checkbox onClick={(e) => props.handleToggleAlerts(e)} checked={props.showAlerts} sx={{ p: "6px" }} />}
                        />
                    </AccordionSummary>
                </Accordion>
                <Accordion
                    sx={{ width: "100%", display: user.user_role_id === 5 ? "none" : "grid", background: "transparent", boxShadow: "none" }}
                    disableGutters
                    expanded={false}
                >
                    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                        <FormControlLabel
                            label="Radar Footprint"
                            control={<Checkbox onClick={(e) => props.handleToggleSensors(e)} checked={props.showSensors} sx={{ p: "6px" }} />}
                        />
                    </AccordionSummary>
                </Accordion>
            </div>
        </>
    );
};
