import { Color, ColorGeometryInstanceAttribute } from "cesium";
import React, { createContext, useContext, useEffect, useState } from "react";
import {
    GetFacilityMapLabelCollection,
    GetFacilityMapPrimitive,
    GetPrimitiveFromData,
    GetStadiumLabelCollection,
    getAirspaceHexColorFromType,
    getAirspaceVisibilityFromType
} from "../map/mapUtil";

import { useUserAuth } from "./authContext";
import { useMediaQuery } from "@mui/material";

export const MapContext = createContext();

export function useMap() {
    return useContext(MapContext);
}

export function MapProvider({ children }) {
    const [colors, setColors] = useState();
    const [drones, setDrones] = useState([]);
    const [visibleDrones, setVisibleDrones] = useState([]);
    const [sensors, setSensors] = useState([]);
    const [sourceData, setSourceData] = useState(new Map());
    const [uniqueSources, setUniqueSources] = useState([]);

    const [airspaces, setAirspaces] = useState([]);
    const [airspacePrimitiveClassA, setAirspacePrimitiveClassA] = useState(null);
    const [airspacePrimitiveClassB, setAirspacePrimitiveClassB] = useState(null);
    const [airspacePrimitiveClassC, setAirspacePrimitiveClassC] = useState(null);
    const [airspacePrimitiveClassD, setAirspacePrimitiveClassD] = useState(null);
    const [airspacePrimitiveClassE2, setAirspacePrimitiveClassE2] = useState(null);
    const [airspacePrimitiveClassE3, setAirspacePrimitiveClassE3] = useState(null);
    const [airspacePrimitiveClassE4, setAirspacePrimitiveClassE4] = useState(null);
    const [airspacePrimitiveClassE5, setAirspacePrimitiveClassE5] = useState(null);
    const [airspacePrimitiveModeC, setAirspacePrimitiveModeC] = useState(null);

    const [facilityMapPrimitive, setFacilityMapPrimitive] = useState(null);
    const [facilityMapLabelCollection, setFacilityMapLabelCollection] = useState(null);

    const [stadiumLabelCollection, setStadiumLabelCollection] = useState(null);
    const [stadiumsPrimitive, setStadiumsPrimitive] = useState(null);

    const [nationalSecurityNoFlyPrimitive, setNationalSecurityNoFlyPrimitive] = useState(null);
    const [prohibitedAirspacePrimitive, setProhibitedAirspacePrimitive] = useState(null);
    const [restrictedAirspacePrimitive, setRestrictedAirspacePrimitive] = useState(null);

    const [militaryOperationAreasPrimitive, setMilitaryOperationAreasPrimitive] = useState(null);
    const [warningAreasPrimitive, setWarningAreasPrimitive] = useState(null);
    const [alertAreasPrimitive, setAlertAreasPrimitive] = useState(null);
    const [tfrAreasPrimitive, setTFRAreasPrimitive] = useState(null);

    const { handleFailedFetch, user } = useUserAuth();
    const isDesktop = useMediaQuery("(min-width:900px)", { noSsr: true });

    useEffect(() => {
        const sensorMap = new Map();
        sensors.forEach((sensor) => {
            sensorMap.set(sensor.truth_source, sensor.name);
        });
        setSourceData(sensorMap);
    }, [sensors]);

    const updateAirspacePrimitives = (updatedSettings) => {
        const airspaceTypes = [
            { type: "CLASS_A", data: airspacePrimitiveClassA, func: setAirspacePrimitiveClassA },
            { type: "CLASS_B", data: airspacePrimitiveClassB, func: setAirspacePrimitiveClassB },
            { type: "CLASS_C", data: airspacePrimitiveClassC, func: setAirspacePrimitiveClassC },
            { type: "CLASS_D", data: airspacePrimitiveClassD, func: setAirspacePrimitiveClassD },
            { type: "CLASS_E2", data: airspacePrimitiveClassE2, func: setAirspacePrimitiveClassE2 },
            { type: "CLASS_E3", data: airspacePrimitiveClassE3, func: setAirspacePrimitiveClassE3 },
            { type: "CLASS_E4", data: airspacePrimitiveClassE4, func: setAirspacePrimitiveClassE4 },
            { type: "CLASS_E5", data: airspacePrimitiveClassE5, func: setAirspacePrimitiveClassE5 },
            { type: "MODE C", data: airspacePrimitiveModeC, func: setAirspacePrimitiveModeC }
        ];
        airspaceTypes.forEach(({ type, data, func }) => {
            if (data === null) {
                return;
            }
            const visibility = getAirspaceVisibilityFromType(type, updatedSettings);
            const hex = getAirspaceHexColorFromType(type, updatedSettings);
            const updated = {
                ...data,
                show: visibility,
                geometryInstances: data.geometryInstances.map((instance) => {
                    return {
                        ...instance,
                        attributes: {
                            color: ColorGeometryInstanceAttribute.fromColor(Color.fromCssColorString(hex))
                        }
                    };
                })
            };
            func(updated);
        });
    };
    const fetchMapData = (settings) => {
        if (isDesktop === true) {
            const lat_deg = settings.latitude;
            const lon_deg = settings.longitude;
            const size_deg = 4;
            const half = size_deg / 2;

            const polygon = [
                [lon_deg - half, lat_deg + half],
                [lon_deg + half, lat_deg + half],
                [lon_deg + half, lat_deg - half],
                [lon_deg - half, lat_deg - half],
                [lon_deg - half, lat_deg + half]
            ];
            const airspaceTypes = [
                { type: "CLASS_A", func: setAirspacePrimitiveClassA },
                { type: "CLASS_B", func: setAirspacePrimitiveClassB },
                { type: "CLASS_C", func: setAirspacePrimitiveClassC },
                { type: "CLASS_D", func: setAirspacePrimitiveClassD },
                { type: "CLASS_E2", func: setAirspacePrimitiveClassE2 },
                { type: "CLASS_E3", func: setAirspacePrimitiveClassE3 },
                { type: "CLASS_E4", func: setAirspacePrimitiveClassE4 },
                { type: "CLASS_E5", func: setAirspacePrimitiveClassE5 },
                { type: "MODE C", func: setAirspacePrimitiveModeC }
            ];
            setAirspaces([]);
            airspaceTypes.forEach(({ type, func }) => {
                fetchAirspacesByPolygonAndType(polygon, type, settings, func);
            });

            fetchFacilityMapsByPolygon(polygon);
            fetchStadiums();
            fetchNationalSecurityNoFly();

            const special_use_airspace_types = [
                { type: "P", color: "#f2b463", func: setProhibitedAirspacePrimitive },
                { type: "R", color: "#f5f77c", func: setRestrictedAirspacePrimitive },
                { type: "MOA", color: "#f59542", func: setMilitaryOperationAreasPrimitive },
                { type: "W", color: "#34ebde", func: setWarningAreasPrimitive },
                { type: "A", color: "#e942f5", func: setAlertAreasPrimitive }
            ];
            special_use_airspace_types.forEach(({ type, color, func }) => {
                fetchSpecialUseAirspace(type, color, func);
            });
            fetchTFRs();
        }
        fetchSensors();
        fetchColors();
        getDrones();
        getUniqueSources();
    };
    const fetchAirspacesByPolygonAndType = async (polygon, type, settings, setFunction) => {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ polygon: polygon })
        };
        await fetch(`api/laanc_airspaces/getbypolygon/${type}`, requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => {
                const visibility = getAirspaceVisibilityFromType(type, settings.airspace_settings);
                const hex = getAirspaceHexColorFromType(type, settings.airspace_settings);
                const primitive = GetPrimitiveFromData(data, visibility, hex);

                setAirspaces((prev) => {
                    return [...prev, ...data];
                });
                setFunction(primitive);
            })
            .catch((err) => handleFailedFetch(err));
    };
    const fetchFacilityMapsByPolygon = async (polygon) => {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ polygon: polygon })
        };
        await fetch("api/laanc_facility_map/get", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => {
                const primitive = GetFacilityMapPrimitive(data, true);
                setFacilityMapPrimitive(primitive);
                const labels = GetFacilityMapLabelCollection(data);
                setFacilityMapLabelCollection(labels);
            })
            .catch((err) => handleFailedFetch(err));
    };
    const fetchSensors = async () => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        await fetch("api/sensors/get", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => setSensors(data))
            .catch((err) => handleFailedFetch(err));
    };
    const fetchColors = async () => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        await fetch("api/misc/getColors", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => setColors(data))
            .catch((err) => handleFailedFetch(err));
    };
    const fetchStadiums = async () => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        await fetch("api/laanc_stadiums/get", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => {
                const primitive = GetPrimitiveFromData(data, true, "#00FF00");
                setStadiumsPrimitive(primitive);
                const labels = GetStadiumLabelCollection(data);
                setStadiumLabelCollection(labels);
            })
            .catch((err) => handleFailedFetch(err));
    };
    const fetchNationalSecurityNoFly = async () => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        await fetch("api/laanc_ft_ns_uas_restrictions/getAll", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => {
                const primitive = GetPrimitiveFromData(data, true, "#ff355e");
                setNationalSecurityNoFlyPrimitive(primitive);
            })
            .catch((err) => handleFailedFetch(err));
    };
    const fetchSpecialUseAirspace = async (type, color, setFunction) => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        // nosemgrep
        await fetch(`api/laanc_special_use_airspace/get/${type}`, requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => {
                const primitive = GetPrimitiveFromData(data, true, color);
                setFunction(primitive);
            })
            .catch((err) => handleFailedFetch(err));
    };
    const getDrones = async () => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        await fetch("/api/drone/getAll", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => {
                setDrones(data);
                const visibleDrones = data.filter((drone) => {
                    if (user.user_role_id === 0) {
                        return true;
                    } else {
                        return drone.organization_id === user.organization_id;
                    }
                });
                setVisibleDrones(visibleDrones);
            })
            .catch((err) => handleFailedFetch(err));

        return drones;
    };
    const fetchTFRs = async () => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        await fetch("api/laanc_tfr/getAll", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => {
                const primitive = GetPrimitiveFromData(data, true, "#ffffff");
                setTFRAreasPrimitive(primitive);
            })
            .catch((err) => handleFailedFetch(err));
    };
    const getUniqueSources = async () => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        await fetch("/api/asd/getUnique", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => setUniqueSources(data))
            .catch((err) => handleFailedFetch(err));
    };
    const value = {
        airspaces,
        airspacePrimitiveClassA,
        airspacePrimitiveClassB,
        airspacePrimitiveClassC,
        airspacePrimitiveClassD,
        airspacePrimitiveClassE2,
        airspacePrimitiveClassE3,
        airspacePrimitiveClassE4,
        airspacePrimitiveClassE5,
        airspacePrimitiveModeC,
        sourceData,
        stadiumLabelCollection,
        stadiumsPrimitive,
        drones,
        visibleDrones,
        colors,
        sensors,
        nationalSecurityNoFlyPrimitive,
        prohibitedAirspacePrimitive,
        restrictedAirspacePrimitive,
        facilityMapPrimitive,
        facilityMapLabelCollection,
        fetchSensors,
        getDrones,
        fetchMapData,
        updateAirspacePrimitives,
        militaryOperationAreasPrimitive,
        warningAreasPrimitive,
        alertAreasPrimitive,
        tfrAreasPrimitive,
        uniqueSources,
        getUniqueSources
    };

    return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
}
