//Unit testing setup for MapDraw
export const testingVolumes = [
    {
        id: 0,
        type: "polygon",
        vertices: [
            { lat: 0, lng: 0 },
            { lat: 1, lng: 1 },
            { lat: 2, lng: 2 },
            { lat: 3, lng: 3 }
        ]
    },
    {
        id: 1,
        type: "circle",
        vertices: [{ lat: 0, lng: 0 }]
    }
];
