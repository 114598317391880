import React, { Suspense, lazy, useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import Drawer from "@mui/material/Drawer";
import Fab from "@mui/material/Fab";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@mui/material/useMediaQuery";

import AddIcon from "@mui/icons-material/Add";
import LayersIcon from "@mui/icons-material/Layers";
import ModeIcon from "@mui/icons-material/Mode";
import SettingsIcon from "@mui/icons-material/Settings";
import StyleIcon from "@mui/icons-material/Style";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PublishIcon from "@mui/icons-material/Publish";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SellIcon from "@mui/icons-material/Sell";

// cesium
import {
    CallbackProperty,
    Cartesian3,
    Cartographic,
    Color,
    HeightReference,
    Math,
    Matrix4,
    PolygonHierarchy,
    SceneMode,
    ScreenSpaceEventHandler,
    ScreenSpaceEventType,
    createWorldTerrainAsync,
    defined,
    subscribeAndEvaluate
} from "cesium";
import { Viewer } from "resium";

// components
import { ConversationDialog } from "../chat/conversationDialog";
import { ConstraintCircleCreateDialog } from "../manager/constraints/constraintCircleCreateDialog";
import { ConstraintDraw } from "../manager/constraints/constraintDraw";
import { ConstraintEdit } from "../manager/constraints/constraintEdit";
import { EditFlightDialog } from "../manager/operations/editFlightDialog";
import { MapContextMenu } from "./mapContextMenu";
import { MapFlightCards } from "./mapFlightCards";
import { MapLayersDropdown } from "./mapLayersDropdown";
import { MapSettingsDropdown } from "./mapSettingsDropdown";
import { EntityEditDialog } from "./entityEditDialog";
import { EntityWatchToolbar } from "./entityWatchToolbar";

// hooks
import { useUserAuth } from "../contexts/authContext";
import { useEnv } from "../contexts/envContext";
import { useMap } from "../contexts/mapContext";
import { useSocket } from "../contexts/socketContext";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { usePrimitive } from "../hooks/usePrimitive";

// util
import { GetVerticalSpeedIndicator } from "../util";
import { getRingModel } from "./mapModels";
import {
    createAlertConflictRing,
    createLabelEntity,
    createSensorEntity,
    createUpdateAirEntities,
    createUpdateZeroConflictDesc,
    createVolumeEntity,
    createZeroConflictLine,
    createZeroConflictRing,
    entityIsVisible,
    formatAlertVolumeToDraw,
    formatConstraintToDraw,
    formatOperationToDraw,
    getPolygonCenter,
    handleToggleVisibilityOfAlertRingEntities,
    handleUpdateAirEntitiesWithNewSettings,
    handleUpdateVolumeEntitiesWithNewSettings,
    isAnASDEntity,
    pickPosition,
    updateZeroConflictLine,
    updateZeroConflictRing,
    zoomToEntities
} from "./mapUtil";
import { StartTimeHasAlreadyPassed } from "../util";

import alertSound from "../../public/static/files/zero_conflict_alert.mp3";
import useImageryLayer from "../hooks/useImageryLayer";

const FlightDetailsDialog = lazy(() => import("../manager/operations/flightDetailsDialog"));

// const variables
const ENTITY_TIMEOUT_SECONDS = 10;
const FLIGHT_CARD_WIDTH_PX = 400;

export const MapComponent = (props) => {
    const { socket, publishedOperations, constraints, alertVolumes } = useSocket();
    const { airspacePrimitiveClassA, airspacePrimitiveClassB, airspacePrimitiveClassC, airspacePrimitiveClassD, airspacePrimitiveModeC } = useMap();
    const { airspacePrimitiveClassE2, airspacePrimitiveClassE3, airspacePrimitiveClassE4, airspacePrimitiveClassE5 } = useMap();
    const { user, updateMapSettings, userOperationalStates, userMapSettings, snackbar, setSnackbar, handleFailedFetch } = useUserAuth();
    const { sensors, colors, sourceData } = useMap();
    const { providerViewModels } = useEnv();

    const providerViewModelsRef = useRef(providerViewModels);

    // misc variables
    const [component, setComponent] = useState(null);
    const [viewerState, setViewerState] = useState(null);
    const [terrainProvider, setTerrainProvider] = useState(undefined);

    const settings = useRef(userMapSettings);
    const volumeUuidToZoomTo = useRef("");
    const location = useLocation();
    const navigate = useNavigate();

    // element ref variables
    const docRef = useRef(null);
    const viewerRef = useRef(null);
    const layersButtonRef = useRef(null);
    const createButtonRef = useRef(null);
    const settingsButtonRef = useRef(null);
    const flightCardsButtonRef = useRef(null);
    const currentLocationRef = useRef(null);

    const selectedEntityActionsRef = useRef(null);
    const watchButtonRef = useRef(null);
    const publishButtonRef = useRef(null);
    const editButtonRef = useRef(null);
    const deleteButtonRef = useRef(null);
    const tagButtonRef = useRef(null);

    const [mapSettingsOpen, setMapSettingsOpen] = useState(false);
    const [mapLayersOpen, setMapLayersOpen] = useState(false);

    // volume entities ref variables
    const operationEntities = useRef([]);
    const constraintEntities = useRef([]);
    const alertVolumeEntities = useRef([]);
    const sensorEntities = useRef([]);

    // asd entities ref variables
    const asdEntities = useRef(new Map());
    const sourceIDs = useRef(new Set());

    // altitude layer toggle
    const [altitudeRange, setAltitudeRange] = useLocalStorage("altitudeRange", [0, 18000]);
    const altitudeRangeRef = useRef([0, 18000]);

    // user toggle settings for asd sources
    const [sourceToggle, setSourceToggle] = useState(true);
    const sourceToggleRef = useRef(true);

    // user toggle settings for volume
    const [showOperations, setShowOperations] = useState(true);
    const [showConstraints, setShowConstraints] = useState(true);
    const [showSensors, setShowSensors] = useState(true);
    const [showAlerts, setShowAlerts] = useState(true);

    const showOperationsRef = useRef(true);
    const showConstraintsRef = useRef(true);
    const showSensorRef = useRef(true);
    const showAlertsRef = useRef(true);

    // first responder constraint variables
    const [drawingFirstResponderConstraint, setDrawingFirstResponderConstraint] = useState(false);

    // create constraint variables
    const createConstraintHandlerRef = useRef(null);
    const createConstraintCursorRef = useRef(null);
    const createConstraintEntityRef = useRef(null);

    // cursor entity
    const cursorEntities = useRef([]);

    // asd editing variables
    const [flightDetailsDialogOpen, setFlightDetailsDialogOpen] = useState(false);
    const [flightDetailsVolume, setFlightDetailsVolume] = useState("");

    // asd editing variables
    const [asdEditDialogOpen, setASDEditDialogOpen] = useState(false);
    const [asdEditEntity, setASDEditEntity] = useState("");
    const [asdRefreshEntity, setASDRefreshEntity] = useState(false);

    // asd watch variables
    const asdWatchEntityIdRef = useRef("");
    const asdWatchEntityCallsignRef = useRef("");
    const asdWatchEntityTrackAngleRef = useRef("");
    const asdWatchEntitySpeedRef = useRef("");
    const asdWatchEntityAglRef = useRef("");
    const asdWatchEntityAmslRef = useRef("");
    const asdWatchEntityAlertRef = useRef("");
    const asdInAlertVolume = useRef(false);
    const asdInZeroConflict = useRef(false);

    // fight card variables
    const [flightCardAlertStatusRefs, setFlightCardAlertStatusRefs] = useState(new Map());
    const flightCardAlertStatusRefsRef = useRef(new Map());
    const publishedOperationsMessageRef = useRef([]);

    // constraint editing variables
    const [constraintEditEntity, setConstraintEditEntity] = useState("");
    const [constraintEditDialogOpen, setConstraintEditDialogOpen] = useState(false);
    const [constraintCreateVertices, setConstraintCreateVertices] = useState(false);
    const [constraintCreateDialogOpen, setConstraintCreateDialogOpen] = useState(false);
    const [constraintCircleCenterPoint, setConstraintCircleCenterPoint] = useState("");
    const [constraintCircleCreateDialogOpen, setConstraintCircleCreateDialogOpen] = useState(false);

    // context menu variables
    const [contextMenuOpen, setContextMenuOpen] = useState(false);
    const [contextMenuEntities, setContextMenuEntities] = useState([]);
    const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState({
        getBoundingClientRect: () => ({ width: 0, height: 0, top: 0, right: 0, bottom: 0, left: 0 })
    });

    // refs to store random entities
    const highlightEntities = useRef([]);

    // map to store positions
    const spzPositions = new Map();
    const zeroConflictLinePositions = new Map();
    const dpzPositions = new Map();
    const coastedPositions = new Map();

    // maps to store data
    const zeroConflictMessages = useRef(new Map());
    const entityTimes = useRef(new Map());
    const dpzIds = useRef(new Map());

    // variables to store messages
    const [publishedOperationsMessage, setPublishedOperationsMessage] = useState([]);
    const [constraintsMessage, setConstraintsMessage] = useState([]);
    const [alertVolumesMessage, setAlertVolumesMessage] = useState([]);

    // additonal map component controllers
    const [flightCardsOpen, setFlightCardsOpen] = useLocalStorage("flightCardsOpen", false);
    const [asdWatchToolbarOpen, setASDWatchToolbarOpen] = useState(false);
    const [editFlightDialogOpen, setEditFlightDialogOpen] = useState(false);
    const [conversationDialogOpen, setConversationDialogOpen] = useState(false);

    // edit flight variables
    const [editFlightVolume, setEditFlightVolume] = useState(null);

    // sound variables
    const soundOnRef = useRef(true);

    // custom hooks
    const [mapLocation, setMapLocation] = useLocalStorage("mapLocation", null);

    // primitives
    const [airspacesClassA] = usePrimitive(viewerState, component, 0, airspacePrimitiveClassA);
    const [airspacesClassB] = usePrimitive(viewerState, component, 0, airspacePrimitiveClassB);
    const [airspacesClassC] = usePrimitive(viewerState, component, 0, airspacePrimitiveClassC);
    const [airspacesClassD] = usePrimitive(viewerState, component, 0, airspacePrimitiveClassD);
    const [airspacesClassE2] = usePrimitive(viewerState, component, 0, airspacePrimitiveClassE2);
    const [airspacesClassE3] = usePrimitive(viewerState, component, 0, airspacePrimitiveClassE3);
    const [airspacesClassE4] = usePrimitive(viewerState, component, 0, airspacePrimitiveClassE4);
    const [airspacesClassE5] = usePrimitive(viewerState, component, 0, airspacePrimitiveClassE5);
    const [airspacesModeC] = usePrimitive(viewerState, component, 0, airspacePrimitiveModeC);

    // imagery layers
    const [weatherRadarLayer, setWeatherRadarLayer] = useImageryLayer(viewerState, 0, false);

    const isDesktop = useMediaQuery("(min-width:900px)");

    useEffect(() => {
        if (location.state && location.state.volumeUuidToZoomTo) {
            volumeUuidToZoomTo.current = location.state.volumeUuidToZoomTo;
        } else {
            volumeUuidToZoomTo.current = "";
        }
    }, [location]);

    // initialize map on load
    useEffect(() => {
        const infoBox = docRef.current.querySelector("div.cesium-infoBox");
        const toolbar = docRef.current.querySelector("div.cesium-viewer-toolbar");
        const camera = docRef.current.querySelector("button.cesium-infoBox-camera");
        const modeButton = docRef.current.querySelector("span.cesium-sceneModePicker-wrapper");
        const interval = setInterval(() => removeExpiredEntities(), 1000);

        const viewer = viewerRef.current.cesiumElement;
        const sceneMode = userMapSettings.map_scene_mode;
        if (sceneMode === "SCENE3D") {
            viewer.scene.mode = SceneMode.SCENE3D;
        }
        if (sceneMode === "COLUMBUS_VIEW") {
            viewer.scene.mode = SceneMode.COLUMBUS_VIEW;
        }
        if (sceneMode === "SCENE2D") {
            viewer.scene.mode = SceneMode.SCENE2D;
        }
        if (props.standalone === true) {
            setComponent({ volumes: { airspaces: true } });
        }
        setViewerState(viewer);

        if (userMapSettings.map_preference >= 0 && userMapSettings.map_preference < providerViewModelsRef.current.length) {
            viewer.baseLayerPicker.viewModel.selectedImagery = providerViewModelsRef.current[userMapSettings.map_preference];
        }
        viewer.screenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
        viewer.selectedEntityChanged.addEventListener(handleSelectedEntityChanged);
        viewer.scene.screenSpaceCameraController.enableTilt = false;
        viewer.scene.globe.depthTestAgainstTerrain = false;
        viewer.scene.requestRenderMode = true;
        viewer.entities.removeAll();

        infoBox.insertBefore(selectedEntityActionsRef.current, camera);
        toolbar.insertBefore(flightCardsButtonRef.current, modeButton);
        toolbar.insertBefore(settingsButtonRef.current, flightCardsButtonRef.current);
        toolbar.insertBefore(layersButtonRef.current, settingsButtonRef.current);
        toolbar.insertBefore(createButtonRef.current, layersButtonRef.current);

        viewer.homeButton.viewModel.command.beforeExecute.addEventListener((e) => {
            e.cancel = true;
            viewer.camera.lookAt(Cartesian3.fromDegrees(settings.current.longitude, settings.current.latitude), new Cartesian3(0.0, 0.0, 200000.0));
            viewer.camera.lookAtTransform(Matrix4.IDENTITY);
        });

        viewer.scene.morphComplete.addEventListener((mode) => {
            const mapSettings = { ...settings.current };
            const sceneMode = mode._scene.mode;
            if (sceneMode === 1) {
                mapSettings.map_scene_mode = "COLUMBUS_VIEW";
            } else if (sceneMode === 2) {
                mapSettings.map_scene_mode = "SCENE2D";
            } else if (sceneMode === 3) {
                mapSettings.map_scene_mode = "SCENE3D";
            }
            updateMapSettings(mapSettings);
            setTimeout(() => {
                viewer.camera.lookAt(Cartesian3.fromDegrees(settings.current.longitude, settings.current.latitude), new Cartesian3(0.0, 0.0, 200000.0));
                viewer.camera.lookAtTransform(Matrix4.IDENTITY);
            }, [500]);
        });

        viewer.scene.globe.tileLoadProgressEvent.addEventListener((loadingTiles) => {
            if (loadingTiles === 0 && viewer.scene.globe.tilesLoaded) {
                document.body.setAttribute("main-map-loaded", "true");
            }
        });

        if (mapLocation) {
            viewer.camera.lookAt(Cartesian3.fromDegrees(mapLocation.longitude, mapLocation.latitude), new Cartesian3(0.0, 0.0, mapLocation.altitude));
        } else {
            viewer.camera.lookAt(Cartesian3.fromDegrees(userMapSettings.longitude, userMapSettings.latitude), new Cartesian3(0.0, 0.0, 200000.0));
        }
        viewer.camera.lookAtTransform(Matrix4.IDENTITY);

        viewer.camera.moveEnd.addEventListener(() => {
            const cartographic = viewer.camera.positionCartographic;
            const location = {
                longitude: Math.toDegrees(cartographic.longitude),
                latitude: Math.toDegrees(cartographic.latitude),
                altitude: cartographic.height
            };
            setMapLocation(location);
        });

        subscribeAndEvaluate(viewer.baseLayerPicker.viewModel, "selectedImagery", (selectedImageryProviderViewModel) => {
            for (let i = 0; i < viewer.imageryLayers.length; i++) {
                const layer = viewer.imageryLayers.get(i);
                if (layer !== undefined && layer.isBaseLayer()) {
                    layer.brightness = settings.current.brightness;
                }
            }
            const index = providerViewModelsRef.current.findIndex((imageryLayer) => {
                return imageryLayer.name === selectedImageryProviderViewModel.name;
            });
            if (index !== userMapSettings.map_preference) {
                const imagerySettings = {
                    ...settings.current,
                    map_preference: index
                };
                updateMapSettings(imagerySettings);
            }
        });
        return () => {
            clearInterval(interval);
            document.body.removeAttribute("main-map-loaded");
        };
    }, []);

    useEffect(() => {
        let timerId = null;
        const entityLabelsToShow = [];

        const viewer = viewerRef.current.cesiumElement;
        viewer.screenSpaceEventHandler.setInputAction(({ endPosition }) => {
            const cartesian = pickPosition(viewer, endPosition);
            if (cartesian) {
                // update mouse position at the bottom of the screen
                const cartographic = Cartographic.fromCartesian(cartesian);
                const longitudeString = Math.toDegrees(cartographic.longitude);
                const latitudeString = Math.toDegrees(cartographic.latitude);
                currentLocationRef.current.innerHTML = `( ${latitudeString}, ${longitudeString} )`;

                // reset entity labels
                entityLabelsToShow.forEach((entity) => {
                    entity.label.show = false;
                });
                entityLabelsToShow.length = 0;

                // timeout prevents rapid drillPicks from being fired and preserves frame rate
                clearTimeout(timerId);
                timerId = setTimeout(() => {
                    if (viewerRef.current && viewerRef.current.cesiumElement) {
                        // show labels of asd entities if mouse stops on one and settings permit
                        if (userMapSettings.label_visible === "off" || userMapSettings.label_visible === "alerts") {
                            const pickedEntities = viewer.scene.drillPick(endPosition);
                            if (defined(pickedEntities)) {
                                const filteredEntities = pickedEntities.filter(({ id }) => {
                                    if (id.label && id.label.show && id.label.show.getValue() === false) {
                                        return isAnASDEntity(id, asdEntities.current);
                                    } else {
                                        return false;
                                    }
                                });
                                if (filteredEntities.length > 0) {
                                    const entity = filteredEntities[0].id;
                                    if (entity.label) {
                                        entity.label.position = cartesian;
                                        entity.label.show = true;
                                        entityLabelsToShow.push(entity);
                                        viewer.scene.requestRender();
                                    }
                                }
                            }
                        }
                    }
                }, 250);
            }
        }, ScreenSpaceEventType.MOUSE_MOVE);
    }, [userMapSettings]);

    // useEffect to select certain entities
    useEffect(() => {
        const viewer = viewerRef.current.cesiumElement;
        const handler = new ScreenSpaceEventHandler(viewer.scene.canvas);

        handler.setInputAction((click) => {
            viewer.selectedEntity = undefined;

            const pickedEntities = viewer.scene.drillPick(click.position);
            if (!defined(pickedEntities) || createConstraintHandlerRef.current || drawingFirstResponderConstraint === true) {
                return;
            }
            const filteredEntities = pickedEntities.map((entity) => entity.id);
            const contextEntities = filteredEntities.filter((entity) => !entity.id.includes("_"));
            const finalEntities = contextEntities.filter((e, i) => contextEntities.findIndex((a) => a.id === e.id) === i);

            if (finalEntities.length > 1) {
                const xOffset = docRef.current.getBoundingClientRect().left;
                const yOffset = docRef.current.getBoundingClientRect().top;
                const virtualElement = {
                    getBoundingClientRect: () => ({
                        width: 0,
                        height: 0,
                        top: yOffset + click.position.y,
                        right: xOffset + click.position.x,
                        bottom: yOffset + click.position.y,
                        left: xOffset + click.position.x
                    })
                };
                setContextMenuOpen(true);
                setContextMenuEntities(finalEntities);
                setContextMenuAnchorEl(virtualElement);
            } else if (finalEntities.length === 1) {
                viewer.selectedEntity = finalEntities[0];
            }
        }, ScreenSpaceEventType.LEFT_CLICK);

        return () => {
            handler.destroy();
        };
    }, [drawingFirstResponderConstraint]);

    useEffect(() => {
        socket.on("asd", (message) => processASDMessage(message));
        socket.on("zero_conflict", (message) => processZeroConflictMessage(message));
        socket.on("airspace_alerting_conflicts", (message) => processAlertConflictMessage(message));

        return () => {
            socket.off("asd");
            socket.off("zero_conflict");
            socket.off("airspace_alerting_conflicts");
        };
    }, []);

    // useEffect to draw sensors
    useEffect(() => {
        if (!props.standalone || !sensors.length) {
            return;
        }
        drawSensorsOnMap(sensors);
    }, [sensors]);

    // Save any changes to the user map settings.
    useEffect(() => {
        const viewer = viewerRef.current.cesiumElement;
        settings.current = userMapSettings;

        handleUpdateAirEntitiesWithNewSettings(asdEntities.current, settings.current, sourceToggleRef.current, dpzIds.current, altitudeRangeRef.current);
        handleUpdateVolumeEntitiesWithNewSettings(operationEntities.current, settings.current);
        handleUpdateVolumeEntitiesWithNewSettings(constraintEntities.current, settings.current);
        handleUpdateVolumeEntitiesWithNewSettings(alertVolumeEntities.current, settings.current);

        zeroConflictMessages.current.forEach((value) => {
            const intruder_entity = viewer.entities.getById(value.intruder_track.id);
            const ownship_entity = viewer.entities.getById(value.ownship_track.id);
            const ring_entity = viewer.entities.getById(value.ownship_track.id + "_zc");
            const line_entity = viewer.entities.getById(value.ownship_track.id + value.intruder_track.id + "_zc_line");

            if (ring_entity) {
                updateZeroConflictRing(ring_entity, value, ownship_entity, settings.current.entity_size);
            }
            if (line_entity)
                updateZeroConflictLine(line_entity, value, ownship_entity, intruder_entity, zeroConflictLinePositions, settings.current.entity_size);
        });
        for (let i = 0; i < viewer.imageryLayers.length; i++) {
            const layer = viewer.imageryLayers.get(i);
            if (layer !== undefined && layer.isBaseLayer()) {
                layer.brightness = settings.current.brightness;
            }
        }
    }, [userMapSettings]);

    const processASDMessage = (messages) => {
        if (!viewerRef.current && !viewerRef.current.cesiumElement) {
            return;
        }

        const viewer = viewerRef.current.cesiumElement;
        createUpdateAirEntities(
            viewer,
            messages,
            asdEntities.current,
            sourceIDs.current,
            settings.current,
            user,
            altitudeRangeRef.current,
            dpzPositions,
            sourceToggleRef.current,
            publishedOperationsMessageRef.current,
            flightCardAlertStatusRefsRef.current,
            asdWatchEntityIdRef.current,
            updatedASDWatchValues,
            zeroConflictMessages.current,
            zeroConflictLinePositions,
            entityTimes.current,
            dpzIds.current,
            sourceData,
            spzPositions,
            coastedPositions
        );
        viewer.scene.requestRender();
    };

    // functions to process received volume messages
    useEffect(() => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            const operations = [];
            const flightCardStatusMap = new Map();

            publishedOperations.forEach((entity) => {
                for (const [index, volume] of entity.volumes.entries()) {
                    const operation = formatOperationToDraw(entity, index, volume, userOperationalStates);
                    operation.label = index === 0 ? true : false;
                    operations.push(operation);
                }
                flightCardStatusMap.set(entity.flight_uuid, React.createRef());
            });

            flightCardAlertStatusRefsRef.current = flightCardStatusMap;
            publishedOperationsMessageRef.current = publishedOperations;

            setFlightCardAlertStatusRefs(flightCardStatusMap);
            setPublishedOperationsMessage(publishedOperations);
            drawOperationsOnMap(operations);

            const viewer = viewerRef.current.cesiumElement;
            viewer.scene.requestRender();
        }
    }, [publishedOperations]);

    // functions to process received constraint messages
    useEffect(() => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            const constraintToZoomTo = constraints.find(({ constraint_uuid }) => {
                return constraint_uuid === volumeUuidToZoomTo.current;
            });
            if (volumeUuidToZoomTo.current && !constraintToZoomTo) {
                setSnackbar({ children: "Constraint has been removed", severity: "error" });
                navigate("/map", { state: { volumeUuidToZoomTo: "" } });
            }
            const constraintsToDraw = [];
            constraints.forEach((entity) => {
                for (const [index, volume] of entity.volumes.entries()) {
                    const constraint = formatConstraintToDraw(entity, index, volume, userOperationalStates);
                    constraint.label = index === 0 ? true : false;
                    constraintsToDraw.push(constraint);
                }
            });
            setConstraintsMessage(constraints);
            drawConstraintsOnMap(constraintsToDraw);

            const viewer = viewerRef.current.cesiumElement;
            viewer.scene.requestRender();
        }
    }, [constraints]);

    useEffect(() => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            setAlertVolumesMessage(alertVolumes);
            drawAlertVolumesOnMap(alertVolumes);

            const viewer = viewerRef.current.cesiumElement;
            viewer.scene.requestRender();
        }
    }, [alertVolumes]);

    // useEffect to fetch and set terrain provider
    useEffect(() => {
        async function fetchTerrainProvider() {
            const terrain = await createWorldTerrainAsync();
            setTerrainProvider(terrain);
        }
        fetchTerrainProvider();
    }, []);

    // functions to process received confliction messages
    const processZeroConflictMessage = (message) => {
        if (!viewerRef.current && !viewerRef.current.cesiumElement) {
            return;
        }
        const ringId = message.ownship_track.id + "_zc";
        const lineId = message.ownship_track.id + message.intruder_track.id + "_zc_line";

        const viewer = viewerRef.current.cesiumElement;
        if (message.state === "EXPIRED") {
            zeroConflictMessages.current.delete(message.ownship_track.id + message.intruder_track.id);
            handleRemoveZeroConflictEntities(message);
        } else {
            const ownshipId = message.ownship_track.id;

            const ring = viewer.entities.getById(ringId);
            const line = viewer.entities.getById(lineId);
            const ownship = viewer.entities.getById(ownshipId);

            const watchZeroConflictElement = asdWatchEntityAlertRef.current.children[0];
            const watchAlertVolumeElement = asdWatchEntityAlertRef.current.children[1];

            const color = message.severity === "WARNING" ? "red" : "yellow";
            const alert = watchAlertVolumeElement.innerHTML !== "" ? "DAA Alert, " : "DAA Alert";

            publishedOperationsMessageRef.current.forEach((operation) => {
                if (operation.asd_uuid === ownshipId) {
                    const flightCardAlertRef = flightCardAlertStatusRefsRef.current.get(operation.flight_uuid);
                    if (flightCardAlertRef !== undefined) {
                        const flightCardZeroConflictElement = flightCardAlertRef.current.children[0];
                        flightCardZeroConflictElement.innerHTML = alert;
                        flightCardZeroConflictElement.style.color = color;
                    }
                }
            });
            if (asdWatchEntityIdRef.current === ownshipId) {
                watchZeroConflictElement.innerHTML = alert;
                watchZeroConflictElement.style.color = color;
            }
            if (!ownship || !ownship.show) {
                return;
            }
            if (ring == null) {
                const newModel = getRingModel(settings.current.entity_size, message.severity);
                const newRing = viewer.entities.add(createZeroConflictRing(message, newModel, ringId));
                asdEntities.current.set(newRing.id, newRing);

                if (!sourceToggleRef.current) {
                    newRing.show = false;
                }
            }

            if (line == null) {
                const newDescription = viewer.entities.add(createUpdateZeroConflictDesc(message));
                asdEntities.current.set(newDescription.id, newDescription);

                const newLine = viewer.entities.add(createZeroConflictLine(message, lineId, zeroConflictLinePositions));
                asdEntities.current.set(newLine.id, newLine);

                if (!sourceToggleRef.current) {
                    newLine.show = false;
                    newDescription.show = false;
                }
            }
            zeroConflictMessages.current.set(message.ownship_track.id + message.intruder_track.id, message);
        }
        viewer.scene.requestRender();
    };

    const processAlertConflictMessage = (message) => {
        if (!viewerRef.current && !viewerRef.current.cesiumElement) return;

        const ringId = message.track_id + "_spz";
        const viewer = viewerRef.current.cesiumElement;
        const ringEntity = viewer.entities.getById(ringId);
        const alertEntity = viewer.entities.getById(message.track_id);

        if (!defined(alertEntity)) return;
        if (
            (user.user_role === "Admin - Org" || user.user_role === "Airspace Manager" || user.user_role === "Operations Manager") &&
            message.organization_id !== user.organization_id
        )
            return;
        if (user.user_role === "Operator" && message.created_user_id !== user.id) return;
        if (user.user_role === "First Responder") return;

        const showAlert = settings.current.label_visible !== "off" ? true : false;
        alertEntity.label.show = showAlert;

        if (ringEntity == null) {
            const ringModel = getRingModel(settings.current.entity_size, "WARNING");
            const newEntity = createAlertConflictRing(ringId, ringModel, alertEntity, spzPositions, showAlertsRef.current);
            const newAlertRing = viewer.entities.add(newEntity);
            asdEntities.current.set(newAlertRing.id, newAlertRing);
        }
        entityTimes.current.set(ringId, message.time_of_alert);
        viewer.scene.requestRender();
    };

    const handlePlaySound = useCallback(() => {
        if (soundOnRef.current) new Audio(alertSound).play();
    });

    const handleToggleSound = useCallback(() => {
        soundOnRef.current = !soundOnRef.current;
    });

    const updatedASDWatchValues = (inAlertVolume, inZeroConfliction, entity) => {
        // update the daa alert ref child if there is zero confliction
        const daaAlertElement = asdWatchEntityAlertRef.current.children[0];
        if (inZeroConfliction) {
            if (!entity.inZeroConflict) {
                entity.inZeroConflict = true;
                handlePlaySound();
            }
            const zeroConflictMessage = zeroConflictMessages.current.get(asdWatchEntityIdRef.current);
            if (zeroConflictMessage !== undefined) {
                const color = zeroConflictMessage.severity === "WARNING" ? "red" : "yellow";
                const comma = inAlertVolume !== undefined ? ", " : "";

                daaAlertElement.innerHTML = `DAA Alert${comma}`;
                daaAlertElement.style.color = color;
            }
        } else {
            if (inAlertVolume !== undefined) {
                daaAlertElement.innerHTML = "";
            } else {
                daaAlertElement.innerHTML = "--";
            }
            entity.inZeroConflict = false;
            daaAlertElement.style.color = "#6b778c";
        }
        // update the alert colume ref child if in alert volume
        const alertVolumeElement = asdWatchEntityAlertRef.current.children[1];
        if (inAlertVolume) {
            if (!entity.inAlertVolume) {
                entity.inAlertVolume = true;
                handlePlaySound();
            }
            alertVolumeElement.innerHTML = "In Alert Volume";
            alertVolumeElement.style.color = "red";
        } else {
            entity.inAlertVolume = false;
            alertVolumeElement.innerHTML = "";
            alertVolumeElement.style.color = "#6b778c";
        }
        const heading = isNaN(entity.heading_deg) ? "--" : entity.heading_deg;
        const speed_kn = isNaN(entity.speed_kn) ? "--" : entity.speed_kn;
        const agl_ft = isNaN(entity.agl_ft) ? "--" : entity.agl_ft;
        const amsl_ft = isNaN(entity.amsl_ft) ? "--" : entity.amsl_ft;

        asdWatchEntityIdRef.current = entity.id;
        asdWatchEntityCallsignRef.current.innerHTML = entity.name;
        asdWatchEntityTrackAngleRef.current.innerHTML = `${heading} °`;
        asdWatchEntitySpeedRef.current.innerHTML = `${speed_kn} kn`;
        asdWatchEntityAglRef.current.innerHTML = `${agl_ft} ft`;
        asdWatchEntityAmslRef.current.innerHTML = `${amsl_ft} ft`;
    };

    // functions to draw volumes on the map
    const drawOperationsOnMap = (operations) => {
        if (!viewerRef.current && !viewerRef.current.cesiumElement) return;

        const viewer = viewerRef.current.cesiumElement;
        removeOperationEntities();

        operations.forEach((operation) => {
            const newEntity = createVolumeEntity(operation, settings.current.op_opacity);
            const newOperation = viewer.entities.add(newEntity);
            newOperation.show = showOperationsRef.current;
            operationEntities.current.push(newOperation);

            if (operation.label) {
                const newOperationLabel = viewer.entities.add(createLabelEntity(newOperation, operation, settings.current.volume_labels));
                newOperationLabel.show = showOperationsRef.current;
                operationEntities.current.push(newOperationLabel);
            }
        });
    };
    const drawConstraintsOnMap = (constraints) => {
        if (!viewerRef.current && !viewerRef.current.cesiumElement) return;

        const viewer = viewerRef.current.cesiumElement;
        removeConstraintEntities();

        constraints.forEach((constraint) => {
            const newEntity = createVolumeEntity(constraint, settings.current.op_opacity);
            newEntity.constraint = constraint.constraint;
            const newConstraint = viewer.entities.add(newEntity);
            newConstraint.show = showConstraintsRef.current;
            constraintEntities.current.push(newConstraint);

            if (constraint.constraint?.constraint_uuid === volumeUuidToZoomTo.current) {
                viewer.selectedEntity = newConstraint;
                zoomToEntities(viewer, [newConstraint]);
                navigate("/map", { state: { volumeUuidToZoomTo: "" } });
            }
            if (constraint.label) {
                const newConstraintLabel = viewer.entities.add(createLabelEntity(newConstraint, constraint, settings.current.volume_labels));
                newConstraintLabel.show = showConstraintsRef.current;
                constraintEntities.current.push(newConstraintLabel);
            }
        });
    };

    const drawAlertVolumesOnMap = (managedAlerts) => {
        if (!viewerRef.current && !viewerRef.current.cesiumElement) return;

        const viewer = viewerRef.current.cesiumElement;
        const alertVolumes = [];
        removeAlertVolumeEntities();

        managedAlerts.forEach((alert) => {
            alert.polygons.forEach((polygon, i) => {
                const alertVolume = formatAlertVolumeToDraw(alert, polygon, settings.current.op_opacity);
                if (i === 0 && !alert.flight_uuid) {
                    alertVolume.position = getPolygonCenter(polygon);
                    alertVolume.label = true;
                }
                alertVolumes.push(alertVolume);
            });
        });

        alertVolumes.forEach((alertVolume) => {
            const newAlertVolume = viewer.entities.add(alertVolume);
            newAlertVolume.show = showAlertsRef.current;
            alertVolumeEntities.current.push(newAlertVolume);

            if (alertVolume.label) {
                const newAlertVolumeLabel = viewer.entities.add(createLabelEntity(newAlertVolume, alertVolume, settings.current.volume_labels));
                newAlertVolumeLabel.show = showAlertsRef.current;
                alertVolumeEntities.current.push(newAlertVolumeLabel);
            }
        });
    };
    const drawSensorsOnMap = (sensors) => {
        if (!viewerRef.current && !viewerRef.current.cesiumElement) {
            return;
        }
        const viewer = viewerRef.current.cesiumElement;
        removeSensorEntities();

        sensors.forEach((sensor) => {
            if (!sensor.lon || !sensor.lat || !sensor.radius) {
                return;
            }
            const entity = createSensorEntity(sensor);
            entity.show = showSensors;

            const newSensor = viewer.entities.add(entity);
            newSensor.ellipse.outlineColor = new CallbackProperty(() => {
                return highlightEntities.current.includes(newSensor) ? Color.LIME : Color.BLACK;
            }, false);
            sensorEntities.current.push(newSensor);
        });
    };

    // functions to remove entities from map
    const removeOperationEntities = () => {
        const viewer = viewerRef.current.cesiumElement;
        for (const entity of operationEntities.current) {
            viewer.entities.remove(entity);
        }
        operationEntities.current = [];
    };

    const removeConstraintEntities = () => {
        const viewer = viewerRef.current.cesiumElement;
        for (const entity of constraintEntities.current) {
            viewer.entities.remove(entity);
        }
        constraintEntities.current = [];
    };
    const removeAlertVolumeEntities = () => {
        const viewer = viewerRef.current.cesiumElement;
        for (const entity of alertVolumeEntities.current) {
            viewer.entities.remove(entity);
        }
        alertVolumeEntities.current = [];
    };

    const removeSensorEntities = () => {
        const viewer = viewerRef.current.cesiumElement;
        for (const entity of sensorEntities.current) {
            viewer.entities.remove(entity);
        }
        sensorEntities.current = [];
    };

    // handler function to handle selected entity change
    const handleSelectedEntityChanged = () => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            const viewer = viewerRef.current.cesiumElement;
            const entity = viewer.selectedEntity;

            if (entity) {
                // if selected entity is a sensor highlight it
                const is_sensor_entity = sensorEntities.current.includes(entity) ? true : false;
                if (is_sensor_entity) {
                    highlightEntities.current.push(entity);
                }
                // if selected entity is asd show the watch button
                const is_asd_entity = isAnASDEntity(entity, asdEntities.current);
                if (is_asd_entity) {
                    watchButtonRef.current.style.display = "block";

                    if (user.user_role === "Admin" || user.user_role === "Airspace Manager" || user.user_role === "Operator") {
                        tagButtonRef.current.style.display = "block";
                    }
                }
                // if selected entity is a constraint show the publish, edit, and delete buttons
                const is_constraint_entity = constraintEntities.current.includes(entity) ? true : false;
                if (is_constraint_entity && (user.user_role === "Admin" || user.user_role === "Airspace Manager")) {
                    if (entity.constraint && entity.constraint.state !== "ACCEPTED") {
                        publishButtonRef.current.style.display = "block";
                    }
                    editButtonRef.current.style.display = "block";
                    deleteButtonRef.current.style.display = "block";
                }
                if (is_constraint_entity && user.user_role === "First Responder" && entity.constraint && entity.constraint.created_user_id == user.id) {
                    deleteButtonRef.current.style.display = "block";
                }
            } else {
                // reset highlighted entities
                highlightEntities.current.length = 0;

                // remove buttons from description box
                watchButtonRef.current.style.display = "none";
                editButtonRef.current.style.display = "none";
                publishButtonRef.current.style.display = "none";
                deleteButtonRef.current.style.display = "none";
                tagButtonRef.current.style.display = "none";
            }
        }
    };
    const handleCallWatchEntity = () => {
        const viewer = viewerRef.current.cesiumElement;
        const selectedEntity = viewer.selectedEntity;
        handleWatchEntity(selectedEntity.id);
    };

    const handleWatchEntity = (id) => {
        const viewer = viewerRef.current.cesiumElement;
        const selectedEntity = viewer.entities.getById(id);
        const isASD = isAnASDEntity(selectedEntity, asdEntities.current);

        if (!selectedEntity || !isASD) {
            return;
        }
        const inAlertVolume = viewer.entities.getById(selectedEntity.id + "_spz");
        const inZeroConfliction = viewer.entities.getById(selectedEntity.id + "_zc");

        updatedASDWatchValues(inAlertVolume, inZeroConfliction, selectedEntity);
        setASDWatchToolbarOpen(true);
    };

    const handleCheckFlightASDVisible = (id) => {
        if (!id || !viewerRef.current || !viewerRef.current.cesiumElement) {
            return false;
        }
        const viewer = viewerRef.current.cesiumElement;
        return viewer.entities.getById(id) ? true : false;
    };

    const handleSetSelectedEntity = (entity) => {
        const viewer = viewerRef.current.cesiumElement;
        if (viewer.entities.contains(entity)) {
            viewer.selectedEntity = entity;
        }
        handleCloseContextMenu();
    };

    const handleShowEntityDpz = (asd) => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            const viewer = viewerRef.current.cesiumElement;

            const dpz_id = asd.id + "_dpz";
            const dpz_entity = viewer.entities.getById(dpz_id);
            if (defined(dpz_entity)) {
                dpz_entity.show = asd.show_dpz && userMapSettings.well_clear_volume;
                dpzIds.current.set(dpz_id, asd.show_dpz);
            }
            const asd_id = asd.id;
            const asd_entity = viewer.entities.getById(asd_id);
            if (defined(asd_entity)) {
                asd_entity.name = asd.callsign;
                asd_entity.show_dpz = asd.show_dpz;
                asd_entity.flight_uuid = asd.flight_uuid;
                asd_entity.daa_config_uuid = asd.alerting_params_uuid;

                if (asd_entity.label) {
                    const altitude = `${asd_entity.altitude}ft ${GetVerticalSpeedIndicator(asd_entity.vertical_speed_fpm)}`;
                    asd_entity.label.text = `${asd.callsign}\n${altitude}\n${asd_entity.speed_kn}kn`;
                }
            }
            viewer.scene.requestRender();
        }
    };

    const handleOnClick = (e) => {
        const virtualElement = {
            getBoundingClientRect: () => ({ width: 0, height: 0, top: e.clientY, right: e.clientX, bottom: e.clientY, left: e.clientX })
        };
        setContextMenuAnchorEl(virtualElement);
    };

    const handleCreateConstraint = () => {
        const viewer = viewerRef.current.cesiumElement;
        if (createConstraintHandlerRef.current === null) {
            const newCreateConstraintHandler = new ScreenSpaceEventHandler(viewer.canvas);
            createConstraintHandlerRef.current = newCreateConstraintHandler;
            handleToggleConstraints({ target: { checked: true } });

            let cursor_entity_position = undefined;
            const cursor_entity = viewer.entities.add({
                point: { color: Color.WHITE, pixelSize: 5 },
                position: new CallbackProperty(() => {
                    return cursor_entity_position;
                }, false)
            });
            createConstraintCursorRef.current = cursor_entity;

            const constraint_entity_polygon_hierarchy = [];
            const constraint_entity_polyline_positions = [];
            const constraint_entity = viewer.entities.add({
                polyline: {
                    show: true,
                    width: 3,
                    material: Color.WHITE,
                    clampToGround: true
                },
                polygon: {
                    show: false,
                    material: Color.WHITE,
                    hierarchy: new CallbackProperty(() => {
                        return new PolygonHierarchy(constraint_entity_polygon_hierarchy);
                    })
                }
            });
            createConstraintEntityRef.current = constraint_entity;

            let constraint_vertices = [];
            let first_cursor_entity = null;
            createConstraintHandlerRef.current.setInputAction((event) => {
                const pickedEntities = viewer.scene.drillPick(event.position);
                if (defined(pickedEntities)) {
                    const first_entity = pickedEntities.find(({ id }) => {
                        if (first_cursor_entity !== null) {
                            return id.id === first_cursor_entity.id;
                        } else {
                            return false;
                        }
                    });
                    if (first_entity !== undefined) {
                        if (constraint_vertices.length < 3) {
                            alert("Please enter 3 or more coordinates before continuing");
                            return;
                        } else {
                            handleRemoveConstraintHandler();
                            setConstraintCreateDialogOpen(true);
                            setConstraintCreateVertices([{ id: 0, vertices: constraint_vertices }]);
                            return;
                        }
                    }
                }
                if (event.position.clientX != 0 && event.position.clientY != 0) {
                    const leftClickPosition = pickPosition(viewer, event.position);
                    if (defined(leftClickPosition)) {
                        const floatingPoint = createPoint(leftClickPosition);
                        cursorEntities.current.push(floatingPoint);

                        // if first entity is null set it
                        if (first_cursor_entity === null) {
                            first_cursor_entity = floatingPoint;
                        }
                        const cartographic = Cartographic.fromCartesian(leftClickPosition);
                        const latitude = Math.toDegrees(cartographic.latitude);
                        const longitude = Math.toDegrees(cartographic.longitude);

                        constraint_entity_polyline_positions.push(longitude, latitude);
                        constraint_entity_polygon_hierarchy.push(leftClickPosition);
                        constraint_vertices.push({ lng: longitude, lat: latitude });

                        // set polyline positions once there are 4
                        if (constraint_entity_polyline_positions.length === 4) {
                            constraint_entity.polyline.positions = new CallbackProperty(() => {
                                return Cartesian3.fromDegreesArray(constraint_entity_polyline_positions);
                            }, false);
                        }
                    }
                }
            }, ScreenSpaceEventType.LEFT_CLICK);

            let timer = undefined;
            createConstraintHandlerRef.current.setInputAction(({ endPosition }) => {
                const mouseMovePosition = pickPosition(viewer, endPosition);
                if (defined(mouseMovePosition)) {
                    if (first_cursor_entity !== null) {
                        clearTimeout(timer);
                        timer = setTimeout(() => {
                            const pickedEntities = viewer.scene.drillPick(endPosition);
                            if (defined(pickedEntities)) {
                                const first_entity = pickedEntities.find(({ id }) => {
                                    if (first_cursor_entity !== null) {
                                        return id.id === first_cursor_entity.id;
                                    } else {
                                        return false;
                                    }
                                });
                                if (first_entity !== undefined) {
                                    // if mouse is over the first element for the first time show the polygon
                                    if (constraint_entity.polyline.show.getValue() === true && constraint_entity.polygon.show.getValue() === false) {
                                        constraint_entity.polyline.show = false;
                                        constraint_entity.polygon.show = true;

                                        constraint_entity_polygon_hierarchy.push(constraint_entity_polygon_hierarchy[0]);
                                        createConstraintCursorRef.current.show = false;
                                    }
                                } else {
                                    // if mouse moves away from the first element hide the polygon
                                    if (constraint_entity.polyline.show.getValue() === false && constraint_entity.polygon.show.getValue() === true) {
                                        constraint_entity.polyline.show = true;
                                        constraint_entity.polygon.show = false;

                                        constraint_entity_polygon_hierarchy.pop();
                                        createConstraintCursorRef.current.show = true;
                                    }
                                }
                                viewer.scene.requestRender();
                            }
                        }, 100);
                    }
                    // on mobile do not worry about moving the cursor or polyline on hover
                    if (isDesktop === true) {
                        const cartographic = Cartographic.fromCartesian(mouseMovePosition);
                        const latitude = Math.toDegrees(cartographic.latitude);
                        const longitude = Math.toDegrees(cartographic.longitude);

                        cursor_entity_position = mouseMovePosition;
                        constraint_entity_polyline_positions.splice(-2);
                        constraint_entity_polyline_positions.push(longitude, latitude);
                    }
                    viewer.scene.requestRender();
                }
            }, ScreenSpaceEventType.MOUSE_MOVE);
        } else {
            handleRemoveConstraintHandler();
        }
    };
    const handleRemoveConstraintHandler = () => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            const viewer = viewerRef.current.cesiumElement;
            cursorEntities.current.forEach((entity) => {
                viewer.entities.remove(entity);
            });
            viewer.entities.remove(createConstraintCursorRef.current);
            viewer.entities.remove(createConstraintEntityRef.current);

            createConstraintHandlerRef.current.destroy();
            createConstraintHandlerRef.current = null;
        }
    };

    //Handle the toggle for asd entities
    const handleToggleSources = (e) => {
        const value = e.target.checked;
        sourceToggleRef.current = value;
        setSourceToggle(value);

        asdEntities.current.forEach((entity) => {
            const visibility = entityIsVisible(entity, value, altitudeRangeRef.current, settings.current);
            if (visibility) {
                if (entity.id.includes("_pl")) {
                    entity.show = settings.current.pred_line;
                } else if (entity.id.includes("_hl")) {
                    entity.show = settings.current.hist_line;
                } else if (entity.id.includes("_dpz")) {
                    entity.show = settings.current.well_clear_volume && dpzIds.current.get(entity.source_id);
                } else if (entity.id.includes("_spz")) {
                    entity.show = showAlertsRef.current;
                } else {
                    entity.show = true;
                }
            } else {
                entity.show = false;
            }
        });
        e.stopPropagation();
    };

    // Handle the toggle for asd entities based on altitude
    const handleToggleAltitude = (e, value) => {
        altitudeRangeRef.current = value;
        setAltitudeRange(value);

        asdEntities.current.forEach((entity) => {
            if (entity.id.includes("_zc")) {
                return;
            }
            const pred_line_setting = settings.current.pred_line;
            const hist_line_setting = settings.current.hist_line;

            if (entity.source_type === "TELEMETRY") {
                return; // telemetry vehicles should be shown regardless of altitude
            }
            const visibility = entityIsVisible(entity, sourceToggleRef.current, value, settings.current);
            if (visibility) {
                if (entity.id.includes("_pl")) {
                    entity.show = pred_line_setting;
                } else if (entity.id.includes("_hl")) {
                    entity.show = hist_line_setting;
                } else if (entity.id.includes("_dpz")) {
                    entity.show = settings.current.well_clear_volume && dpzIds.current.get(entity.source_id);
                } else if (entity.id.includes("_spz")) {
                    entity.show = showAlertsRef.current;
                } else {
                    entity.show = true;
                }
            } else {
                entity.show = false;
            }
        });
    };

    //Handle the toggle for operation entities
    const handleToggleOperations = (e) => {
        for (const entity of operationEntities.current) entity.show = e.target.checked;

        showOperationsRef.current = e.target.checked;
        setShowOperations(e.target.checked);
    };

    //Handle the toggle for alert entities
    const handleToggleAlerts = (e) => {
        const value = e.target.checked;
        for (const entity of alertVolumeEntities.current) {
            entity.show = value;
        }
        handleToggleVisibilityOfAlertRingEntities(asdEntities.current, value);
        showAlertsRef.current = value;
        setShowAlerts(value);
    };

    //Handle the toggle for constraint entities
    const handleToggleConstraints = (e) => {
        for (const entity of constraintEntities.current) entity.show = e.target.checked;

        showConstraintsRef.current = e.target.checked;
        setShowConstraints(e.target.checked);
    };

    //Handle the toggle for sensor entities
    const handleToggleSensors = (e) => {
        for (const entity of sensorEntities.current) entity.show = e.target.checked;

        showSensorRef.current = e.target.checked;
        setShowSensors(e.target.checked);
    };

    // misc functions
    const removeExpiredEntities = () => {
        // remove asd entities
        entityTimes.current.forEach((value, key) => {
            const date = new Date(value);
            const exp_date = new Date(date).setSeconds(date.getSeconds() + ENTITY_TIMEOUT_SECONDS);
            const current_time = new Date();

            if (current_time > exp_date) {
                const viewer = viewerRef.current.cesiumElement;
                const asd_entity = asdEntities.current.get(key);

                if (asd_entity !== undefined) {
                    asdEntities.current.delete(key);
                }
                if (key === asdWatchEntityIdRef.current) {
                    const daaAlertElement = asdWatchEntityAlertRef.current.children[0];
                    daaAlertElement.innerHTML = "--";
                    daaAlertElement.style.color = "#6b778c";

                    const alertVolumeElement = asdWatchEntityAlertRef.current.children[1];
                    alertVolumeElement.innerHTML = "";
                    alertVolumeElement.style.color = "#6b778c";

                    asdWatchEntityCallsignRef.current.innerHTML = "--";
                    asdWatchEntityTrackAngleRef.current.innerHTML = "-- °";
                    asdWatchEntityAglRef.current.innerHTML = "-- ft";
                    asdWatchEntityAmslRef.current.innerHTML = "-- ft";
                    asdWatchEntitySpeedRef.current.innerHTML = "-- kn";
                    asdInAlertVolume.current = false;
                    asdInZeroConflict.current = false;
                }
                const associatedOperation = publishedOperationsMessageRef.current.find((op) => op.asd_uuid === key);
                if (associatedOperation) {
                    const alertStatusRef = flightCardAlertStatusRefsRef.current.get(associatedOperation.flight_uuid);

                    const daaAlertElement = alertStatusRef.current.children[0];
                    daaAlertElement.innerHTML = "--";
                    daaAlertElement.style.color = "#6b778c";

                    const alertVolumeElement = alertStatusRef.current.children[1];
                    alertVolumeElement.innerHTML = "";
                    alertVolumeElement.style.color = "#6b778c";
                }
                viewer.entities.removeById(key);
                viewer.entities.removeById(key + "_pl");
                viewer.entities.removeById(key + "_hl");
                viewer.entities.removeById(key + "_dpz");
                viewer.entities.removeById(key + "_spz");
                viewer.entities.removeById(key + "_coasted");
                entityTimes.current.delete(key);
            }
        });
        // remove zero conflict entities
        const messages_to_remove = [];
        zeroConflictMessages.current.forEach((value) => {
            const msg_timestamp_ms = new Date(value.timestamp).getTime();
            const current_timestamp_ms = new Date().getTime();
            if (current_timestamp_ms - msg_timestamp_ms > ENTITY_TIMEOUT_SECONDS * 1000) {
                messages_to_remove.push(value);
            }
        });
        messages_to_remove.forEach((message) => {
            zeroConflictMessages.current.delete(message.ownship_track.id + message.intruder_track.id);
            handleRemoveZeroConflictEntities(message);
        });
    };
    const createPoint = (worldPosition) => {
        const viewer = viewerRef.current.cesiumElement;
        const heightReference = viewer.scene.mode != SceneMode.SCENE2D ? HeightReference.CLAMP_TO_GROUND : HeightReference.NONE;

        const point = viewer.entities.add({
            position: worldPosition,
            billboard: {
                image: "../static/2d-models/shapes/circle.png",
                color: Color.WHITE,
                scale: 0.35,
                heightReference: heightReference
            }
        });
        return point;
    };
    const handleCloseContextMenu = () => {
        setContextMenuOpen(false);
        setContextMenuAnchorEl(null);
    };
    // function to handle zero confliction cleanup
    const handleRemoveZeroConflictEntities = (message) => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            const viewer = viewerRef.current.cesiumElement;
            // remove line and desc pertaining to that message
            const ids = [
                message.ownship_track.id + message.intruder_track.id + "_zc_line", // zc line id
                message.ownship_track.id + message.intruder_track.id + "_zc_desc" // zc desc id
            ];
            ids.forEach((entity_id) => {
                asdEntities.current.delete(entity_id);
                viewer.entities.removeById(entity_id);
            });
            // check if there is another alert for this ownship
            let there_is_another_alert = false;
            zeroConflictMessages.current.forEach((value) => {
                if (value.ownship_track.id === message.ownship_track.id) {
                    there_is_another_alert = true;
                }
            });
            // remove ring only if there are not any other zero conflictions for that ownship
            if (there_is_another_alert === false) {
                const ring_id = message.ownship_track.id + "_zc"; // zc ring id
                asdEntities.current.delete(ring_id);
                viewer.entities.removeById(ring_id);
            }
        }
    };
    const handleEditConstraintButtonClicked = () => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            const viewer = viewerRef.current.cesiumElement;
            const entity = viewer.selectedEntity;

            setConstraintEditDialogOpen(true);
            setConstraintEditEntity(entity);
        }
    };
    const handlePublishConstraintButtonClicked = () => {
        if (confirm("Are you sure you want to publish this constraint?") === true) {
            if (viewerRef.current && viewerRef.current.cesiumElement) {
                const viewer = viewerRef.current.cesiumElement;
                const entity = viewer.selectedEntity;

                if (StartTimeHasAlreadyPassed(entity.startTime)) {
                    return alert("The start time of this constraint has passed. Please update the start time before publishing.");
                }
                const constraint = entity.constraint;
                constraint.version = parseInt(constraint.version) + 1;
                constraint.state = "ACCEPTED";

                const requestOptions = {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(constraint)
                };
                fetch("api/constraint/update", requestOptions)
                    .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                    .then(() => {
                        setSnackbar({ children: "Constraint successfully updated", severity: "success" });
                        setConstraintEditDialogOpen(false);
                    })
                    .catch((err) => handleFailedFetch(err));
            }
        }
    };
    const handleDeleteConstraintButtonClicked = () => {
        if (confirm("Are you sure you want to delete this constraint?") === true) {
            if (viewerRef.current && viewerRef.current.cesiumElement) {
                const viewer = viewerRef.current.cesiumElement;
                const constraint = viewer.selectedEntity.constraint;

                const requestOptions = {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(constraint)
                };
                fetch("/api/constraint/delete", requestOptions)
                    .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                    .then(() => {
                        setSnackbar({ children: "Constraint successfully deleted", severity: "success" });
                        setConstraintEditDialogOpen(false);
                    })
                    .catch((err) => handleFailedFetch(err));
            }
        }
    };
    const handleTagEntityButtonClicked = () => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            const viewer = viewerRef.current.cesiumElement;
            const entity = viewer.selectedEntity;

            setASDEditEntity(entity);
            setASDEditDialogOpen(true);
            setASDRefreshEntity(entity);

            viewer.selectedEntity = undefined;
        }
    };
    const handleJumpToOperation = (flight_uuid) => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            const all_operational_volumes = operationEntities.current.filter((entity) => {
                return entity.uuid === flight_uuid;
            });
            if (Array.isArray(all_operational_volumes) && all_operational_volumes.length > 0) {
                const viewer = viewerRef.current.cesiumElement;
                zoomToEntities(viewer, all_operational_volumes);

                if (isDesktop === false) {
                    setFlightCardsOpen(false);
                }
            } else {
                alert("Unable to locate this operation. Please try again later");
            }
        }
    };
    const handleFirstResponderConstraintButtonClick = () => {
        if (viewerRef.current && viewerRef.current.cesiumElement) {
            const viewer = viewerRef.current.cesiumElement;
            handleToggleConstraints({ target: { checked: true } });
            setDrawingFirstResponderConstraint(true);

            const cursor = viewer.entities.add({
                point: { color: Color.WHITE, pixelSize: 5 }
            });
            const handler = new ScreenSpaceEventHandler(viewer.canvas);
            handler.setInputAction((event) => {
                const mouseMovePosition = pickPosition(viewer, event.endPosition);
                if (defined(mouseMovePosition)) {
                    cursor.position = mouseMovePosition;
                    viewer.scene.requestRender();
                }
            }, ScreenSpaceEventType.MOUSE_MOVE);

            handler.setInputAction((event) => {
                if (event.position.clientX != 0 && event.position.clientY != 0) {
                    const leftClickPosition = pickPosition(viewer, event.position);
                    if (defined(leftClickPosition)) {
                        const cartographic = Cartographic.fromCartesian(leftClickPosition);
                        setConstraintCircleCenterPoint(cartographic);
                        setConstraintCircleCreateDialogOpen(true);

                        handler.destroy();
                        viewer.entities.remove(cursor);
                        setDrawingFirstResponderConstraint(false);
                    }
                }
            }, ScreenSpaceEventType.LEFT_CLICK);
        }
    };
    return (
        <Paper sx={{ display: "flex", flexDirection: "row", overflowX: "hidden", width: "100%" }}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }} onClick={handleOnClick} ref={docRef}>
                <EntityWatchToolbar
                    asdWatchToolbarOpen={asdWatchToolbarOpen}
                    asdWatchEntityCallsignRef={asdWatchEntityCallsignRef}
                    asdWatchEntityTrackAngleRef={asdWatchEntityTrackAngleRef}
                    asdWatchEntityAglRef={asdWatchEntityAglRef}
                    asdWatchEntityAmslRef={asdWatchEntityAmslRef}
                    asdWatchEntitySpeedRef={asdWatchEntitySpeedRef}
                    asdWatchEntityAlertRef={asdWatchEntityAlertRef}
                    setASDWatchToolbarOpen={setASDWatchToolbarOpen}
                    toggleSound={handleToggleSound}
                />
                <Box sx={{ flex: 1, position: "relative" }}>
                    <Viewer
                        full
                        ref={viewerRef}
                        animation={false}
                        timeline={false}
                        imageryProviderViewModels={providerViewModelsRef.current}
                        terrainProvider={terrainProvider}
                        navigationHelpButton={false}
                    />

                    {mapLayersOpen === true ? (
                        <ClickAwayListener onClickAway={() => setMapLayersOpen(false)}>
                            <Box className="custom-baseLayerPicker-dropDown">
                                <MapLayersDropdown
                                    sourceIDs={sourceIDs.current}
                                    handleToggleSources={handleToggleSources}
                                    handleToggleAltitude={handleToggleAltitude}
                                    handleToggleOperations={handleToggleOperations}
                                    handleToggleAlerts={handleToggleAlerts}
                                    handleToggleConstraints={handleToggleConstraints}
                                    handleToggleSensors={handleToggleSensors}
                                    altitudeRange={altitudeRange}
                                    sourceToggle={sourceToggle}
                                    showOperations={showOperations}
                                    showConstraints={showConstraints}
                                    showAlerts={showAlerts}
                                    showSensors={showSensors}
                                    airspacesClassA={airspacesClassA}
                                    airspacesClassB={airspacesClassB}
                                    airspacesClassC={airspacesClassC}
                                    airspacesClassD={airspacesClassD}
                                    airspacesClassE2={airspacesClassE2}
                                    airspacesClassE3={airspacesClassE3}
                                    airspacesClassE4={airspacesClassE4}
                                    airspacesClassE5={airspacesClassE5}
                                    airspacesModeC={airspacesModeC}
                                    weatherRadarLayer={weatherRadarLayer}
                                    setWeatherRadarLayer={setWeatherRadarLayer}
                                />
                            </Box>
                        </ClickAwayListener>
                    ) : (
                        <></>
                    )}
                    {mapSettingsOpen ? <MapSettingsDropdown setMapSettingsOpen={setMapSettingsOpen} setSnackbar={setSnackbar} /> : <></>}
                    <div ref={currentLocationRef} className="cesium-dialog" />
                </Box>
            </Box>
            <Drawer
                variant="persistent"
                anchor="right"
                open={flightCardsOpen}
                sx={{
                    "& .MuiDrawer-paper": {
                        position: isDesktop ? "relative" : "fixed",
                        whiteSpace: "nowrap",
                        width: isDesktop ? FLIGHT_CARD_WIDTH_PX : "100%",
                        transition: (theme) =>
                            theme.transitions.create("width", {
                                easing: theme.transitions.easing.sharp,
                                duration: theme.transitions.duration.enteringScreen
                            }),
                        boxSizing: "border-box",
                        ...(!flightCardsOpen && {
                            overflowX: "hidden",
                            display: "none",
                            transition: (theme) =>
                                theme.transitions.create("width", {
                                    easing: theme.transitions.easing.sharp,
                                    duration: theme.transitions.duration.leavingScreen
                                }),
                            width: (theme) => ({ xs: theme.spacing(1), md: theme.spacing(2) })
                        })
                    }
                }}
            >
                {isDesktop === false ? <Toolbar /> : <></>}
                <MapFlightCards
                    operations={publishedOperationsMessage}
                    flightCardAlertStatusRefs={flightCardAlertStatusRefs}
                    flightDetailsDialogOpen={flightDetailsDialogOpen}
                    setFlightDetailsVolume={setFlightDetailsVolume}
                    setFlightDetailsDialogOpen={setFlightDetailsDialogOpen}
                    setConversationDialogOpen={setConversationDialogOpen}
                    setEditFlightVolume={setEditFlightVolume}
                    setEditFlightDialogOpen={setEditFlightDialogOpen}
                    handleWatchEntity={handleWatchEntity}
                    handleCheckFlightASDVisible={handleCheckFlightASDVisible}
                    setFlightCardsOpen={setFlightCardsOpen}
                    handleJumpToOperation={handleJumpToOperation}
                />
            </Drawer>
            <button
                ref={layersButtonRef}
                className="cesium-button cesium-toolbar-button"
                onClick={() => setMapLayersOpen(true)}
                title="Map Layers"
                id="mapLayers"
            >
                <LayersIcon />
            </button>
            <button
                ref={settingsButtonRef}
                className="cesium-button cesium-toolbar-button"
                onClick={() => setMapSettingsOpen(true)}
                title="Map Settings"
                id="mapSettings"
            >
                <SettingsIcon />
            </button>
            <button
                ref={createButtonRef}
                className="cesium-button cesium-toolbar-button"
                onClick={handleCreateConstraint}
                title="Create"
                style={{ display: user.user_role === "Admin" || user.user_role === "Airspace Manager" ? "unset" : "none" }}
            >
                <ModeIcon />
            </button>
            <button
                ref={flightCardsButtonRef}
                className="cesium-button cesium-toolbar-button"
                onClick={() => setFlightCardsOpen((prev) => !prev)}
                title="Flight Cards"
                id="flightCards"
                style={{ display: user.user_role_id === 5 ? "none" : "unset" }}
            >
                <StyleIcon />
            </button>
            {/* description box action buttons */}
            <Box ref={selectedEntityActionsRef} sx={{ display: "flex", flexDirection: "row", position: "absolute", top: "5px", left: "5px", gap: "5px" }}>
                {[
                    { title: "Watch", ref: watchButtonRef, func: handleCallWatchEntity, icon: <VisibilityIcon fontSize="small" /> },
                    { title: "Tag", ref: tagButtonRef, func: handleTagEntityButtonClicked, icon: <SellIcon fontSize="small" /> },
                    { title: "Publish", ref: publishButtonRef, func: handlePublishConstraintButtonClicked, icon: <PublishIcon fontSize="small" /> },
                    { title: "Edit", ref: editButtonRef, func: handleEditConstraintButtonClicked, icon: <EditIcon fontSize="small" /> },
                    { title: "Delete", ref: deleteButtonRef, func: handleDeleteConstraintButtonClicked, icon: <DeleteIcon fontSize="small" /> }
                ].map(({ title, ref, func, icon }, i) => {
                    return (
                        <button
                            key={i}
                            ref={ref}
                            className="cesium-button cesium-infoBox-camera"
                            onClick={func}
                            title={title}
                            style={{ position: "relative", left: "unset", top: "unset" }}
                        >
                            {icon}
                        </button>
                    );
                })}
            </Box>

            {asdEditDialogOpen === true ? (
                <EntityEditDialog
                    operations={publishedOperationsMessage}
                    asdEditDialogOpen={asdEditDialogOpen}
                    asdEditEntity={asdEditEntity}
                    handleShowEntityDpz={handleShowEntityDpz}
                    setASDEditDialogOpen={setASDEditDialogOpen}
                    key={asdRefreshEntity}
                />
            ) : (
                <></>
            )}
            <MapContextMenu
                contextMenuOpen={contextMenuOpen}
                contextMenuAnchorEl={contextMenuAnchorEl}
                contextMenuEntities={contextMenuEntities}
                handleCloseContextMenu={handleCloseContextMenu}
                handleSetSelectedEntity={handleSetSelectedEntity}
            />
            <ConstraintDraw
                snackbar={snackbar}
                constraintCreateVertices={constraintCreateVertices}
                constraintCreateDialogOpen={constraintCreateDialogOpen}
                setSnackbar={setSnackbar}
                setConstraintCreateDialogOpen={setConstraintCreateDialogOpen}
            />
            <ConstraintEdit
                snackbar={snackbar}
                constraintEditEntity={constraintEditEntity}
                constraintEditDialogOpen={constraintEditDialogOpen}
                setSnackbar={setSnackbar}
                setConstraintEditDialogOpen={setConstraintEditDialogOpen}
            />
            {constraintCircleCreateDialogOpen === true ? (
                <ConstraintCircleCreateDialog
                    snackbar={snackbar}
                    constraintCircleCenterPoint={constraintCircleCenterPoint}
                    constraintCircleCreateDialogOpen={constraintCircleCreateDialogOpen}
                    setConstraintCircleCreateDialogOpen={setConstraintCircleCreateDialogOpen}
                    setSnackbar={setSnackbar}
                />
            ) : (
                <></>
            )}
            {conversationDialogOpen ? (
                <ConversationDialog conversationDialogOpen={conversationDialogOpen} setConversationDialogOpen={setConversationDialogOpen} />
            ) : (
                <></>
            )}
            {editFlightDialogOpen ? (
                <EditFlightDialog
                    type={1}
                    colors={colors}
                    publishedFlight={true}
                    constraints={constraintsMessage}
                    alertVolumes={alertVolumesMessage}
                    planningOperations={[]}
                    publishedOperations={publishedOperationsMessage}
                    editFlightVolume={editFlightVolume}
                    editFlightDialogOpen={editFlightDialogOpen}
                    setEditFlightVolume={setEditFlightVolume}
                    setEditFlightDialogOpen={setEditFlightDialogOpen}
                />
            ) : (
                <></>
            )}
            {flightDetailsDialogOpen ? (
                <Suspense fallback={<></>}>
                    <FlightDetailsDialog
                        flightDetailsVolume={flightDetailsVolume}
                        flightDetailsDialogOpen={flightDetailsDialogOpen}
                        publishedOperations={publishedOperationsMessage}
                        setFlightDetailsVolume={setFlightDetailsVolume}
                        setFlightDetailsDialogOpen={setFlightDetailsDialogOpen}
                    />
                </Suspense>
            ) : (
                <></>
            )}
            {user.user_role === "First Responder" ? (
                <Fab
                    color="primary"
                    onClick={handleFirstResponderConstraintButtonClick}
                    disabled={drawingFirstResponderConstraint}
                    sx={{
                        position: "absolute",
                        bottom: "40px",
                        right: "10px",
                        zIndex: "unset"
                    }}
                    id="firstResponderConstraint"
                >
                    <AddIcon />
                </Fab>
            ) : (
                <></>
            )}
        </Paper>
    );
};
