import React from "react";
import ReactDom from "react-dom";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { App } from "./app";
import { Chat } from "./chat/chat";
import { DataLogs } from "./datalogs/datalog";
import { HMSSystemStatus } from "./hms/hmsSystemStatus";
import { Manager } from "./manager/manager";
import { PlanningOps } from "./manager/operations/planningOps";
import { MapComponent } from "./map/map";
import { DataLoggingDashboard } from "./monitoring/dataLoggingDashboard";
import { DroneSettings } from "./settings/droneSettings";
import { HMSSettings } from "./settings/hmsSettings";
import { CreateUser } from "./users/createUser";
import { Forgot } from "./users/forgotPassword";
import { Login } from "./users/login";
import { Reset } from "./users/resetPassword";
import { UserInfo } from "./users/userInfo";
import { UserList } from "./users/userList";
import { UserNotification } from "./users/userNotifications";
import { ZCASettings } from "./settings/zcaSettings";

import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { UserAuthContextProvider } from "./contexts/authContext";
import { EnvProvider } from "./contexts/envContext";
import { MapProvider } from "./contexts/mapContext";
import { SocketProvider } from "./contexts/socketContext";

import ErrorBoundary from "./errorBoundary";
import Metrics from "./metrics/metrics";
import OperatorDashboard from "./manager/operations/operatorDashboard";
import Settings from "./settings/settings";

import "./css/cmp.css";

const darkTheme = createTheme({
    palette: {
        mode: "dark",
        text: {
            secondary: "#6b778c"
        }
    }
});

ReactDom.render(
    <React.StrictMode>
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />

            <BrowserRouter>
                <ErrorBoundary>
                    <EnvProvider>
                        <UserAuthContextProvider>
                            <MapProvider>
                                <SocketProvider>
                                    <Routes>
                                        <Route path="/login" element={<Login />} />
                                        <Route path="/signup" element={<CreateUser />} />
                                        <Route path="/reset" element={<Reset />} />
                                        <Route path="/forgot" element={<Forgot />} />

                                        <Route path="/" element={<App />}>
                                            <Route path="" element={<Manager />} />
                                            <Route path="chat" element={<Chat />} />
                                            <Route path="manage" element={<UserList />} />
                                            <Route path="account" element={<UserInfo />} />
                                            <Route path="datalogs" element={<DataLogs />} />
                                            <Route path="hms" element={<HMSSystemStatus />} />
                                            <Route path="operations" element={<PlanningOps />} />
                                            <Route path="map-settings" element={<Settings />} />
                                            <Route path="notifications" element={<UserNotification />} />
                                            <Route path="monitoring" element={<DataLoggingDashboard />} />
                                            <Route path="map" element={<MapComponent standalone={true} />} />
                                            <Route path="drone-settings" element={<DroneSettings />} />
                                            <Route path="metrics" element={<Metrics />} />
                                            <Route path="hms-settings" element={<HMSSettings />} />
                                            <Route path="zca-settings" element={<ZCASettings />} />
                                            <Route path="dashboard" element={<OperatorDashboard />} />
                                        </Route>
                                    </Routes>
                                </SocketProvider>
                            </MapProvider>
                        </UserAuthContextProvider>
                    </EnvProvider>
                </ErrorBoundary>
            </BrowserRouter>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
