import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tooltip, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../contexts/authContext";
import { ConvertMetersToFeet, GetColorFromState } from "../../util";

const CHANGE_ADD = "ADD";
const CHANGE_REMOVE = "REMOVE";
const CHANGE_ADD_REMOVE = "ADD_REMOVE";
const CHANGE_EDIT = "EDIT";

const blacklistedFields = [
    "id",
    "version",
    "created_user_id",
    "updated_user_id",
    "changed",
    "conflicts",
    "otd_uuid",
    "flight_json",
    "date_created",
    "date_updated",
    "volumeId"
];

const keyFormats = [
    ["lat", "Latitude"],
    ["lng", "Longitude"],
    ["faa_request_type", "FAA Request Type"],
    ["laanc", "LAANC"],
    ["pilot_uuid", "Pilot UUID"],
    ["flight_uuid", "Flight UUID"],
    ["vehicle_uuid", "Vehicle UUID"],
    ["faa_approval_required", "FAA Approval Required"],
    ["volumes", "Flight volume"],
    ["bvlos", "BVLOS"]
];

export const FlightHistoryDialog = (props) => {
    const [rows, setRows] = useState([]);
    const [changes, setChanges] = useState([]);
    const [changeDialogOpen, setChangeDialogOpen] = useState(false);
    const { handleFailedFetch, userOperationalStates } = useUserAuth();

    const formatChange = (change) => {
        const changes = [];
        if (change.changeMethod === CHANGE_REMOVE || change.changeMethod === CHANGE_ADD_REMOVE) {
            changes.push({ text: `- ${change.key}: ${change.oldValue}`, color: "#FF5753" });
        }
        if (change.changeMethod === CHANGE_ADD || change.changeMethod === CHANGE_ADD_REMOVE) {
            changes.push({ text: `+ ${change.key}: ${change.newValue}`, color: "#77CB86" });
        }
        if (change.changeMethod === CHANGE_EDIT) {
            changes.push({ text: `* ${change.key}`, color: "#76A0FB" });
        }
        return changes;
    };

    const formatPreview = (changes) => {
        const preview = formatChange(changes[0]);
        if (changes.length > 1) {
            preview[preview.length - 1].text = preview[preview.length - 1].text + "...";
        }
        return preview;
    };

    const formatRowChanges = (changes) => {
        const rowChanges = [];
        for (const change of changes) {
            const rowChange = formatChange(change);
            if (!rowChanges.find((r) => r[0].text === rowChange[0].text)) {
                rowChanges.push(rowChange);
            }
        }
        return rowChanges;
    };

    const columns = [
        {
            field: "date",
            headerName: "Date",
            width: 230
        },
        {
            field: "status",
            headerName: "Status",
            width: 230,
            renderCell: (params) => {
                return (
                    <div
                        style={{
                            fontSize: 14,
                            fontWeight: "bold",
                            color: GetColorFromState(params.value, userOperationalStates)
                        }}
                    >
                        {params.value}
                    </div>
                );
            }
        },
        {
            field: "user",
            headerName: "User",
            width: 230
        },
        {
            field: "changes",
            headerName: "Changes",
            width: 460,
            renderCell: (params) => {
                if (!params.value || params.value.length === 0) {
                    return <></>;
                }

                const changes = params.value;
                const preview = formatPreview(changes);
                const rowChanges = formatRowChanges(changes);

                return (
                    <div>
                        <Tooltip title={rowChanges.map((change) => change.text).join("\n")}>
                            <Box
                                component="button"
                                onClick={() => {
                                    setChanges(rowChanges);
                                    setChangeDialogOpen(true);
                                }}
                                style={{ backgroundColor: "transparent", border: "none", padding: 0, outline: "none" }}
                            >
                                <Grid container direction="column" alignItems="flex-start">
                                    {preview.map((item, index) => (
                                        <Grid item key={index}>
                                            <Typography variant="body1" style={{ color: item.color }}>
                                                {item.text}
                                            </Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </Tooltip>
                    </div>
                );
            }
        }
    ];

    useEffect(() => {
        const formatKey = (key) => {
            for (const keyFormat of keyFormats) {
                if (key === keyFormat[0]) {
                    return keyFormat[1];
                }
            }
            return key
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ");
        };

        const formatDate = (dateValue) => {
            const date = new Date(dateValue);
            return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
        };

        // Recursive function to handle nested JSON.
        const findDifferences = (oldObj, newObj, keyPrefix = "") => {
            let changes = [];
            for (const key in newObj) {
                const formattedKey = formatKey(key);
                let nestedKey = keyPrefix ? `${keyPrefix} ➔ ${formattedKey}` : formattedKey;
                let oldValue = oldObj ? oldObj[key] : null;
                let newValue = newObj ? newObj[key] : null;

                const sameValue = oldValue === newValue;
                const invalidValues = !oldValue && !newValue;
                const blacklisted = blacklistedFields.includes(key);
                const invalidVolumeKey = keyPrefix === "Volume" && key !== "altitude_min_agl_m" && key !== "altitude_max_agl_m";
                const invalidLaancKey = keyPrefix === "LAANC" && key !== "state";
                if (sameValue || invalidValues || blacklisted || invalidVolumeKey || invalidLaancKey) {
                    continue;
                }

                // Look for additional changes in the 1st volumes array (for altitudes, etc.).
                if (key === "volumes") {
                    changes = changes.concat(findDifferences(oldValue ? oldValue[0] : null, newValue ? newValue[0] : null, "Volume"));
                }

                // Check for changes in larger values (arrays).
                if (Array.isArray(newValue) && JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
                    changes.push({
                        key: `${nestedKey} has changed`,
                        changeMethod: CHANGE_EDIT
                    });
                    continue;
                }

                // Check for nested JSON.
                if (newValue && typeof newValue === "object") {
                    changes = changes.concat(findDifferences(oldValue, newValue, nestedKey));
                    continue;
                }

                // Altitude fields.
                if (key === "altitude_min_agl_m" || key === "altitude_max_agl_m") {
                    newValue = ConvertMetersToFeet(newValue) + "ft";
                    oldValue = ConvertMetersToFeet(oldValue) + "ft";
                    if (key === "altitude_min_agl_m") nestedKey = "Min Altitude (AGL)";
                    if (key === "altitude_max_agl_m") nestedKey = "Max Altitude (AGL)";
                }

                // Date fields.
                const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
                if (dateRegex.test(newValue) && dateRegex.test(oldValue)) {
                    newValue = formatDate(newValue);
                    oldValue = formatDate(oldValue);
                }

                let changeMethod = CHANGE_ADD_REMOVE;
                if (typeof oldValue !== "boolean" && (!oldValue || (typeof oldValue === "string" && !oldValue.trim()))) changeMethod = CHANGE_ADD;
                if (typeof newValue !== "boolean" && (!newValue || (typeof newValue === "string" && !newValue.trim()))) changeMethod = CHANGE_REMOVE;

                changes.push({
                    key: nestedKey,
                    oldValue: oldValue,
                    newValue: newValue,
                    changeMethod: changeMethod
                });
            }
            return changes;
        };

        const computeDifferences = (flightHistory) => {
            if (flightHistory.length === 0) {
                return;
            }

            let rows = [];
            let newHistory = flightHistory[0];

            for (let i = 1; i < flightHistory.length; i++) {
                const oldHistory = flightHistory[i];
                const changes = findDifferences(oldHistory.flight_json, newHistory.flight_json);

                // Don't add any rows when there's no changes.
                if (changes.length === 0) {
                    newHistory = oldHistory;
                    continue;
                }

                const newDate = formatDate(newHistory.date_changed);
                const oldDate = formatDate(oldHistory.date_changed);

                // Combine changes that were made in the same second.
                if (rows.length > 0 && newDate === oldDate) {
                    rows[rows.length - 1].changes.push(...changes);
                } else {
                    rows.push({
                        id: i,
                        date: newDate,
                        status: newHistory.state,
                        user: newHistory.user_changed !== " " ? newHistory.user_changed : "Unknown",
                        changes: changes
                    });
                }

                newHistory = oldHistory;
            }

            setRows(rows);
        };

        const fetchFlightHistory = async () => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json" }
            };

            fetch(`/api/op/flightHistory/get/${props.flightUUID}`, requestOptions)
                .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                .then((flightHistory) => computeDifferences(flightHistory))
                .catch((err) => handleFailedFetch(err));
        };

        fetchFlightHistory();
    }, []);

    const handleCloseDialog = () => {
        setChangeDialogOpen(false);
    };

    const handleCloseFlightHistory = () => {
        props.setFlightHistoryOpen(false);
    };

    return (
        <>
            <Dialog open={props.flightHistoryOpen} onClose={handleCloseFlightHistory} maxWidth="lg" fullWidth>
                <DialogTitle>Flight History</DialogTitle>
                <DialogContent>
                    <Box sx={{ marginTop: "5px", display: "flex", flexDirection: "column", gap: "5px" }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            disableVirtualization
                            disableSelectionOnClick
                            autoHeight
                            sx={{ bgcolor: "#121212" }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ mr: "auto" }} onClick={handleCloseFlightHistory} id="flightHistoryClose">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={changeDialogOpen} onClose={handleCloseDialog} PaperProps={{ style: { minWidth: 400 } }}>
                <DialogTitle>Changes</DialogTitle>
                <DialogContent>
                    <Grid container direction="column" spacing={2}>
                        {changes.map((changeGroup, index) => (
                            <Grid item key={index}>
                                <Grid container direction="column">
                                    {changeGroup.map((change, subIndex) => (
                                        <Typography key={subIndex} variant="body1" style={{ color: change.color }}>
                                            {change.text}
                                        </Typography>
                                    ))}
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} id="flightHistoryChangesClose">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
