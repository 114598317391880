import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";

import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { CustomPagination } from "../../customPagination";
import { MapPreview } from "../../map/mapPreview";
import { ConvertISOToDate, GetColorFromState } from "../../util";
import { CreateFlightDialog } from "./createFlightDialog";
import { EditFlightDialog } from "./editFlightDialog";
import { getOperationEditable, getOperationPriority, getWaypointsDownloadable, handleDownloadOperations, userAccountHasFirstAndLastName } from "./opUtil";

import { useUserAuth } from "../../contexts/authContext";
import { useMap } from "../../contexts/mapContext";
import { useSocket } from "../../contexts/socketContext";

import ExportFlightWaypointsDialog from "./exportFlightWaypointsDialog";
import * as CONSTANT from "./opConstants";
import { IconButton } from "@mui/material";
import { FlightInfoDialog } from "./flightInfoDialog";

export function PlanningOps() {
    const [showMap, setShowMap] = useState(false);
    const [createFlightDialogOpen, setCreateFlightDialogOpen] = useState(false);
    const [editFlightDialogOpen, setEditFlightDialogOpen] = useState(false);
    const [editFlightVolume, setEditFlightVolume] = useState(null);
    const [selectedPlanningOperations, setSelectedPlanningOperations] = useState([]);
    const [operationsAreSelected, setOperationsAreSelected] = useState(false);

    const [downloadWaypointDialogOpen, setDownloadWaypointDialogOpen] = useState(false);
    const [downloadWaypointOperation, setDownloadWaypointOperation] = useState(null);

    const [infoFlight, setInfoFlight] = useState(null);
    const [flightInfoOpen, setFlightInfoOpen] = useState(false);

    const { drones, colors, airspaces, getDrones } = useMap();
    const { planningOperations, publishedOperations, constraints, alertVolumes } = useSocket();
    const { user, userMapSettings, getOrganizationByID, handleFailedFetch } = useUserAuth();

    const laancStates = [
        { state: CONSTANT.PLANNING_STATUS, color: "#E0D268" },
        { state: CONSTANT.LAANC_FURTHER_COORD_STATUS, color: "#E0D268" },
        { state: CONSTANT.DECONFLICTION_BLOCKED_STATUS, color: "#CB4C4E" },
        { state: CONSTANT.PRECHECK_BLOCKED_STATUS, color: "#CB4C4E" },
        { state: CONSTANT.LAANC_BLOCKED_STATUS, color: "#CB4C4E" },
        { state: CONSTANT.LAANC_NOT_REQUIRED_STATUS, color: "#5fa052" },
        { state: CONSTANT.LAANC_AUTO_APPROVED_STATUS, color: "#5fa052" },
        { state: CONSTANT.FAA_APPROVAL_BLOCKED_STATUS, color: "#CB4C4E" },
        { state: CONSTANT.FAA_APPROVAL_CLEAR_STATUS, color: "#5fa052" }
    ];

    const dateTimeColumns = [
        {
            field: "info",
            headerName: "",
            minWidth: 50,
            maxWidth: 50,
            flex: 1,
            renderCell: (cellValues) => {
                return (
                    <IconButton size="small" onClick={(event) => handleInfoClick(event, cellValues)} id={`info-${cellValues.row.volumeId}`}>
                        <InfoIcon />
                    </IconButton>
                );
            }
        },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            editable: false,
            minWidth: 175,
            valueGetter: (params) => `${params.row.name}`
        },
        {
            field: "time_start",
            headerName: "Start Date",
            editable: false,
            type: "dateTime",
            minWidth: 225,
            flex: 1,
            valueGetter: (params) => `${ConvertISOToDate(params.row.time_start)}`
        },
        {
            field: "time_end",
            headerName: "End Date",
            editable: false,
            type: "dateTime",
            minWidth: 225,
            flex: 1,
            valueGetter: (params) => `${ConvertISOToDate(params.row.time_end)}`
        },
        {
            field: "organization_id",
            headerName: "Org",
            editable: false,
            minWidth: 75,
            maxWidth: 75,
            flex: 1,
            renderCell: ({ formattedValue }) => {
                const org = getOrganizationByID(formattedValue);
                return org ? <>{org.name}</> : <></>;
            }
        },
        {
            field: "pilot_uuid",
            headerName: "Pilot",
            editable: false,
            minWidth: 175,
            flex: 1,
            valueGetter: ({ row }) => `${row.pilot_name || row.pilot_email}`
        },
        {
            field: "vehicle_uuid",
            headerName: "Vehicle",
            editable: false,
            minWidth: 150,
            flex: 1,
            renderCell: ({ formattedValue }) => {
                const drone = drones.find((drone) => drone.vehicle_uuid === formattedValue);
                return drone ? drone.name : "Unknown";
            }
        },
        {
            field: "state",
            headerName: "Status",
            editable: false,
            minWidth: 250,
            flex: 1,
            valueGetter: ({ row }) => `${row.state}`,
            renderCell: (cellValues) => {
                let fontColor = GetColorFromState(cellValues.formattedValue, laancStates);
                return (
                    <div style={{ fontWeight: "bold", color: fontColor }} id={`status-${cellValues.row.volumeId}`}>
                        {cellValues.formattedValue.toUpperCase().replace(/_/g, " ")}
                    </div>
                );
            }
        },
        {
            field: "priority",
            headerName: "Priority",
            minWidth: 125,
            flex: 1,
            editable: false,
            valueGetter: ({ row }) => `${getOperationPriority(row)}`
        },
        {
            type: "actions",
            field: "actions",
            minWidth: 125,
            headerName: "Actions",
            cellClassName: "actions",
            getActions: ({ row }) => {
                const canEditOrDelete = getOperationEditable(row, user);
                const canDownloadWaypoints = getWaypointsDownloadable(row, user);
                return [
                    canEditOrDelete === true ? (
                        <GridActionsCellItem
                            key={row.id}
                            icon={<EditIcon />}
                            label="Edit"
                            id={`edit-${row.volumeId}`}
                            onClick={() => {
                                setEditFlightVolume(row);
                                setEditFlightDialogOpen(true);
                            }}
                        />
                    ) : (
                        <></>
                    ),
                    canDownloadWaypoints === true ? (
                        <GridActionsCellItem
                            key={row.id}
                            icon={<DownloadIcon />}
                            label="Download"
                            onClick={() => {
                                setDownloadWaypointDialogOpen(true);
                                setDownloadWaypointOperation(row);
                            }}
                        />
                    ) : (
                        <></>
                    ),
                    canEditOrDelete === true ? (
                        <GridActionsCellItem
                            key={row.id}
                            icon={<DeleteIcon />}
                            label="Delete"
                            id={`delete-${row.volumeId}`}
                            onClick={() => {
                                handleDeleteClick(row);
                            }}
                        />
                    ) : (
                        <></>
                    )
                ];
            }
        }
    ];

    useEffect(() => {
        if (operationsAreSelected === false) {
            setSelectedPlanningOperations(planningOperations);
        }
        getDrones();
    }, [planningOperations, selectedPlanningOperations, operationsAreSelected]);

    const handleDeleteClick = (operation) => {
        if (confirm("Are you sure you want to delete this operation from planning?") === false) {
            return;
        }
        operation.updated_user_id = user.id;
        operation.state = "DELETED";

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(operation)
        };
        fetch("/api/op/deconflict", requestOptions)
            .then((response) => response.json())
            .catch((err) => handleFailedFetch(err));
    };

    const handleCheckboxSelected = (ids) => {
        if (ids.length > 0 && showMap === false) {
            setShowMap(true);
        }
        const selected = planningOperations.filter(({ flight_uuid }) => {
            return ids.includes(flight_uuid);
        });
        if (selected.length > 0) {
            setOperationsAreSelected(true);
            setSelectedPlanningOperations(selected);
        } else {
            setOperationsAreSelected(false);
            setSelectedPlanningOperations(planningOperations);
        }
    };

    const handleOpenCreateDialog = () => {
        if (userAccountHasFirstAndLastName(user)) {
            setCreateFlightDialogOpen(true);
        } else {
            alert("Please include a first and last name in your account settings before creating an operation");
        }
    };

    const handleInfoClick = (event, cellValues) => {
        event.stopPropagation();
        setInfoFlight(cellValues.row);
        setFlightInfoOpen(true);
    };

    return (
        <Grid item xs={16} sx={{ maxWidth: "100% !important", pt: 3, px: 3, width: "100%" }}>
            <Typography component="h2" variant="h6" color="#6b778c" noWrap sx={{ flexGrow: 1 }}>
                Operation Planning
            </Typography>
            <Paper sx={{ p: 2, mt: 2, display: "flex", flexDirection: "column", height: 710, width: "100%" }}>
                <DataGrid
                    pageSize={10}
                    checkboxSelection
                    rows={planningOperations}
                    disableVirtualization
                    disableSelectionOnClick
                    rowsPerPageOptions={[6]}
                    columns={dateTimeColumns}
                    getRowId={(row) => row.flight_uuid}
                    components={{ Pagination: CustomPagination }}
                    onSelectionModelChange={handleCheckboxSelected}
                />
                <Box sx={{ mt: 2, display: "flex", gap: 1, overflow: "auto" }}>
                    <Button
                        onClick={() => setShowMap((prev) => !prev)}
                        variant="contained"
                        color="primary"
                        data-testid="preview"
                        size="small"
                        sx={{ whiteSpace: "nowrap", flexShrink: 0 }}
                    >
                        {showMap ? "Hide Map" : "Preview on Map"}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleDownloadOperations(selectedPlanningOperations, userMapSettings)}
                        data-testid="download"
                        size="small"
                        sx={{ mr: "auto", whiteSpace: "nowrap", flexShrink: 0 }}
                        id="download"
                    >
                        Download KML
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        id="createOperation"
                        data-testid="create"
                        onClick={handleOpenCreateDialog}
                        sx={{ whiteSpace: "nowrap", flexShrink: 0 }}
                    >
                        Create Operation
                    </Button>
                </Box>
            </Paper>
            {downloadWaypointDialogOpen === true ? (
                <ExportFlightWaypointsDialog
                    downloadWaypointDialogOpen={downloadWaypointDialogOpen}
                    setDownloadWaypointDialogOpen={setDownloadWaypointDialogOpen}
                    downloadWaypointOperation={downloadWaypointOperation}
                    setDownloadWaypointOperation={setDownloadWaypointOperation}
                />
            ) : (
                <></>
            )}
            {showMap ? (
                <Paper sx={{ px: 2, pb: 2, height: "600px" }}>
                    <MapPreview
                        id={0}
                        planningOperations={selectedPlanningOperations}
                        publishedOperations={publishedOperations}
                        airspaces={airspaces}
                        constraints={constraints}
                        alertVolumes={alertVolumes}
                    />
                </Paper>
            ) : (
                <></>
            )}
            {flightInfoOpen ? <FlightInfoDialog flight={infoFlight} flightInfoOpen={flightInfoOpen} setFlightInfoOpen={setFlightInfoOpen} /> : <></>}
            {createFlightDialogOpen ? (
                <CreateFlightDialog
                    type={0}
                    colors={colors}
                    constraints={constraints}
                    publishedOperations={publishedOperations}
                    planningOperations={planningOperations}
                    createFlightDialogOpen={createFlightDialogOpen}
                    setCreateFlightDialogOpen={setCreateFlightDialogOpen}
                />
            ) : (
                <></>
            )}
            {editFlightDialogOpen ? (
                <EditFlightDialog
                    type={1}
                    colors={colors}
                    publishedFlight={false}
                    constraints={constraints}
                    editFlightVolume={editFlightVolume}
                    editFlightDialogOpen={editFlightDialogOpen}
                    planningOperations={planningOperations}
                    publishedOperations={publishedOperations}
                    alertVolumes={alertVolumes}
                    setEditFlightVolume={setEditFlightVolume}
                    setEditFlightDialogOpen={setEditFlightDialogOpen}
                />
            ) : (
                <></>
            )}
        </Grid>
    );
}
