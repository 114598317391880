import DataObjectIcon from "@mui/icons-material/DataObject";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useUserAuth } from "../contexts/authContext";
import { CustomPagination } from "../customPagination";
import { ConvertISOToDate } from "../util";
import { JsonDialog } from "./jsonDialog";

export function ErrorDashboard() {
    const { handleFailedFetch } = useUserAuth();
    const [errorData, setErrorData] = useState([]);
    const [sortModel, setSortModel] = useState([
        {
            field: "error_date",
            sort: "desc"
        }
    ]);

    const [jsonOpen, setJsonOpen] = useState(false);
    const [selectedJson, setSelectedJson] = useState({});

    const DEFAULT_COLOR = "#FFFFFF"; //Dark Grey
    const NOMINAL_COLOR = "#5fa052"; //Green
    const LOW_SEVERITY_COLOR = "#E0D268"; //Yellow
    const HIGH_SEVERITY_COLOR = "#CB4C4E"; //Red

    const columns = [
        {
            field: "json",
            headerName: "",
            flex: 1,
            midWidth: 50,
            maxWidth: 50,
            renderCell: (params) => {
                return (
                    <IconButton size="small" onClick={() => handleJsonOpen(params.row)}>
                        <DataObjectIcon />
                    </IconButton>
                );
            }
        },
        {
            field: "service",
            headerName: "Service",
            flex: 1,
            minWidth: 250,
            maxWidth: 250,
            renderCell: (cellValues) => {
                let color = DEFAULT_COLOR;
                if (cellValues.row.error_count >= 1) color = LOW_SEVERITY_COLOR;
                if (cellValues.row.error_count > 5) color = HIGH_SEVERITY_COLOR;

                return (
                    <div
                        style={{
                            fontSize: 14,
                            fontWeight: "bold",
                            color: color
                        }}
                    >
                        {cellValues.formattedValue}
                    </div>
                );
            }
        },
        {
            field: "error_count",
            headerName: "Errors Last 24 Hours",
            flex: 1,
            minWidth: 200,
            maxWidth: 200,
            renderCell: (cellValues) => {
                let color = "#2f2f2f";
                if (cellValues.formattedValue >= 1) color = LOW_SEVERITY_COLOR;
                if (cellValues.formattedValue > 5) color = HIGH_SEVERITY_COLOR;
                if (cellValues.formattedValue == 0) color = NOMINAL_COLOR;

                return (
                    <div
                        style={{
                            fontSize: 14,
                            fontWeight: "bold",
                            color: color
                        }}
                    >
                        {cellValues.formattedValue}
                    </div>
                );
            }
        },
        {
            field: "error_count_week",
            headerName: "Errors Last Week",
            flex: 1,
            minWidth: 200,
            maxWidth: 200
        },
        {
            field: "error_count_month",
            headerName: "Errors Last Month",
            flex: 1,
            minWidth: 200,
            maxWidth: 200
        },
        {
            field: "error_date",
            headerName: "Date of Last Error",
            minWidth: 250,
            maxWidth: 250,
            type: "dateTime",
            flex: 1,
            valueFormatter: (params) => `${ConvertISOToDate(params.value)}`
        }
    ];

    useEffect(() => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };

        const fetchData = async () => {
            await fetch("api/errors/getSummary", requestOptions)
                .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                .then((data) => setErrorData(data))
                .catch((err) => handleFailedFetch(err));
        };
        fetchData();

        return () => setErrorData([]);
    }, []);

    const handleJsonOpen = (row) => {
        setSelectedJson(row);
        setJsonOpen(true);
    };

    return (
        <Box sx={{ height: 578 }}>
            <DataGrid
                disableVirtualization
                rows={errorData}
                columns={columns}
                disableSelectionOnClick
                getRowId={(row) => row.service}
                pageSize={9}
                rowsPerPageOptions={[10]}
                components={{ Pagination: CustomPagination }}
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
            />
            {jsonOpen ? <JsonDialog jsonOpen={jsonOpen} setJsonOpen={setJsonOpen} selectedJson={selectedJson}></JsonDialog> : <></>}
        </Box>
    );
}
