import DataObjectIcon from "@mui/icons-material/DataObject";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useUserAuth } from "../contexts/authContext";
import { CustomPagination } from "../customPagination";
import { ConvertISOToDate } from "../util";
import { JsonDialog } from "./jsonDialog";

export function ConstraintLog() {
    const { handleFailedFetch } = useUserAuth();
    const [data, setData] = useState([]);
    const [sortModel, setSortModel] = useState([
        {
            field: "time_start",
            sort: "desc"
        }
    ]);

    const [jsonOpen, setJsonOpen] = useState(false);
    const [selectedJson, setSelectedJson] = useState({});

    const columns = [
        {
            field: "json",
            headerName: "",
            flex: 1,
            midWidth: 50,
            maxWidth: 50,
            renderCell: (params) => {
                return (
                    <IconButton size="small" onClick={() => handleJsonOpen(params.row)}>
                        <DataObjectIcon />
                    </IconButton>
                );
            }
        },
        {
            field: "constraint_uuid",
            headerName: "ID",
            flex: 1,
            minWidth: 275
        },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            minWidth: 150
        },
        {
            field: "state",
            headerName: "State",
            flex: 1,
            minWidth: 100
        },
        {
            field: "time_start",
            headerName: "Time Start",
            minWidth: 175,
            type: "dateTime",
            flex: 1,
            valueFormatter: (params) => `${ConvertISOToDate(params.value)}`
        },
        {
            field: "time_end",
            headerName: "Time End",
            minWidth: 175,
            type: "dateTime",
            flex: 1,
            valueFormatter: (params) => `${ConvertISOToDate(params.value)}`
        },
        {
            field: "owner",
            headerName: "Owner",
            minWidth: 150,
            flex: 1
        },
        {
            field: "organization",
            headerName: "Org",
            minWidth: 50,
            flex: 1
        },
        {
            field: "date_created",
            headerName: "Created",
            minWidth: 175,
            type: "dateTime",
            flex: 1,
            valueFormatter: (params) => `${ConvertISOToDate(params.value)}`
        },
        {
            field: "date_updated",
            headerName: "Updated",
            minWidth: 175,
            type: "dateTime",
            flex: 1,
            valueFormatter: (params) => `${ConvertISOToDate(params.value)}`
        }
    ];

    useEffect(() => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };

        const fetchData = async () => {
            await fetch("api/constraint/get", requestOptions)
                .then((response) => (response.ok ? response.json() : Promise.reject(response)))
                .then((data) => setData(data))
                .catch((err) => handleFailedFetch(err));
        };
        fetchData();

        return () => setData([]);
    }, []);

    const handleJsonOpen = (row) => {
        setSelectedJson(row);
        setJsonOpen(true);
    };

    return (
        <Box sx={{ height: 578 }}>
            <DataGrid
                disableVirtualization
                rows={data}
                columns={columns}
                disableSelectionOnClick
                getRowId={(row) => row.id}
                pageSize={9}
                rowsPerPageOptions={[10]}
                components={{ Pagination: CustomPagination }}
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
            />
            {jsonOpen ? <JsonDialog jsonOpen={jsonOpen} setJsonOpen={setJsonOpen} selectedJson={selectedJson}></JsonDialog> : <></>}
        </Box>
    );
}
