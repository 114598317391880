import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";

import { DataGrid } from "@mui/x-data-grid";
import { useUserAuth } from "../../contexts/authContext";
import { useMap } from "../../contexts/mapContext";
import { useSocket } from "../../contexts/socketContext";
import { CustomPagination } from "../../customPagination";
import { MapPreview } from "../../map/mapPreview";
import { ConvertISOToDate, ConvertMetersToFeet, GetColorFromState, StartTimeHasAlreadyPassed } from "../../util";
import { CreateConstraint } from "./createConstraint";

export function Constraints() {
    const [showMap, setShowMap] = useState(false);
    const [constraintColumns, setConstraintColumns] = useState([]);
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [constraintDetails, setConstraintDetails] = useState("");
    const [infoOpen, setInfoOpen] = useState(false);
    const [selectedConstraints, setSelectedConstraints] = useState([]);
    const [constraintsAreSelected, setConstraintsAreSelected] = useState(false);

    const { airspaces } = useMap();
    const { planningOperations, publishedOperations, constraints, alertVolumes } = useSocket();
    const { user, getOrganizationByID, userOperationalStates, handleFailedFetch } = useUserAuth();

    const DELETED_STATUS = "DELETED";
    const PLANNING_STATUS = "PLANNING";
    const ACCEPTED_STATUS = "ACCEPTED";
    const CMP_UI_OWNER = "cmp-ui-online";

    const initialColumns = [
        {
            field: "info",
            headerName: "",
            minWidth: 50,
            maxWidth: 50,
            flex: 1,
            display: "flex",
            renderCell: (cellValues) => {
                return (
                    <IconButton size="medium" onClick={(event) => handleInfoClick(event, cellValues)}>
                        <InfoIcon />
                    </IconButton>
                );
            }
        },
        {
            field: "name",
            headerName: "Name",
            minWidth: 175,
            flex: 1,
            editable: false
        },
        {
            field: "state",
            headerName: "State",
            minWidth: 135,
            flex: 1,
            editable: false,
            renderCell: (cellValues) => {
                let fontColor = GetColorFromState(cellValues.formattedValue, userOperationalStates);
                return <div style={{ fontWeight: "bold", color: fontColor }}>{cellValues.formattedValue ? cellValues.formattedValue.toUpperCase() : ""}</div>;
            }
        },
        {
            field: "time_start",
            headerName: "Start",
            type: "dateTime",
            minWidth: 200,
            flex: 1,
            editable: false,
            valueFormatter: (value) => `${ConvertISOToDate(value)}`
        },
        {
            field: "time_end",
            headerName: "End",
            type: "dateTime",
            minWidth: 200,
            flex: 1,
            valueFormatter: (value) => `${ConvertISOToDate(value)}`
        },
        {
            field: "details.volumes[0].altitude_max_agl_m",
            headerName: "Alt ft (AGL)",
            minWidth: 100,
            flex: 1,
            valueGetter: (value, row) => `${ConvertMetersToFeet(row.volumes[0].altitude_max_agl_m)}`
        },
        {
            field: "owner",
            headerName: "Owner",
            minWidth: 175,
            flex: 1,
            editable: false
        },
        {
            field: "type",
            headerName: "Type",
            minWidth: 175,
            flex: 1,
            editable: false
        },
        {
            field: "organization_id",
            headerName: "Org",
            editable: false,
            minWidth: 100,
            flex: 1,
            renderCell: (cellValues) => {
                const org = getOrganizationByID(cellValues.formattedValue);
                if (org != undefined) {
                    return <div>{org.name}</div>;
                }
            }
        }
    ];

    const managerColumns = [
        {
            field: "status",
            headerName: "",
            editable: false,
            minWidth: 100,
            flex: 1,
            renderCell: (cellValues) => {
                if ((user.user_role_id == 4 || user.user_role_id == 0) && cellValues.row.owner == CMP_UI_OWNER) {
                    if (cellValues.row.state && cellValues.row.state.toUpperCase() == PLANNING_STATUS) {
                        return <Button onClick={(event) => handleStatusClick(event, cellValues)}>Publish</Button>;
                    }
                }
            }
        },
        {
            field: "Cancel",
            align: "center",
            headerAlign: "center",
            minWidth: 75,
            flex: 1,
            sortable: false,
            renderCell: (cellValues) => {
                if ((user.user_role_id == 4 || user.user_role_id == 0) && cellValues.row.owner == CMP_UI_OWNER)
                    return (
                        <IconButton onClick={(event) => handleCancelClick(event, cellValues)} id={`cancel-${cellValues.row.volumeId}`}>
                            <CancelIcon />
                        </IconButton>
                    );
            }
        }
    ];

    useEffect(() => {
        setupColumnsFromLogin();
    }, []);

    useEffect(() => {
        if (constraintsAreSelected === false) {
            setSelectedConstraints(constraints);
        }
    }, [constraints, selectedConstraints, constraintsAreSelected]);

    const setupColumnsFromLogin = () => {
        const columns = initialColumns;
        if (user.user_role != "Operator") {
            columns.push(...managerColumns);
        }
        setConstraintColumns(columns);
    };

    const handleCancelClick = (event, cellValues) => {
        event.stopPropagation();
        if (!confirm("Are you sure you want to cancel this constraint?")) {
            return;
        }
        const constraint = cellValues.row;
        constraint.state = DELETED_STATUS;

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(constraint)
        };
        fetch("/api/constraint/delete", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .catch((err) => handleFailedFetch(err));
    };

    const handleStatusClick = (event, cellValues) => {
        event.stopPropagation();

        if (StartTimeHasAlreadyPassed(cellValues.row.time_start)) {
            return alert(
                "The start time of this constraint has passed. If you wish to update it, please visit the Map and right-click the constraint to edit."
            );
        } else if (!confirm("Are you sure you want to publish this constraint?")) {
            return;
        }

        const constraint = cellValues.row;
        constraint.state = ACCEPTED_STATUS;
        constraint.version = parseInt(constraint.version) + 1;

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(constraint)
        };
        fetch("/api/constraint/update", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .catch((err) => handleFailedFetch(err));
    };

    const handleInfoClick = (event, cellValues) => {
        event.stopPropagation();
        const displayDetails = {
            name: cellValues.row.name,
            uuid: cellValues.row.constraint_uuid,
            maxAlt: ConvertMetersToFeet(cellValues.row.volumes[0].altitude_max_agl_m)
        };
        setConstraintDetails(displayDetails);
        setInfoOpen(true);
    };

    const handleCloseInfo = () => {
        setInfoOpen(false);
    };

    const handleCheckboxSelected = (ids) => {
        if (ids.length > 0 && showMap === false) {
            setShowMap(true);
        }
        const selected = constraints.filter(({ constraint_uuid }) => {
            return ids.includes(constraint_uuid);
        });
        if (selected.length > 0) {
            setConstraintsAreSelected(true);
            setSelectedConstraints(selected);
        } else {
            setConstraintsAreSelected(false);
            setSelectedConstraints(constraints);
        }
    };

    return (
        <>
            <Box sx={{ width: 1, height: 578 }}>
                <DataGrid
                    checkboxSelection
                    rows={constraints}
                    disableVirtualization
                    disableRowSeletionOnClick
                    columns={constraintColumns}
                    getRowId={(row) => row.constraint_uuid}
                    pagination
                    slots={{ pagination: CustomPagination }}
                    initialState={{ pagination: { paginationModel: { pageSize: 9 } } }}
                    onRowSelectionModelChange={handleCheckboxSelected}
                />
            </Box>
            <Box sx={{ my: 2, display: "flex", justifyContent: "flex-end", gap: "5px" }}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    data-testid="largePreview"
                    sx={{ mr: "auto" }}
                    onClick={() => setShowMap((prev) => !prev)}
                >
                    {showMap ? "Hide Map" : "Preview on Map"}
                </Button>
                {user.user_role === "Admin" || user.user_role === "Airspace Manager" ? (
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        data-testid="create"
                        id="createConstraint"
                        onClick={() => setCreateDialogOpen(true)}
                    >
                        Create on Map
                    </Button>
                ) : (
                    <></>
                )}
            </Box>
            {showMap === true ? (
                <Paper sx={{ pb: 2, height: "600px" }}>
                    <MapPreview
                        id={3}
                        planningOperations={planningOperations}
                        publishedOperations={publishedOperations}
                        airspaces={airspaces}
                        constraints={selectedConstraints}
                        alertVolumes={alertVolumes}
                    />
                </Paper>
            ) : (
                <></>
            )}
            {createDialogOpen ? (
                <CreateConstraint
                    constraints={constraints}
                    createDialogOpen={createDialogOpen}
                    setCreateDialogOpen={setCreateDialogOpen}
                    volumes={[]} // This is for unit testing
                />
            ) : (
                <></>
            )}
            <Dialog open={infoOpen} onClose={handleCloseInfo}>
                <DialogContent sx={{ minWidth: "350px", pb: 0 }}>
                    <Box sx={{ minWidth: 350, marginTop: "5px" }}>
                        <Typography variant="body2" color="text.secondary" sx={{ display: "flex", justifyContent: "space-between" }}>
                            <b>Constraint: </b>
                            <span>{constraintDetails.name}</span>
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ display: "flex", justifyContent: "space-between" }}>
                            <b>UUID: </b>
                            <span>{constraintDetails.uuid}</span>
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ display: "flex", justifyContent: "space-between" }}>
                            <b>Max Altitude: </b>
                            <span>{constraintDetails.maxAlt}ft</span>
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseInfo} sx={{ m: "auto" }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
