import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { DataGrid, GridActionsCellItem, GridToolbarContainer } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";

import { useUserAuth } from "../contexts/authContext";
import { CustomPagination } from "../customPagination";

function EditToolbar(props) {
    const { setEditRow, setSystemDialogOpen } = props;

    const handleClick = () => {
        setEditRow({
            id: 0,
            name: "",
            system_uuid: "",
            visible: true,
            components: []
        });
        setSystemDialogOpen(true);
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick} id="createSystem">
                Add Record
            </Button>
        </GridToolbarContainer>
    );
}

export function HMSSystemSettings() {
    const [systems, setSystems] = useState([]);
    const [editRow, setEditRow] = useState({});
    const [components, setComponents] = useState([]);
    const [systemDialogOpen, setSystemDialogOpen] = useState(false);
    const { handleFailedFetch } = useUserAuth();

    const fetchData = async () => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };

        await fetch("/api/hms/components/get", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => setComponents(data))
            .catch((err) => handleFailedFetch(err));

        await fetch("/api/hms/system/get", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then((data) => setSystems(data))
            .catch((err) => handleFailedFetch(err));
    };

    useEffect(() => {
        fetchData();
        return () => setSystems([]);
    }, []);

    const handleEditClick = (id) => {
        setEditRow(systems.find((row) => row.id === id));
        setSystemDialogOpen(true);
    };

    const handleSystemDialogClose = () => {
        setSystemDialogOpen(false);
        fetchData();
    };

    const systemColumns = [
        {
            field: "name",
            headerName: "Name",
            width: 100,
            editable: false,
            flex: 1
        },
        {
            field: "system_uuid",
            headerName: "UUID",
            width: 200,
            editable: false,
            flex: 1
        },
        {
            field: "visible",
            headerName: "Visible",
            maxWidth: 75,
            editable: false,
            flex: 1
        },
        {
            field: "components",
            headerName: "Components",
            width: 100,
            editable: false,
            flex: 1
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 100,
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        key={id}
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => handleEditClick(id)}
                        color="inherit"
                    />
                ];
            }
        }
    ];

    return (
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", width: "100%" }}>
            <DataGrid
                rows={systems}
                columns={systemColumns}
                disableSelectionOnClick
                disableVirtualization
                autoHeight
                pageSize={10}
                rowsPerPageOptions={[10]}
                components={{ Toolbar: EditToolbar, Pagination: CustomPagination }}
                componentsProps={{ toolbar: { setEditRow, setSystemDialogOpen } }}
            />
            {systemDialogOpen ? (
                <SystemDialog
                    row={editRow}
                    systemDialogOpen={systemDialogOpen}
                    handleSystemDialogClose={handleSystemDialogClose}
                    components={components}
                    systems={systems}
                    key={components}
                />
            ) : (
                <></>
            )}
        </Paper>
    );
}

export function SystemDialog(props) {
    const row = props.row;
    const { snackbar, setSnackbar } = useUserAuth();
    const { handleFailedFetch } = useUserAuth();

    const [name, setName] = useState(row.name);
    const [uuid, setUUID] = useState(row.system_uuid);
    const [isVisible, setIsVisible] = useState(row.visible);
    const [components] = useState(props.components);
    const [selectedComponents, setSelectedComponents] = useState([]);

    // Regular expression to check if string is a valid UUID
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    useEffect(() => {
        if (components.length && row.component_uuids) {
            const selectedComps = [];
            row.component_uuids.forEach((uuid) => {
                const component = components.find((comp) => comp.component_uuid == uuid);
                if (component) selectedComps.push(component);
            });
            setSelectedComponents(selectedComps);
        }
    }, [props.components]);

    const handleVisibleChange = async () => {
        setIsVisible(!isVisible);
    };

    const handleSubmit = async () => {
        if (!name || !uuid) {
            return setSnackbar({ children: "Please fill in the below fields", severity: "error" });
        }

        if (!regexExp.test(uuid)) {
            return setSnackbar({ children: "Please enter a valid UUID", severity: "error" });
        }

        if (row.system_uuid != uuid && props.systems.some((s) => s.system_uuid === uuid)) {
            return setSnackbar({ children: "Please enter a unique UUID", severity: "error" });
        }

        const newRow = {
            id: row.id,
            system_uuid: uuid,
            name: name,
            visible: isVisible,
            components: selectedComponents
        };

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(newRow)
        };
        await fetch("/api/hms/system/insertupdate", requestOptions).catch((err) => handleFailedFetch(err));
        handleClose();
    };

    const handleClose = () => {
        props.handleSystemDialogClose();
    };

    return (
        <Dialog onClose={handleClose} open={props.systemDialogOpen} maxWidth="lg" width="500px">
            <DialogTitle>HIMS System Manager</DialogTitle>
            <DialogContent>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, "& > *": { margin: "0 !important" } }}>
                    <FormControlLabel
                        control={<Checkbox checked={isVisible} onChange={handleVisibleChange} id="isVisible" />}
                        label="Is Visible on Dashboard?"
                    />
                    <TextField
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        margin="normal"
                        id="name"
                        label="Name"
                        fullWidth
                        error={snackbar && !name}
                    />
                    <TextField
                        value={uuid}
                        onChange={(e) => setUUID(e.target.value)}
                        margin="normal"
                        id="uuid"
                        label="UUID"
                        fullWidth
                        error={snackbar && (!uuid || (row.system_uuid != uuid && props.systems.some((s) => s.system_uuid === uuid)))}
                    />
                    <Autocomplete
                        multiple
                        options={components}
                        getOptionLabel={({ name }) => name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Components"
                                placeholder="Search for a component"
                                inputProps={{ ...params.inputProps, id: "componentSearch" }}
                            />
                        )}
                        style={{ width: 500 }}
                        onChange={(e, value) => setSelectedComponents(value)}
                        value={selectedComponents}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button sx={{ mr: "auto" }} onClick={handleClose} data-testid="close" id="close">
                    Close
                </Button>
                <Button onClick={handleSubmit} data-testid="save" id="save">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
