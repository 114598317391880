import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import { DataGrid } from '@mui/x-data-grid';
import { ConvertISOToDate } from "../util";
import { useUserAuth } from "../contexts/authContext";

export function LoginHistory(props) {
    const [open, setOpen] = React.useState(props.open);
    const [data, setData] = React.useState([]);

    const { handleFailedFetch } = useUserAuth();

    const handleClose = () => {
        setOpen(false);
        props.close();
    };

    React.useEffect(() => {
        if (props.user != null) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(props.user)
            };

            const fetchData = async () => {
                await fetch('api/loginhistory/get', requestOptions)
                .then(response => response.ok ? response.json() : Promise.reject(response))
                .then(data => setData(data))
                .catch(err => handleFailedFetch(err))
            }
            fetchData();

            return () => setData([]);
        }
    }, []);

    const columns = [
        {
            field: 'login_time',
            headerName: 'Login History',
            minWidth: 200,
            type: 'dateTime',
            flex: 1,
            valueGetter: (params) => `${ConvertISOToDate(params.row.login_time)}`,
        }
    ];

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            data-testid="dialog"
        >
            <DialogContent>
                <Box sx={{ marginTop: "5px", display: 'flex', flexDirection: 'column', gap: '5px', minWidth: 410 }}>
                    <Box                >
                        <DataGrid
                            disableVirtualization
                            rows={data}
                            columns={columns}
                            disableSelectionOnClick
                            autoHeight
                            pageSize={6}
                            rowsPerPageOptions={[6]}
                            sx={{
                                bgcolor: "#121212"
                            }}
                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}
