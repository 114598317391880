import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useEffect, useState } from "react";
import Button from "../../components/button";
import { useUserAuth } from "../../contexts/authContext";
import { ConvertFeetToMeters, GetTimezone, StartTimeHasAlreadyPassed, getConstraintDurationFromInput } from "../../util";

const ADVISORY_TYPE = "ADVISORY";
const TRAINING_TYPE = "TRAINING";
const COMMERCIAL_TYPE = "COMMERCIAL";
const PUBLIC_SAFETY_TYPE = "PUBLIC_SAFETY";
const EMERGENCY_TYPE = "EMERGENCY";
const RESTRICTED_TYPE = "RESTRICTED";

export const ConstraintDraw = (props) => {
    const [createEnd, setCreateEnd] = useState(new Date());
    const [createName, setCreateName] = useState("");
    const [createStart, setCreateStart] = useState(new Date());
    const [createAltitude, setCreateAltitude] = useState(0);
    const [createDuration, setCreateDuration] = useState(60);
    const [createType, setCreateType] = useState(0);
    const [createPriority, setCreatePriority] = useState(ADVISORY_TYPE);

    const { user, handleFailedFetch } = useUserAuth();

    //Reset variables everytime dialog is opened
    useEffect(() => {
        const currentTime = new Date();
        const startTime = currentTime.getTime() + 10 * 60 * 1000;
        const endTime = currentTime.getTime() + (parseInt(10) + parseInt(createDuration || 0)) * 60 * 1000;
        const startIsoString = new Date(startTime).toISOString();
        const endIsoString = new Date(endTime).toISOString();

        setCreateName("");
        setCreateAltitude("");
        setCreatePriority(0);
        setCreateDuration(60);
        setCreateEnd(endIsoString);
        setCreateStart(startIsoString);
        setCreateType(ADVISORY_TYPE);
    }, [props.constraintCreateDialogOpen]);

    useEffect(() => {
        const startTime = new Date(createStart).getTime();
        const endTime = startTime + parseInt(createDuration || 0) * 60 * 1000;
        if (!startTime) return;

        setCreateStart(new Date(startTime).toISOString());
        setCreateEnd(new Date(endTime).toISOString());
    }, [createDuration, createStart]);

    useEffect(() => {
        switch (createType) {
            case RESTRICTED_TYPE:
                setCreatePriority(1000);
                break;
            case EMERGENCY_TYPE:
                setCreatePriority(40);
                break;
            case PUBLIC_SAFETY_TYPE:
                setCreatePriority(30);
                break;
            case COMMERCIAL_TYPE:
                setCreatePriority(20);
                break;
            case TRAINING_TYPE:
                setCreatePriority(10);
                break;
            default:
                setCreatePriority(0);
        }
    }, [createType]);

    //Handle publish for constraint entities
    const handlePublish = async () => {
        if (
            !createName ||
            StartTimeHasAlreadyPassed(createStart) ||
            isNaN(parseFloat(createAltitude)) ||
            parseFloat(createAltitude) <= 0 ||
            isNaN(parseFloat(createDuration)) ||
            parseFloat(createDuration) <= 0
        ) {
            props.setSnackbar({ children: "Please fill out the required fields.", severity: "error" });
            return false;
        }

        // Create constraint and publish it
        const constraint = {
            name: createName,
            type: createType,
            altitude: ConvertFeetToMeters(parseFloat(createAltitude)),
            organization: user.organization,
            organization_id: user.organization_id,
            created_user_id: user.id,
            priority: createPriority,
            time_start: createStart,
            time_end: createEnd,
            volumes: props.constraintCreateVertices,
            state: "PLANNING",
            version: 1
        };

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(constraint)
        };
        await fetch("api/constraint/planning", requestOptions)
            .then((response) => (response.ok ? response.json() : Promise.reject(response)))
            .then(() => {
                props.setSnackbar({ children: "Constraint successfully created", severity: "success" });
                props.setConstraintCreateDialogOpen(false);
            })
            .catch((err) => handleFailedFetch(err));
    };

    //Handle cancel for constraint creation
    const handleCancel = () => {
        props.setConstraintCreateDialogOpen(false);
    };
    const handleDurationChange = (e) => {
        const input = getConstraintDurationFromInput(e.target.value);
        if (input !== undefined) {
            setCreateDuration(input);
        }
    };
    return (
        <Dialog open={props.constraintCreateDialogOpen} onContextMenu={(e) => e.preventDefault()} fullWidth={true} maxWidth={"sm"}>
            <DialogTitle>Create Constraint</DialogTitle>
            <DialogContent>
                <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2, mb: 2, pt: 1 }}>
                    <TextField
                        inputProps={{ "data-testid": "createName" }}
                        onChange={(e) => setCreateName(e.target.value)}
                        error={props.snackbar && !createName}
                        variant="outlined"
                        id="nameInput"
                        label="Name"
                        value={createName}
                        sx={{ flex: 1 }}
                    />

                    <TextField
                        inputProps={{ "data-testid": "inputAltitude" }}
                        InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                        onChange={(e) => setCreateAltitude(e.target.value)}
                        error={props.snackbar && (isNaN(parseFloat(createAltitude)) || parseFloat(createAltitude) <= 0)}
                        variant="outlined"
                        id="createAltitude"
                        label="Altitude (AGL)"
                        value={createAltitude}
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        sx={{ flex: 1 }}
                    />

                    <FormControl sx={{ flex: 1 }}>
                        <InputLabel id="select-priority-air">Type</InputLabel>
                        <Select value={createType} id="selectType" labelId="select-label" label="Type" onChange={(e) => setCreateType(e.target.value)}>
                            <MenuItem value={ADVISORY_TYPE}>Advisory</MenuItem>
                            <MenuItem value={TRAINING_TYPE}>Training</MenuItem>
                            <MenuItem value={COMMERCIAL_TYPE}>Commercial</MenuItem>
                            <MenuItem value={PUBLIC_SAFETY_TYPE}>Public Safety</MenuItem>
                            <MenuItem value={EMERGENCY_TYPE}>Emergency</MenuItem>
                            <MenuItem value={RESTRICTED_TYPE}>Restricted</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
                    <Box sx={{ flex: 1 }}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateTimePicker
                                label={`Start Time (${GetTimezone()}) *`}
                                value={createStart}
                                onChange={(value) => setCreateStart(value)}
                                InputProps={{ "data-testid": "start-date-picker" }}
                                renderInput={(params) => <TextField {...params} fullWidth error={props.snackbar && StartTimeHasAlreadyPassed(createStart)} />}
                            />
                        </LocalizationProvider>
                    </Box>

                    <TextField
                        error={props.snackbar && (isNaN(parseFloat(createDuration)) || parseFloat(createDuration) <= 0)}
                        InputProps={{ endAdornment: <InputAdornment position="end">mins</InputAdornment> }}
                        variant="outlined"
                        id="durationInput"
                        label="Duration"
                        onChange={handleDurationChange}
                        value={createDuration}
                        sx={{ flex: 1 }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} size="small">
                    Cancel
                </Button>
                <Button onClick={handlePublish} data-testid="accept" size="small">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};
