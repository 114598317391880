import React, { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { ErrorComponent } from "../errorComponent";
import { useUserAuth } from "../contexts/authContext";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

export function Reset() {
  const { user, updatePassword, statusMessage, setStatusMessage } = useUserAuth();
  const inputStyle = { WebkitBoxShadow: "0 0 0 1000px #121212 inset" };
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState("");
  const isMobile = useMediaQuery("(max-width:400px)");
  const navigateTo = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const password = data.get("password");
    const confirm_password = data.get("confirm-password");

    if (password !== confirm_password) {
      setPasswordError(true);
      setPasswordErrorMessage("The two passwords must match.");
      return false;
    }

    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#_-])[A-Za-z\d@$!%*?&#_-]{8,}$/.test(confirm_password)) {
      setPasswordErrorMessage(
        "Password must contain at least 8 characters, and an uppercase, lowercase, numeric, and special character."
      );
      setPasswordError(true);
      return false;
    }

    const updatedUser = { ...user };
    updatedUser.reset_password = false;
    updatedUser.password = password;

    updatePassword(updatedUser);
    navigateTo("/login");
  };

  const handleCancel = () => {
    navigateTo("/login");
  };

  useEffect(() => {
    setStatusMessage("");
  }, []);

  return (
    <Grid item xs={16}>
      <div style={{ justifyContent: "space-around", width: "100%", display: "inline-flex", height: "500px" }}>
        <Box sx={{ justifyContent: "space-around", alignItems: "center", display: "flex", maxWidth: "400px" }}>
          <Box sx={{ marginTop: 8, display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Create New Password
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ m: 4 }}>
              <TextField
                inputProps={{ style: inputStyle, "data-testid": "password" }}
                margin="normal"
                required
                fullWidth
                id="password"
                label="Password"
                type="password"
                name="password"
                autoFocus
              />
              <TextField
                inputProps={{ "data-testid": "confirm-password" }}
                error={passwordError}
                helperText={passwordErrorMessage}
                margin="normal"
                required
                fullWidth
                name="confirm-password"
                label="Confirm Password"
                type="password"
                id="confirm-password"
              />
              <ErrorComponent statusMessage={statusMessage} />
              <Box sx={{ justifyContent: "space-between", alignItems: "center", display: "flex" }}>
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  size={isMobile ? "small" : "medium"}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} size={isMobile ? "small" : "medium"}>
                  Reset Password
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    </Grid>
  );
}
