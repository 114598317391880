import { Color, Math, NearFarScalar } from "cesium";
import { getMessageSeverity } from "./mapUtil";

const allModels = new Map();
allModels.set(0, "../static/2d-models/adsb/adsb-default.png");
allModels.set(1, "../static/2d-models/adsb/adsb-light.png");
allModels.set(2, "../static/2d-models/adsb/adsb-light.png");
allModels.set(3, "../static/2d-models/adsb/adsb-large.png");
allModels.set(4, "../static/2d-models/adsb/adsb-heavy.png");
allModels.set(5, "../static/2d-models/adsb/adsb-heavy.png");
allModels.set(7, "../static/2d-models/adsb/adsb-rotocraft.png");
allModels.set(8, "../static/2d-models/telemetry/telemetry-quad.png");

const SCALEBYDISTANCE = new NearFarScalar(1.5e2, 2.0, 1.5e7, 0.5);

export function getRingModel(size, severity) {
    const RING_MODEL = {
        image: "../static/2d-models/zero-conflict/ring.png",
        color: Color.RED,
        scaleByDistance: SCALEBYDISTANCE
    };

    RING_MODEL.color = getMessageSeverity(severity);
    RING_MODEL.scale = size;
    return RING_MODEL;
}

export function getDpzRingModel(entitySize, color = "#5fa052") {
    return {
        image: "../static/2d-models/dpz/ring.png",
        color: Color.fromCssColorString(color),
        scale: entitySize,
        scaleByDistance: SCALEBYDISTANCE
    };
}

export function getCoastedModel(entitySize, color = "#5fa052") {
    return {
        image: "../static/2d-models/telemetry/coasted.png",
        color: Color.fromCssColorString(color),
        scale: entitySize * 0.75,
        scaleByDistance: SCALEBYDISTANCE
    };
}

export function updateModel(entity, model, color, size) {
    entity.billboard = {
        rotation: Math.toRadians(entity.rotation || 0),
        color: Color.fromCssColorString(color),
        scaleByDistance: SCALEBYDISTANCE,
        scale: size
    };

    if (model === "icon") {
        let image = allModels.get(0);
        if (entity.source_type === "TELEMETRY") {
            image = allModels.get(8);
        } else if (entity.emitter_type && allModels.has(entity.emitter_type)) {
            image = allModels.get(entity.emitter_type);
        }
        entity.billboard.image = image;
    } else {
        entity.billboard.image = `../static/2d-models/shapes/${model}.png`;
        entity.billboard.scale *= 0.75;
    }
}
