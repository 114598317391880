import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Paper from "@mui/material/Paper";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { useUserAuth } from "../contexts/authContext";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { IsInvalidDate, IsValidLaancCode, GetTimezone } from "../util";

const Json = ({ header, json }) => {
    const jsonString = JSON.stringify(json, null, 2);

    return (
        <Box sx={{ flex: 1, background: "#121212", padding: 2 }}>
            <Typography component="h1" variant="h6">
                {header}
            </Typography>

            <pre style={{ color: "#fff" }}>{jsonString}</pre>
        </Box>
    );
};

const Comparison = ({ id, endpoint, start, setStart, end, setEnd, code, setCode }) => {
    const [cal, setCal] = useState(null);
    const [faa, setFaa] = useState(null);
    const [loading, setLoading] = useState(true);

    const { handleFailedFetch } = useUserAuth();

    const COLUMNS = [
        { header: "CAL", data: cal },
        { header: "FAA", data: faa }
    ];

    useEffect(() => {
        handleFetchData(endpoint, false);

        return () => {
            setLoading(false);
            setCal(null);
            setFaa(null);
        };
    }, []);

    const handleFetchData = (endpoint, firedOnClick) => {
        let url = endpoint;
        if (id === "3") {
            if (IsInvalidDate(start, end) === false) {
                const iso_start_no_ms = new Date(start).toISOString().replace(/\.\d+/, "");
                const iso_end_no_ms = new Date(end).toISOString().replace(/\.\d+/, "");
                url = `${url}/${iso_start_no_ms}/${iso_end_no_ms}`;
            } else {
                alert("Oops! Something's wrong with your date inputs. Please ensure they are both valid dates and the start time is before the end.");
                setLoading(false);
                return;
            }
        }
        if (id === "4") {
            if (IsValidLaancCode(code)) {
                url = `${url}/${code}`;
            } else {
                if (firedOnClick) {
                    alert("Oops! Something's wrong with your reference code. Please ensure it starts with CAL and has exactly 12 characters.");
                }
                setLoading(false);
                return;
            }
        }
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        fetch(url, requestOptions)
            .then((response) => response.json())
            .then(({ cal, faa }) => {
                setCal(cal);
                setFaa(faa);
                setLoading(false);
            })
            .catch((err) => handleFailedFetch(err));
    };

    const handleRefresh = () => {
        setLoading(true);
        handleFetchData(endpoint, true);
    };

    const handleEnterKeyDown = (e) => {
        if (e.key === "Enter") {
            handleRefresh();
        }
    };

    return (
        <Box>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2, mb: 2 }}>
                {id === "3" ? (
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateTimePicker
                                label={`Start Time (${GetTimezone()}) *`}
                                value={start}
                                onChange={(value) => setStart(value)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <DateTimePicker
                                label={`End Time (${GetTimezone()}) *`}
                                value={end}
                                onChange={(value) => setEnd(value)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Box>
                ) : id === "4" ? (
                    <TextField
                        onChange={(e) => setCode(e.target.value)}
                        value={code}
                        variant="outlined"
                        margin="dense"
                        label="Volume Reference Code"
                        onKeyDown={handleEnterKeyDown}
                    />
                ) : (
                    <></>
                )}
                <Box sx={{ ml: "auto" }}>
                    <Button variant="contained" color="primary" onClick={handleRefresh}>
                        Refresh
                    </Button>
                </Box>
            </Box>

            <Divider sx={{ mb: 2 }} />

            <Box sx={{ display: "flex", gap: 2 }}>
                {cal === null || faa === null ? (
                    <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {loading ? <CircularProgress /> : <Typography variant="body1">No data</Typography>}
                    </Box>
                ) : (
                    <>
                        {COLUMNS.map(({ header, data }, i) => (
                            <Json key={i} header={header} json={data} />
                        ))}
                    </>
                )}
            </Box>
        </Box>
    );
};

const Metrics = () => {
    const [tab, setTab] = useLocalStorage("userMetricTab", "1");
    const [start, setStart] = useState(new Date());
    const [end, setEnd] = useState(new Date());
    const [code, setCode] = useState("");

    const TABS = [
        { id: "1", header: "Status", endpoint: "api/laanc/status" },
        { id: "2", header: "Operations", endpoint: "api/laanc/ops" },
        { id: "3", header: "Statistics", endpoint: `api/laanc/stats` },
        { id: "4", header: "Operation Status", endpoint: `api/laanc/volumes` }
    ];

    return (
        <Grid item xs={16} sx={{ maxWidth: "100% !important", pt: 3, px: 3, width: "100%" }}>
            <Typography component="h2" variant="h6" color="#6b778c" noWrap sx={{ flexGrow: 1 }}>
                Metrics
            </Typography>
            <Paper sx={{ p: 2, pb: 0, mt: 2, display: "flex", flexDirection: "column", width: "100%" }}>
                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            value={tab}
                            onChange={(e, value) => setTab(value)}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            aria-label="scrollable force tabs example"
                        >
                            {TABS.map(({ header, id }) => (
                                <Tab key={id} label={header} value={id} />
                            ))}
                        </Tabs>
                    </Box>

                    {TABS.map(({ id, endpoint }) => (
                        <TabPanel key={id} value={id}>
                            <Comparison id={id} endpoint={endpoint} start={start} setStart={setStart} end={end} setEnd={setEnd} code={code} setCode={setCode} />
                        </TabPanel>
                    ))}
                </TabContext>
            </Paper>
        </Grid>
    );
};

export default Metrics;
