import DataObjectIcon from "@mui/icons-material/DataObject";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useMap } from "../contexts/mapContext";
import { CustomPagination } from "../customPagination";
import { ConvertISOToDate } from "../util";
import { JsonDialog } from "./jsonDialog";

export function AirspaceLog() {
    const { airspaces } = useMap();
    const [jsonOpen, setJsonOpen] = useState(false);
    const [selectedJson, setSelectedJson] = useState({});

    const columns = [
        {
            field: "json",
            headerName: "",
            flex: 1,
            midWidth: 50,
            maxWidth: 50,
            renderCell: (params) => {
                return (
                    <IconButton size="small" onClick={() => handleJsonOpen(params.row)}>
                        <DataObjectIcon />
                    </IconButton>
                );
            }
        },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            minWidth: 200
        },
        {
            field: "city",
            headerName: "City",
            flex: 1,
            minWidth: 200
        },
        {
            field: "local_type",
            headerName: "Type",
            flex: 1,
            minWidth: 75
        },
        {
            field: "included",
            headerName: "Included",
            flex: 1,
            minWidth: 50
        },
        {
            field: "date_deleted",
            headerName: "Deleted",
            minWidth: 50,
            type: "dateTime",
            flex: 1,
            valueFormatter: (params) => `${ConvertISOToDate(params.value)}`
        },
        {
            field: "date_created",
            headerName: "Created",
            minWidth: 175,
            type: "dateTime",
            flex: 1,
            valueFormatter: (params) => `${ConvertISOToDate(params.value)}`
        },
        {
            field: "date_updated",
            headerName: "Updated",
            minWidth: 175,
            type: "dateTime",
            flex: 1,
            valueFormatter: (params) => `${ConvertISOToDate(params.value)}`
        }
    ];

    const handleJsonOpen = (row) => {
        setSelectedJson(row);
        setJsonOpen(true);
    };

    return (
        <Box sx={{ height: 578 }}>
            <DataGrid
                disableVirtualization
                rows={airspaces}
                columns={columns}
                disableSelectionOnClick
                getRowId={(row) => row.id}
                pageSize={9}
                rowsPerPageOptions={[10]}
                components={{ Pagination: CustomPagination }}
            />
            {jsonOpen ? <JsonDialog jsonOpen={jsonOpen} setJsonOpen={setJsonOpen} selectedJson={selectedJson}></JsonDialog> : <></>}
        </Box>
    );
}
